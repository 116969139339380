import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import logo from '../../assets/logo.png';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import SideDrawer from '../SideDrawer/SideDrawer';
import BackDrop from '../BackDrop/BackDrop';
import './styles.css';
import i18n from '../../i18n';
import ReactFlagsSelect from "react-flags-select";
import {Trans, withTranslation} from "react-i18next";

class Topbar extends Component {

    topbarClassName = ''
    originalTopbarClassName = ''
    selected = ''


    constructor(props) {
        super(props)
        this.topbarClassName = props.topbar
        this.originalTopbarClassName = props.topbar

        const currentLng = this.getCurrentLanguage();
        //console.log(currentLng)

        switch (currentLng) {
            case "zh-CN":
                this.state.selected = 'CN'
                break;
            case "pt-BR":
                this.state.selected = 'BR'
                break;
            case "en":
                this.state.selected = 'US'
                break;
            default:
                this.state.selected = "BR";
                break;
        }

    }

    getCurrentLanguage = () => window.localStorage.getItem('i18nextLng') || 'pt-BR'


    state = {
        sideDrawerOpen: false,
        selected: 'US'
    };

    changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    drawerToggleClickHandler = () => {
        this.setState((prevState) => {
            return {sideDrawerOpen: !prevState.sideDrawerOpen};
        });

        this.topbarClassName = !this.state.sideDrawerOpen ? 'contentSendRecieve' : this.originalTopbarClassName
    };

    backdropClickHandler = () => {
        this.setState({sideDrawerOpen: false});
    };
    handleRegister = () => {
        window.location.href = '/signup'
    }

    setSelected = (selected) => {

        this.setState({
            selected: selected
        })

        switch (selected) {
            case "US":
                this.changeLanguage("en");
                break;
            case "BR":
                this.changeLanguage("pt-BR");
                break;
            case "CN":
                this.changeLanguage("zh-CN");
                break;
            default:
                this.changeLanguage("pt-BR");
                break;
        }
    }

    render() {
        let backdrop;

        if (this.state.sideDrawerOpen) {
            backdrop = <BackDrop click={this.backdropClickHandler}/>
        }


        return (
            <>
                <div className={this.topbarClassName}>
                    <div className="topbar__toggle-button">
                        <DrawerToggleButton click={this.drawerToggleClickHandler}/>
                    </div>
                    <Link to="/">
                        <img src={logo} className="leftMenu" alt="gomoney.me"/>
                    </Link>
                    <div className="centerMenu">
                        <ul id="dropdownList">
                            <li><a href='#sobre' to='/about'><Trans>Sobre</Trans></a></li>
                            <li><a href='#gmc'><Trans>GMC</Trans></a></li>
                            <li><a href='#caso'><Trans>Caso de Uso</Trans></a></li>
                            <li><a href='#como'><Trans>Como Funciona</Trans></a>
                                {/* <a href="#howto">Como Funciona</a>
                                <ul id="dropBox">
                                    <li><Link to='/send'>Enviar</Link></li>
                                    <li><Link to='/receive'>Receber</Link></li>
                                </ul> */}

                            </li>
                            <li><a href='#perguntas'><Trans>Ajuda</Trans></a></li>
                            {/* <li><a href="https://gmc.gomoney.me/login">Entrar</a></li> */}
                        </ul>
                    </div>
                    <div className="rightMenu">
                        <ReactFlagsSelect
                            className={"btnLanguageSelection"}
                            fullWidth={false}
                            optionsSize={16}
                            showOptionLabel={false}
                            selected={this.state.selected}
                            selectedSize={16}
                            searchable={false}
                            showSelectedLabel={true}
                            countries={["US", "BR", "CN"]}
                            customLabels={{"US": "EN", "BR": "pt-BR", "CN": "CN"}}
                            onSelect={(code) => this.setSelected(code)}
                        />
                        <a href="https://gmc.gomoney.me/login"><Trans>Entrar</Trans></a>
                        <button className="btnRegister" onClick={this.handleRegister}><Trans>Abra sua conta</Trans></button>
                    </div>
                </div>
                <SideDrawer show={this.state.sideDrawerOpen}/>
                {backdrop}
            </>
        )
    }
}

export default withTranslation()(Topbar)
import React, {Component} from 'react';
import Input from "../../../components/Forms/Input";
import SignupContainer from "../../../components/Signup/SignupContainer";
import * as yup from "yup";
import Upload from "../../../components/Forms/Upload";
import GmcSelect from "../../../components/Forms/GmcSelect";
import {toast} from "react-toastify";
import SignupService from "../../../services/signup";
import CmsService from "../../../services/cms";
import axios from "axios";
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";

class SignupCompanyAddress extends Component {

    constructor(props) {
        super(props);
        this.handleBack = this.handleBack.bind(this)
        this.handleGoNextStep = this.handleGoNextStep.bind(this)
        this.handleUploadProofOfAddressEnterprise = this.handleUploadProofOfAddressEnterprise.bind(this)

        this.state = {
            isLoading: false,
            cepProvided: false,
            street: '',
            number: '',
            complement: '',
            district: '',
            postalCode: '',
            cityId: null,
            stateId: null,
            file: '',
            foreignState: '',
            foreignCity: '',
            countryId: props.location.state.country._id,
            country: props.location.state.country,
            cities: [],
            states: []
        }

        const validations = {
            postalCode: yup
                .string()
                .required(i18next.t('Campo Obrigatório!')),
            file: yup
                .object()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
        }

        if (this.state.country.code === 'BR') {
            Object.assign(validations, {
                street: yup
                    .string()
                    .required(i18next.t('Campo Obrigatório!')),
                number: yup
                    .string()
                    .required(i18next.t('Campo Obrigatório!')),
                district: yup
                    .string()
                    .required(i18next.t('Campo Obrigatório!')),
                cityId: yup
                    .object()
                    .nullable()
                    .required(i18next.t('Campo Obrigatório!')),
                stateId: yup
                    .object()
                    .nullable()
                    .required(i18next.t('Campo Obrigatório!')),
            })
        } else {
            Object.assign(validations, {
                addressLine1: yup.string().required(i18next.t('Campo Obrigatório!')),
            })
        }

        this.addressForm = yup.object().shape(validations);

    }

    async componentWillMount() {
        const statesResponse = await CmsService.getStates();
        this.setState({
            states: statesResponse.data.map(e => {
                return {
                    value: e,
                    label: e.name
                }
            })
        })
    }

    handleStateChange = async (
        newValue,
        actionMeta
    ) => {
        this.setState({
            stateId: newValue,
            cities: [],
            cityId: null
        })

        const citiesResponse = await CmsService.getCities(newValue.value.code);
        this.setState({
            cities: citiesResponse.data.map(e => {
                return {
                    value: e,
                    label: e.name
                }
            })
        })

    };

    handleCityChange = async (
        newValue,
        actionMeta
    ) => {
        this.setState({
            cityId: newValue,
        })
    };

    async handleUploadProofOfAddressEnterprise(file) {
        try {

            const form = new FormData();
            form.append('file', file);
            form.append('documentType', 'PROOF_OF_ADDRESS');
            form.append('documentDirection', 'SINGLE');

            const response = await SignupService.uploadCompanyDocuments(form);

            this.setState({
                file: response.data
            })
            toast.success(i18next.t('Comprovante de endereço anexado com sucesso.'))
        } catch (e) {

        }
    }

    handleBack() {
        this.props.history.goBack();
    }


    async findCep() {
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${this.state.postalCode?.replace(/-/, '')?.replace(/\./, '')}/json/`)
            console.log(response);
            const currentState = this.state.states.find(item => item.value.code === response.data.uf)
            await this.handleStateChange(currentState, null);
            this.setState({
                street: response.data.logradouro,
                complement: response.data.complemento,
                district: response.data.bairro,
                cityId: this.state.cities.find(item => item.value.name === response.data.localidade),
                cepProvided: true
            })
        } catch (e) {
            this.setState({
                cepProvided: false
            })
        }
    }

    async handleGoNextStep() {

        try {

            this.setState({
                isLoading: true,
                errors: [],
                selectedCountry: this.props.location.state.selectedCountry
            })

            const errors = await this.addressForm.validate(this.state, {abortEarly: false})

            if (errors.length > 0) {
                alert('wrong');
            } else {

                const data = this.state;

                if (this.state.country.code === 'BR') {
                    data.cityId = data.cityId.value._id;
                    data.stateId = data.stateId.value._id;
                }

                delete data.states
                delete data.cities

                await SignupService.saveCompanyAddress(data);

                this.props.history.push({
                    pathname: '/signup/company/documents',
                    state: {
                        country: this.state.country
                    }
                })
            }

        } catch (e) {
            if (e.response?.status === 400) {
                toast.error(e.response.data.message);
            } else if (e.inner) {

                console.log(e);

                toast.error(i18next.t('Verifique os campos do formulário.'));

                const errs = [];

                for (const error of e.inner) {
                    errs.push({
                        key: error.path,
                        message: error.message
                    })
                }

                this.setState({
                    errors: errs
                })
            }
        } finally {
            this.setState({
                isLoading: false
            })
        }


    }

    render() {
        return (
            <>
                <SignupContainer>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{
                            display: "flex",
                            maxWidth: "300px",
                            alignSelf: "center",
                            justifyContent: "",
                            flexDirection: "column"
                        }}>

                            <div style={{
                                fontSize: '14px',
                                marginTop: "50px",
                                fontWeight: "500",
                                color: "#cc3366",
                                justifySelf: 'start'
                            }}>
                                <Trans>
                                    Etapa {{current: this.props.step}} de {{total: this.props.stepsTotal}}
                                </Trans>
                            </div>

                            <div style={{
                                fontSize: '24px',
                                fontWeight: "bold",
                                marginTop: "20px",
                                color: "#000066",
                                justifySelf: 'start'
                            }}>
                                <Trans>Dados do<br/> endereço.</Trans>
                            </div>

                            <div style={{marginTop: "20px"}}>

                                {this.state.country.code === 'BR' && <>

                                    <Input label={"CEP"} name={"postalCode"}
                                           mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                           value={this.state.postalCode} onChange={(e) => {
                                        this.setState({postalCode: e.target.value})
                                    }} errors={this.state.errors} onBlur={this.findCep.bind(this)}/>

                                    <Input label={"Logradouro"} name={"street"} maxLength={150}
                                           style={{marginTop: "10px"}}
                                           value={this.state.street} onChange={(e) => {
                                        this.setState({street: e.target.value})
                                    }} errors={this.state.errors}/>

                                    <Input label={"Número"} name={"number"} maxLength={150}
                                           style={{marginTop: "10px"}}
                                           value={this.state.number} onChange={(e) => {
                                        this.setState({number: e.target.value})
                                    }} errors={this.state.errors}/>

                                    <Input label={"Complemento"} name={"complement"} maxLength={150}
                                           style={{marginTop: "10px"}}
                                           value={this.state.complement} onChange={(e) => {
                                        this.setState({complement: e.target.value})
                                    }} errors={this.state.errors}/>

                                    <Input label={"Bairro"} name={"district"} maxLength={150}
                                           style={{marginTop: "10px"}}
                                           value={this.state.district} onChange={(e) => {
                                        this.setState({district: e.target.value})
                                    }} errors={this.state.errors}/>

                                    <GmcSelect
                                        errors={this.state.errors}
                                        name={"stateId"}
                                        label={"Estado"}
                                        options={this.state.states}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                border: "1px solid red"
                                            })
                                        }}
                                        value={this.state.stateId}
                                        isDisabled={this.state.cepProvided}
                                        onChange={this.handleStateChange.bind(this)}/>

                                    <GmcSelect
                                        errors={this.state.errors}
                                        name={"cityId"}
                                        label={"Cidade"}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                border: "1px solid red"
                                            })
                                        }}
                                        options={this.state.cities} value={this.state.cityId}
                                        onChange={this.handleCityChange.bind(this)}
                                        isDisabled={this.state.cities.length === 0 || this.state.cepProvided}
                                    />
                                </>}
                                {this.state.country.code !== 'BR' && <>

                                    <Input label={"Linha Endereço 1"} name={"addressLine1"} maxLength={150}
                                           value={this.state.addressLine1} onChange={(e) => {
                                        this.setState({addressLine1: e.target.value})
                                    }} errors={this.state.errors}/>

                                    <Input label={"Linha Endereço 2"} name={"addressLine2"} maxLength={150}
                                           value={this.state.addressLine2}
                                           style={{marginTop: "10px"}}
                                           onChange={(e) => {
                                               this.setState({addressLine2: e.target.value})
                                           }} errors={this.state.errors}/>

                                    <Input label={"Linha Endereço 3"} name={"addressLine3"} maxLength={150}
                                           value={this.state.addressLine3}
                                           style={{marginTop: "10px"}}
                                           onChange={(e) => {
                                               this.setState({addressLine3: e.target.value})
                                           }} errors={this.state.errors}/>

                                    <Input label={"Código Postal"} name={"postalCode"}
                                           style={{marginTop: "10px"}}
                                           value={this.state.postalCode} onChange={(e) => {
                                        this.setState({postalCode: e.target.value})
                                    }} errors={this.state.errors}/>

                                    <Input label={"Estado"} name={"state"} maxLength={150}
                                           style={{marginTop: "10px"}}
                                           value={this.state.foreignState} onChange={(e) => {
                                        this.setState({foreignState: e.target.value})
                                    }} errors={this.state.errors}/>

                                    <Input label={"Cidade"} name={"state"} maxLength={150}
                                           style={{marginTop: "10px"}}
                                           value={this.state.foreignCity} onChange={(e) => {
                                        this.setState({foreignCity: e.target.value})
                                    }} errors={this.state.errors}/>
                                </>}

                            </div>

                            <div style={{
                                marginTop: "20px"
                            }}>

                                <Upload
                                    errors={this.state.errors}
                                    name={"file"}
                                    label={"Anexar comprovante de endereço"}
                                    btnLabel={"Anexar"}
                                    uploadFile={this.handleUploadProofOfAddressEnterprise.bind(this)}>
                                </Upload>
                            </div>

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px"
                            }}>
                                <button className="btnLink"
                                        onClick={this.handleBack}><Trans>Voltar</Trans>
                                </button>
                                <button className="btnLink"
                                        onClick={this.handleGoNextStep}
                                        disabled={this.state.isLoading}
                                        style={{
                                            backgroundColor: this.state.isLoading ? 'rgba(0,0,102,0.42)' : '',
                                            color: this.state.isLoading ? '#FFF' : ''
                                        }}
                                ><Trans>Prosseguir</Trans>
                                    {this.state.isLoading ? <span style={{marginLeft: '5px'}}>
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                    </span> : ''}
                                </button>
                            </div>
                        </div>

                    </div>
                </SignupContainer>
            </>
        );

    }
}


export default withTranslation()(SignupCompanyAddress);

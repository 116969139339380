import {httpClient} from "../utils/http-client";
import axios from "axios";

class SignupService {

    async signup(data) {
        const response = await httpClient().post('/signup', data)
        localStorage.setItem('token', response.data.access_token);
        return response
    }

    async updateProfile(data) {
        return httpClient().post('/profile/general', data);
    }

    async saveCompanyAddress(data) {
        return await httpClient().post('/company/address', data);
    }

    async saveProfileAddress(data) {
        return await httpClient().post('/profile/address', data);
    }

    async createCompanyPartner(data) {
        return await httpClient().post('/company/partners', data);
    }

    async deleteCompanyPartner(id) {
        return await httpClient().delete(`/company/partners/${id}`);
    }

    async completeSignup() {
        return await httpClient().post(`/signup/complete`);
    }

    // UPLOADS
    async uploadCompanyDocuments(data) {
        return await httpClient().post('/company/documents', data)
    }

    async uploadProfileDocuments(data) {
        return await httpClient().post('/profile/documents', data)
    }

    async uploadCompanyPartnerDocuments(partnerId, data) {
        return await httpClient().post(`/company/partners/${partnerId}/documents`, data)
    }
}


export default new SignupService()
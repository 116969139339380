import React from 'react';
import { Link } from 'react-router-dom';
import '../SendAndRecieve/styles.css';
import cadastro from '../../assets/icone_cadastro.svg';
import insiraFundos from '../../assets/insira_fundos.svg';
import converta from '../../assets/converta.svg';
import transfira from '../../assets/transfira.svg';
import {Trans, withTranslation} from "react-i18next";

function HowItWorksReceive () {
    return (
            <div className="container-fluid">
                <div className="HowItWorksCenter">
                    <div className="centerElement">
                        <img src={cadastro} alt="Cadastre-se"/>
                        <h3>
                            <Trans>1. Consulte seu canal bancário</Trans>
                        </h3>
                        <p>
                        <Trans>Solicite os dados do canal bancário para recebimentos do exterior através do e-mail</Trans> <Link to='' target='_blank' onClick={() => window.location = 'mailto:contact@gomoney.me  '}> contact@gomoney.me  </Link> <Trans>Solicite email</Trans>
 
                        </p>
                    </div>
                    <div className="centerElement">
                        <img src={insiraFundos} alt="Insira Fundos"/>
                        <h3>
                            <Trans>2. Informe os dados da operação</Trans>
                        </h3>
                        <p>
                        <Trans>Envie uma cópia do comprovante bancário da operação para nosso time.</Trans>

                        </p>
                    </div>
                    <div className="centerElement">
                        <img src={converta} alt="Converta"/>
                        <h3>
                            <Trans>3. Receba o valor em GMC</Trans>
                        </h3>
                        <p>
                            <Trans>Você receberá o crédito em GoMoney Coin (GMC), diretamente na sua conta GoMoney.</Trans>
                        </p>
                    </div>
                    <div className="centerElement">
                        <img src={transfira} alt="Transfira"/>
                        <h3>
                            <Trans>4. Resgate seus GMCs quando quiser</Trans>
                        </h3>
                        <p>
                            <Trans>Com o saldo em GMCs disponível</Trans>
                        </p>
                    </div>
                </div>   
                <div className="CSSgal">

                    <s id="s1"></s> 
                    <s id="s2"></s>
                    <s id="s3"></s>
                    <s id="s4"></s>

                    <div className="slider">
                    <div >
                        <div className="centerElement">
                            <img src= {cadastro} alt="Cadastre-se"/>
                            <h3><Trans>1. Consulte seu canal bancário</Trans></h3>
                            <p>
                                <Trans>Solicite os dados do canal bancário para recebimentos do exterior através do e-mail</Trans> <Link to='' target='_blank' onClick={() => window.location = 'mailto:contact@gomoney.me  '}> contact@gomoney.me  </Link> <Trans>Solicite email</Trans>
                            </p>
                        </div>
                        </div>
                        <div >
                                <div className= "centerElement">
                                <img src={insiraFundos} alt="Insira Fundos"/>
                                <h3><Trans>2. Informe os dados da operação</Trans></h3>
                                <p>
                                    <Trans>Envie uma cópia do comprovante bancário da operação para nosso time.</Trans>
                                </p>
                            </div>
                        </div>
                    <div >
                        <div className="centerElement">
                            <img src={converta} alt="Converta"/>
                            <h3><Trans>3. Receba o valor em GMC</Trans></h3>
                            <p>
                                <Trans>Você receberá o crédito em GoMoney Coin (GMC), diretamente na sua conta GoMoney.</Trans>
                            </p>
                        </div>
                        </div>
                    <div >
                        <div className="centerElement">
                            <img src={transfira} alt="Transfira"/>
                            <h3>
                                <Trans>4. Resgate seus GMCs quando quiser</Trans>
                            </h3>
                            <p>
                                <Trans>Com o saldo em GMCs disponível</Trans>
                            </p>
                        </div>
                        </div>
                    </div>

                    <div className="prevNext">
                        <div>
                            <a href="#s4"> </a>
                            <a href="#s2"> </a>
                        </div>
                        <div>
                            <a href="#s1"> </a>
                            <a href="#s3"> </a>
                        </div>
                        <div>
                            <a href="#s2"> </a>
                            <a href="#s4"> </a>
                        </div>
                        <div>
                            <a href="#s3"> </a>
                            <a href="#s1"> </a>
                        </div>
                    </div>

                    <div className="bullets">
                    <a href="#s1"> </a>
                    <a href="#s2"> </a>
                    <a href="#s3"> </a>
                    <a href="#s4"> </a>
                    </div>

                </div>
                {/* <div className="HowItWorksBottom">
                    <Link to="/receive" > <button className="HowItWorksButton">Quero saber mais como receber</button> </Link>
                </div> */}
            </div>
            
        )   
}
export default withTranslation()(HowItWorksReceive)
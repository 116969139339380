import React, { useState } from 'react';
import './styles.css';
import HowItWorksReceive from '../HowItWorksReceive';
import HowItWorksSend from '../HowItWorksSend';
import {Trans, withTranslation} from "react-i18next";

function SendAndReceive(){
    const [buttonState, setButtonState] = useState(true);

    function handleOnClickButton(status){

        
        if(status === 'send'){
            setButtonState(true)
        } else {
            setButtonState(false)
        }
        console.log(buttonState)
    }

    return (
        <section id='como'>
            <div className="HowItWorksTop">
                <h1><Trans>Como funciona a GoMoney?</Trans></h1>
                <span>
                    
                    <button className={buttonState ?  'HowItWorksActive' : 'HowItWorksInactive'} onClick={() => handleOnClickButton('send')} ><Trans>Quero enviar</Trans></button>
                    <button className={!buttonState ? 'HowItWorksActive' : 'HowItWorksInactive'} onClick={() => handleOnClickButton('receive')} ><Trans>Quero receber</Trans></button>
                    
                </span>
            </div>
            {
                buttonState ? <HowItWorksSend /> : <HowItWorksReceive />
                    
            }
            
        </section>
    )
}

export default withTranslation()(SendAndReceive)

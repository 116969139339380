import React, {Component} from 'react';

import TopBar from '../components/Topbar';
import Footer from '../components/Footer';
import PrivacityPolicy from '../components/PrivacityPolicy';
import TermsOfUse from '../components/TermsOfUse';


class Legal extends Component{
     render () {
        return (
        <>
                <TopBar topbar='contentSendRecieve'/>
                {window.location.pathname.includes('termos-de-uso') ? <TermsOfUse /> : <PrivacityPolicy />}
                <Footer />
        </>
        )
     }
 }
 
 
 export default Legal;

import React from "react";
import MaskedInput from "react-text-mask/dist/reactTextMask";
import {Trans} from "react-i18next";

export default function Input(props) {
    let type = props.type || "text"

    const error = props.errors?.find((e) => e.key === props.name)
    const errorMessage = <div style={{fontSize: '12px', marginTop: "5px", color: "red"}}>
        {(error?.message || '').replace(/error.key/, props.label)}
    </div>

    const maskedInput = <MaskedInput mask={props.mask}
                                     className="gmc-input" onChange={props.onChange} maxLength={props.maxLength}
                                     style={{border: error ? "1px solid red" : ""}}
                                     guide={false}
                                     placeholder={props.placeholder}
                                     onBlur={props.onBlur}
                                     value={props.value}/>

    const regularInput = <input type={props.type} name={props.name}
                                className="gmc-input" onChange={props.onChange}
                                maxLength={props.maxLength}
                                onBlur={props.onBlur}
                                placeholder={props.placeholder}
                                style={{border: error ? "1px solid red" : ""}}
                                value={props.value}/>

    return (
        <div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                ...props.style
            }}>
                <label htmlFor={props.name} className="gmc-label">
                    <Trans>
                        {props.label}
                    </Trans>
                </label>
                <>
                    {props.mask ? maskedInput : regularInput}
                    {error ? errorMessage : null}
                </>

            </div>
        </div>
    )
}
import styled from "styled-components"
const Style = styled.div.attrs(props => ({
  color: props.color,
}))`

 margin-top: 20px;
  
  .PhoneInput {
    border-radius: 10px;
    border: 1px solid #000066;
    padding-left: 5px;
    height: 33px;
  }
  
.PhoneInputInput{
    width: 40px;
    border-radius: 10px;
    height: 33px;
    background:transparent;
    border: 0px solid ${props => props.color};
      outline: none;
}
`

export default Style
import React from 'react';
import './styles.css';

export default function HowToReceive() {
    const handleRegister =() =>{
        window.location.href='https://gmc.gomoney.me/login/signup'
    }
    return(
        <>
        <div className="HowToReceiveContainer">
            <div className="HowToReceiveContent">
                <h1>
                    Como receber <br/>
                    em qualquer lugar do <br/>
                    mundo com a GoMoney
                </h1>
                <p>
                    Com a GoMoney você recebe transferências de pessoas <br/>
                    ou empresas em qualquer lugar do mundo com rapidez <br/>
                    e segurança.
                </p>
                <button className="HowToReceiveButton" onClick={handleRegister}>Comece a Receber</button>
            </div>
        </div>
            
        </>
    )
}
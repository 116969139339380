import React from 'react';
import conhecaIcon from '../../assets/icone_gmc.png';
import './styles.css';
import {Trans, withTranslation} from "react-i18next";

function ourHistory(){
    return(
        <div className="knowOurHistoryContainer">
            <div className="knowOurHistoryLeft">
                <img src={conhecaIcon} className="knowOurHistoryImg" alt="Motivos"/>
            </div>
            <div className="knowOurHistoryRight">
                <h1>
                    <Trans>Proteger-se da volatilidade cambial</Trans>
                </h1>
                <p>
                    <Trans>protegerse</Trans>
                </p>
                {/* <Link to="/about"><button className="knowOurHistoryButton">Conheça nossa história</button></Link> */}
            </div>
        </div>
    )
}

export default withTranslation()(ourHistory)
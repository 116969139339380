import React, {Component} from 'react';
import TopBar from '../components/Topbar';
import DownloadApp from '../components/DownloadApp';
import AskedQuestions from '../components/AskedQuestions';
import StartNow from '../components/StartNow';
import HowTransfer from '../components/HowTransfer';
import SendEasy from '../components/SendIsEasy';
import InsertFunds from '../components/InsertFunds';
import ConvertFunds from '../components/ConvertFunds';
import DestinationData  from '../components/DestinationData';
import TransferForWhoYouWant from '../components/TransferForWhoYouWant';
import HrSeparator from '../components/SeparatorHr';
import Footer from '../components/Footer';

class Send extends Component{
 
 
     render () {
         
             return (
                <>
                <TopBar topbar= 'contentSendRecieve'/>
                <HowTransfer />
                <SendEasy />
                <InsertFunds />
                <ConvertFunds />
                <DestinationData />
                <TransferForWhoYouWant />
                <DownloadApp />
                <AskedQuestions />
                <StartNow />
                <HrSeparator />
                <Footer />           
                </>
             );
           
     }
 }
 
 
 export default Send;
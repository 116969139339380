import React from "react";
import MaskedInput from "react-text-mask/dist/reactTextMask";
import Select from "react-select";

export default function GmcErrorMessage(props) {

    const error = props.errors?.find((e) => e.key === props.name)
    const errorMessage = <div style={{fontSize: '12px', marginTop: "5px", color: "red"}}>
        {(error?.message || '').replace(/error.key/, props.label)}
    </div>

    return (
        <>
            {error ? errorMessage : null}
        </>

    )
}
import React, {Component} from 'react';
import Input from "../../../components/Forms/Input";
import SignupContainer from "../../../components/Signup/SignupContainer";
import * as yup from 'yup'
import {toast} from "react-toastify";
import SignupService from "../../../services/signup";
import GmcSelect from "../../../components/Forms/GmcSelect";
import * as DocumentTypes from "../../../constants/document-types";
import 'react-phone-number-input/style.css'
import InputPhone from '../../../components/InputPhone/InputPhone'
import moment from "moment"
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";
import {Link} from "react-router-dom";

class SignupPeople extends Component {


    constructor(props) {

        super(props);
        this.handleBack = this.handleBack.bind(this)
        this.handleGoNextStep = this.handleGoNextStep.bind(this)

        this.handleDocumentTypeChange = this.handleDocumentTypeChange.bind(this)


        this.state = {
            isLoading: false,
            name: '',
            lastName: '',
            dateOfBirth: '',
            email: '',
            emailRepeat: '',
            phone: '',
            phoneDdi: null,
            password: '',
            passwordRepeat: '',
            documentTypes: DocumentTypes,
            documentValue: '',
            documentType: [],
            flow: 'people',
            errors: [],
            countryId: props.location.state.country._id,
            country: props.location.state.country,
            userType: 'PF',
            termsAccepted: false,
            privacyAccepted: false
        }

        if (this.state.country.code === 'BR') {
            this.setState({
                documentType: {label: 'CPF', value: 'CPF'},
            })
        }

        const validations = {
            name: yup.string().required(i18next.t('Campo Obrigatório!')),
            lastName: yup.string().required(i18next.t('Campo Obrigatório!')),
            documentValue: yup.string().required(i18next.t('Campo Obrigatório!')),
            dateOfBirth: yup.date().transform((value, originalValue) => {
                var parsedDate = moment.isDate(originalValue, 'DD/MM/YYYY')
                    ? moment(originalValue, 'DD/MM/YYYY').format('DD/MM/YYYY')
                    : moment(originalValue, 'DD/MM/YYYY').format('MM/DD/YYYY')
                return new Date(parsedDate)
            }).test("DOB", i18next.t("Data Inválida!"), (curr, value) => {
                return moment().diff(moment(value.originalValue, 'dd/mm/YYYY'), "years") >= 18 && moment().diff(moment(value.originalValue, 'dd/mm/YYYY'), "years") != 0;
            }).typeError(i18next.t('Data Inválida!')),

            email: yup.string().email('E-mail inválido!').required(i18next.t('Campo Obrigatório!')),
            emailRepeat: yup.string().email('E-mail inválido!').required(i18next.t('Campo Obrigatório!'))
                .oneOf([yup.ref('email'), null], i18next.t('Os emails devem corresponder!')),
            phone: yup.string().required(i18next.t('Campo Obrigatório!')),
            phoneDdi: yup.string().required(),
            password: yup.string().required(i18next.t('Campo Obrigatório!')).matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                i18next.t("Deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere especial")
            ),
            passwordRepeat: yup.string().required(i18next.t('Campo Obrigatório!'))
                .oneOf([yup.ref('password'), null], i18next.t('As senhas devem corresponder!')),
            termsAccepted: yup.bool().oneOf([true], 'Obrigatório'),
            privacyAccepted: yup.bool().oneOf([true], 'Obrigatório'),
        };

        if (this.state.country.code !== 'BR') {
            validations['documentType'] = yup.object().nullable().required(i18next.t('Campo Obrigatório!'));
        }

        this.enterpriseForm = yup.object().shape(validations);

    }

    toggleTermsChange = () => {
        this.setState({
            termsAccepted: !this.state.termsAccepted,
        });
    }

    togglePrivacyChange = () => {
        this.setState({
            privacyAccepted: !this.state.privacyAccepted,
        });
    }

    componentDidMount() {
        if (this.state.country.code === 'BR') {
            this.setState({
                documentTypes: DocumentTypes.BRAZIL
            })
        } else {
            this.setState({
                documentTypes: DocumentTypes.OTHERS
            })
        }

    }


    handleBack() {
        this.props.history.goBack();
    }

    handleDocumentTypeChange(value) {
        this.setState({
            documentType: value
        })
    }

    async handleGoNextStep() {

        try {

            console.log(this.state)
            this.setState({
                isLoading: true,
                errors: [],
            })

            const data = Object.assign({}, this.state);
            data.documentType = data.documentType.value

            const errors = await this.enterpriseForm.validate(this.state, {abortEarly: false})

            if (errors.length > 0) {
                alert('wrong');
            } else {
                await SignupService.signup(data);
                this.props.history.push({
                    pathname: '/signup/people/address',
                    state: {
                        country: this.state.country,
                        documentType: this.state.documentType
                    }
                })
            }

        } catch (e) {

            if (e.response?.status === 400) {
                toast.error(e.response.data.message);
            } else if (e.inner) {

                toast.error(i18next.t('Verifique os campos do formulário.'));

                const errs = [];

                for (const error of e.inner) {
                    errs.push({
                        key: error.path,
                        message: error.message
                    })
                }

                this.setState({
                    errors: errs
                })
            }

        } finally {
            this.setState({
                isLoading: false
            })
        }


    }

    render() {
        return (
            <>
                <SignupContainer>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{display: "flex", maxWidth: "300px", alignSelf: "center", flexDirection: "column"}}>

                            <div style={{
                                fontSize: '14px',
                                marginTop: "50px",
                                fontWeight: "500",
                                color: "#cc3366",
                                justifySelf: 'start'
                            }}>
                                <Trans>
                                    Etapa {{current: this.props.step}} de {{total: this.props.stepsTotal}}
                                </Trans>
                            </div>

                            <div style={{
                                fontSize: '24px',
                                fontWeight: "bold",
                                marginTop: "20px",
                                color: "#000066",
                                justifySelf: 'start'
                            }}>
                                <Trans>
                                    Dados Pessoais
                                </Trans>
                            </div>

                            <div style={{marginTop: "20px"}}>

                                <Input label={"Nome"} name={"name"} maxLength={150}
                                       value={this.state.name} onChange={(e) => {
                                    this.setState({name: e.target.value})
                                }} errors={this.state.errors}/>

                                <Input label={"Sobrenome"} name={"lastName"}
                                       style={{marginTop: "10px"}}
                                       value={this.state.lastName}
                                       onChange={(e) => {
                                           this.setState({
                                               lastName: e.target.value
                                           });
                                       }}
                                       errors={this.state.errors}/>
                                <Input label={"Data de Nascimento"} type="date" name={"dateOfBirth"}
                                       errors={this.state.errors}
                                       placeholder={"dd/mm/yyyy"}
                                       mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                       style={{marginTop: "10px"}} value={this.state.dateOfBirth} onChange={(e) => {
                                    this.setState({dateOfBirth: e.target.value})
                                }}/>

                                {this.state.country.code !== 'BR' && <>
                                    <div style={{marginTop: "10px"}}>
                                        <GmcSelect
                                            errors={this.state.errors}
                                            name={"documentType"}
                                            label={"Tipo de Documento"}
                                            options={this.state.documentTypes}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: "1px solid red"
                                                })
                                            }}
                                            value={this.state.documentType}
                                            onChange={this.handleDocumentTypeChange.bind(this)}/>
                                    </div>
                                    {this.state.documentType != null && <>
                                        <Input label={"Número do Documento"} name={"documentValue"}
                                               errors={this.state.errors}
                                               style={{marginTop: "10px"}} value={this.state.documentValue}
                                               onChange={(e) => {
                                                   this.setState({documentValue: e.target.value})
                                               }}/>
                                    </>}
                                </>}

                                {this.state.country.code === 'BR' && <>
                                    <Input label={"CPF"} name={"documentValue"}
                                           errors={this.state.errors}
                                           mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                           style={{marginTop: "10px"}} value={this.state.documentValue}
                                           onChange={(e) => {
                                               this.setState({documentValue: e.target.value})
                                           }}/>
                                </>}
                                <Input label={"E-mail de acesso"} name={"email"}
                                       style={{marginTop: "10px"}} maxLength={150} type="email" value={this.state.email}
                                       onChange={(e) => {
                                           this.setState({email: e.target.value})
                                       }}
                                       errors={this.state.errors}/>
                                <Input label={"Confirmar e-mail de acesso"} name={"emailRepeat"}
                                       style={{marginTop: "10px"}} maxLength={150} type="email"
                                       value={this.state.emailRepeat}
                                       onChange={(e) => {
                                           this.setState({emailRepeat: e.target.value})
                                       }}
                                       errors={this.state.errors}/>
                                <div style={{display: "flex"}}>
                                    <InputPhone phoneCountry={this.state.phoneCountry} setPhone={this}
                                                errors={this.state.errors}/>
                                    <Input name={"phone"}
                                           style={{marginTop: "38px", width: "92%", paddingLeft: "10px"}}
                                           mask={this.state.phoneDdi === '+55' ? [/\d/, /\d/, " ", /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/] : false}
                                           value={this.state.phone}
                                           onChange={(e) => {
                                               this.setState({phone: e.target.value})
                                           }}
                                           errors={this.state.errors}/>
                                </div>

                            </div>

                            <div style={{
                                fontSize: '24px',
                                fontWeight: "bold",
                                marginTop: "40px",
                                color: "#000066",
                                justifySelf: 'start'
                            }}>
                                <Trans>
                                    Escolha <br/> sua senha.
                                </Trans>
                            </div>
                            <div style={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#000066",
                                marginTop: "20px"
                            }}>
                                <Trans>
                                    Por favor, crie uma senha da sua escolha,
                                    atendendo aos requisitos abaixo:
                                </Trans>
                            </div>

                            <div style={{
                                fontSize: "12px",
                                marginTop: "20px"
                            }}>
                                <ul>
                                    <li>
                                        <Trans>
                                            Deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um
                                            caractere
                                            especial
                                        </Trans>
                                    </li>
                                </ul>
                            </div>

                            <Input type="password" label={"Senha"} name={"password"}
                                   style={{marginTop: "20px"}} value={this.state.password} onChange={(e) => {
                                this.setState({password: e.target.value})
                            }} errors={this.state.errors}/>
                            <Input type="password" label={"Repetir senha"} name={"passwordRepeat"}
                                   style={{marginTop: "10px"}} value={this.state.passwordRepeat} onChange={(e) => {
                                this.setState({passwordRepeat: e.target.value})
                            }} errors={this.state.errors}/>

                            <label className="inline-flex items-center mt-3">
                                <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600"
                                       checked={this.state.termsAccepted} onChange={this.toggleTermsChange}/><span
                                className="ml-2 text-gray-700">
                                <Trans i18nKey="termsOfUsage">Aceito os <a href="https://gomoney.me/legal/termos-de-uso"
                                                                           target="_blank">Termos de Uso</a></Trans>

                            </span>
                            </label>
                            <label className="inline-flex items-center mt-3">
                                <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600"
                                       checked={this.state.privacyAccepted} onChange={this.togglePrivacyChange}/><span
                                className="ml-2 text-gray-700">
                                <Trans i18nKey="privacyPolicy">
                                    Aceito as <a href="https://gomoney.me/legal/politicas-de-privacidade"
                                                 target="_blank">Políticas de Privacidade</a>
                                       </Trans>
                                </span>
                            </label>

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px"
                            }}>
                                <button className="btnLink"
                                        onClick={this.handleBack}><Trans>
                                    Voltar
                                </Trans>
                                </button>
                                <button className="btnLink"
                                        onClick={this.handleGoNextStep}
                                        disabled={this.state.isLoading}
                                        style={{
                                            backgroundColor: this.state.isLoading ? 'rgba(0,0,102,0.42)' : '',
                                            color: this.state.isLoading ? '#FFF' : ''
                                        }}
                                ><Trans>
                                    Prosseguir
                                </Trans>
                                    {this.state.isLoading ? <span style={{marginLeft: '5px'}}>
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                    </span> : ''}
                                </button>
                            </div>
                        </div>
                    </div>
                </SignupContainer>
            </>
        );

    }
}


export default withTranslation()(SignupPeople);

import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

export default function YouCan(){
    return (
        <>
        <div className="YouCanContainer"> 
            <div className="YouCanContentLeft">
                <h1>
                    Você pode <br/> ser global!
                </h1> 
                
                <div className="YouCanBtnDiv">
                    <Link to='qualquercoisa'><button className="YouCanBtn">Clique e assista</button></Link>
                </div>
                
                
            </div>
            <div className="YouCanContentRight"></div>
        </div>
        <div className="LearnMore">
            <p>Continua com dúvidas? Leia mais <Link to='/faq'><strong>clicando aqui.</strong> </Link> </p>
        </div>
        </>
    )
}
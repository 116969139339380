import React from 'react';
import financialIcon from '../../assets/icone_enviar_gmc.png';
import './styles.css';

export default function FinancialArea(){
    return(
        <>
        <div className="FinancialAreaContainer">
            <div className="FinancialAreaLeft">
                <h1>
                    Área financeira <br/>
                    com tecnologia
                </h1>
                    <p>
                        juntando suas experiências, um da Área Financeira e <br/> outro da Área de Tecnologia, perceberam que poderiam <br/> fazer algo inovador e que teriam as ferramentas para <br/> criar um produto que atravessasse barreiras, unisse <br/> pessoas e conectasse negócios.
                    </p>
            </div>
            <div className="FinancialAreaRight">
                <img src={financialIcon} alt="Área financeira"/>
            </div>
        </div>
        </>
    )
}
import React from 'react';
import WeHelpYou from '../components/WeHelpYou';
import Footer from '../components/Footer';
import FaqMainComponent from '../components/FaqMainComponent';
//import TheGoMoney from '../components/TheGoMoney';
//import FaqAccount from '../components/FaqAccount';


export default function Help (){
    return (
        <>
            <WeHelpYou />
            <FaqMainComponent />
            {/* <TheGoMoney />
            <FaqAccount />*/}
            <Footer />
           
        </>
    )
}
import React, {Component} from 'react';
import Input from "../../../components/Forms/Input";
import SignupContainer from "../../../components/Signup/SignupContainer";
import * as yup from "yup";
import GmcSelect from "../../../components/Forms/GmcSelect";
import * as DocumentTypes from '../../../constants/document-types'
import Upload from "../../../components/Forms/Upload";
import {toast} from "react-toastify";
import SignupService from '../../../services/signup'
import GmcErrorMessage from "../../../components/Forms/GmcErrorMessage";
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";

class SignupCompanyPartners extends Component {

    constructor(props) {
        super(props);
        this.handleBack = this.handleBack.bind(this)
        this.handleGoNextStep = this.handleGoNextStep.bind(this)
        this.handleAddMore = this.handleAddMore.bind(this)
        this.handleCompleteSignup = this.handleCompleteSignup.bind(this)

        this.handleIsPoliticallyExposedPerson = this.handleIsPoliticallyExposedPerson.bind(this)
        this.handleIsNotPoliticallyExposedPerson = this.handleIsNotPoliticallyExposedPerson.bind(this)
        this.handleIsAmericanCitizen = this.handleIsAmericanCitizen.bind(this)
        this.handleIsNotAmericanCitizen = this.handleIsNotAmericanCitizen.bind(this)

        this.handleDocumentTypeChange = this.handleDocumentTypeChange.bind(this)
        this.handleUploadProofOfAddress = this.handleUploadProofOfAddress.bind(this)
        this.handleUploadDocumentFront = this.handleUploadDocumentFront.bind(this)
        this.handleUploadDocumentBack = this.handleUploadDocumentBack.bind(this)
        this.handleUploadSelfie = this.handleUploadSelfie.bind(this)

        this.handleDeletePartner = this.handleDeletePartner.bind(this)

        this.state = {
            isLoading: false,
            name: '',
            dateOfBirth: '',
            cpf: '',
            documentTypes: [],
            documentType: null,
            documentValue: '',
            jobTitle: null,
            proofOfAddress: null,
            documentFront: null,
            documentBack: null,
            selfie: null,
            partners: [],
            errors: [],
            isPoliticallyExposedPerson: null,
            isAmericanCitizen: null,
            addNew: false,
            countryId: props.location.state.country._id,
            country: props.location.state.country,
        }

        this.partnerForm = yup.object().shape({
            name: yup
                .string()
                .required(i18next.t('Campo Obrigatório!')),
            dateOfBirth: yup
                .string()
                .min(8, i18next.t('Campo Obrigatório!'))
                .nullable(),
            documentType: yup.object().nullable().required(i18next.t('Campo Obrigatório!')),
            documentValue: yup.string().required(i18next.t('Campo Obrigatório!')),
            jobTitle: yup.string().nullable().required(i18next.t('Campo Obrigatório!')),
            proofOfAddress: yup
                .object()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
            documentFront: yup
                .object()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
            documentBack: yup
                .object()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
            selfie: yup
                .object()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
            isPoliticallyExposedPerson: yup
                .bool()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
            isAmericanCitizen: yup
                .bool()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
        });
    }

    componentDidMount() {
        if (this.state.country.code === 'BR') {
            this.setState({
                documentTypes: DocumentTypes.BRAZIL.map(item => {
                    item.label = i18next.t(item.label)
                    return item;
                })
            })
        } else {
            this.setState({
                documentTypes: DocumentTypes.OTHERS.map(item => {
                    item.label = i18next.t(item.label)
                    return item;
                })
            })
        }

    }

    handleBack() {
        this.props.history.goBack();
    }

    handleAddMore(value) {
        this.setState({
            addNew: value
        })
    }

    async handleCompleteSignup() {
        try {
            await SignupService.completeSignup();
            this.props.history.push({
                pathname: '/signup/email-confirmation-company',
                state: {
                    country: this.state.country
                }
            })

        } catch (e) {

        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    async handleGoNextStep() {
        try {

            this.setState({
                isLoading: true,
                errors: [],
                selectedCountry: this.props.location.state.selectedCountry
            })

            await this.partnerForm.validate(this.state, {abortEarly: false})

            const formData = new FormData();

            for (const prop of Object.keys(this.state)) {
                if (!['documentType', 'proofOfAddress', 'documentFront', 'documentBack', 'selfie'].includes(prop)) {
                    formData.append(prop, this.state[prop]);
                }
            }

            formData.append('documentType', this.state.documentType.value)

            if (this.state.country.code === 'BR') {
                formData.append('identityDocument[documentType]', 'CPF')
                formData.append('identityDocument[documentValue]', this.state.documentValue)

            } else {
                formData.append('identityDocument[documentType]', this.state.documentType.value)
                formData.append('identityDocument[documentValue]', this.state.documentValue)
            }

            formData.append('proofOfAddress', this.state.proofOfAddress.file);
            formData.append('documentFront', this.state.documentFront.file);
            formData.append('documentBack', this.state.documentBack.file);
            formData.append('selfie', this.state.selfie.file);

            const response = await SignupService.createCompanyPartner(formData);

            this.setState({
                partners: [...this.state.partners, response.data],
                addNew: false
            })

            this.clearForm();

            toast.success(i18next.t('Sócio ou representante legal da empresa adicionado com sucesso!'))

        } catch (e) {
            if (e.response?.status === 400) {
                toast.error(e.response.data.message);
            } else if (e.inner) {

                console.log(e);
                console.log(e.inner);

                toast.error(i18next.t('Verifique os campos do formulário.'));

                const errs = [];

                for (const error of e.inner) {
                    errs.push({
                        key: error.path,
                        message: error.message
                    })
                }

                this.setState({
                    errors: errs
                })
            }
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }


    clearForm() {
        this.setState({
            name: '',
            dateOfBirth: '',
            cpf: '',
            documentType: null,
            documentValue: '',
            jobTitle: '',
            proofOfAddress: null,
            documentFront: null,
            documentBack: null,
            selfie: null,
            isPoliticallyExposedPerson: null,
            isAmericanCitizen: null
        })
    }

    handleDocumentTypeChange(value) {
        this.setState({
            documentType: value
        })
    }


    async handleUploadProofOfAddress(file) {
        console.log(file)
        try {
            this.setState({
                proofOfAddress: {
                    file: file
                }
            })
            console.log(this.state.proofOfAddress)
            return true;
        } catch (e) {

        }
    }

    async handleUploadDocumentFront(file) {
        try {
            this.setState({
                documentFront: {
                    file: file
                }
            })
            return true;
        } catch (e) {

        }
    }

    async handleUploadDocumentBack(file) {
        try {
            this.setState({
                documentBack: {
                    file: file
                }
            })
            return true;
        } catch (e) {

        }
    }

    async handleUploadSelfie(file) {
        try {
            this.setState({
                selfie: {
                    file: file
                }
            })
            return true;
        } catch (e) {

        }
    }

    handleIsPoliticallyExposedPerson() {
        this.setState({
            isPoliticallyExposedPerson: true
        })
    }

    handleIsNotPoliticallyExposedPerson() {
        this.setState({
            isPoliticallyExposedPerson: false
        })
    }

    handleIsAmericanCitizen() {
        this.setState({
            isAmericanCitizen: true
        })
    }

    handleIsNotAmericanCitizen() {
        this.setState({
            isAmericanCitizen: false
        })
    }

    async handleDeletePartner(partner) {
        try {
            await SignupService.deleteCompanyPartner(partner._id);
            this.setState({
                partners: this.state.partners.filter(p => p._id !== partner._id)
            })

            toast.warning(i18next.t('Removido com sucesso.'))
        } catch (e) {
            toast.error('Não foi possível remover o representante.')
        }
    }


    render() {
        return (
            <>
                <SignupContainer>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{
                            display: "flex",
                            maxWidth: "300px",
                            alignSelf: "center",
                            justifyContent: "",
                            flexDirection: "column"
                        }}>

                            <div style={{
                                fontSize: '14px',
                                marginTop: "50px",
                                fontWeight: "500",
                                color: "#cc3366",
                                justifySelf: 'start'
                            }}>
                                <Trans>
                                    Etapa {{current: this.props.step}} de {{total: this.props.stepsTotal}}
                                </Trans>
                            </div>

                            <div style={{
                                fontSize: '24px',
                                fontWeight: "bold",
                                marginTop: "20px",
                                color: "#000066",
                                justifySelf: 'start'
                            }}>
                                <Trans>Documentos.</Trans>
                            </div>

                            <div style={{
                                fontSize: "14px"
                            }}>
                                <Trans>Sócios ou representante legal da empresa.</Trans>
                            </div>

                            {(this.state.partners.length === 0 || this.state.addNew) && <>
                                <div style={{marginTop: "20px"}}>
                                    <Input label={"Nome completo"} name={"name"} maxLength={150}
                                           value={this.state.name} onChange={(e) => {
                                        this.setState({name: e.target.value})
                                    }} errors={this.state.errors}/>
                                    <Input label={"Data de Nascimento"} name={"dateOfBirth"}
                                           errors={this.state.errors}
                                           mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                           style={{marginTop: "10px"}} value={this.state.dateOfBirth} onChange={(e) => {
                                        this.setState({dateOfBirth: e.target.value})
                                    }}/>
                                    {this.state.country.code !== 'BR' && <>
                                        <div style={{marginTop: "20px"}}>
                                            <GmcSelect
                                                errors={this.state.errors}
                                                name={"documentType"}
                                                label={"Tipo de Documento"}
                                                options={this.state.documentTypes}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        border: "1px solid red"
                                                    })
                                                }}
                                                value={this.state.documentType}
                                                onChange={this.handleDocumentTypeChange.bind(this)}/>
                                        </div>
                                    </>}

                                    {this.state.documentType && this.state.country.code !== 'BR' &&
                                    <div style={{marginTop: "20px"}}>
                                        <Input name={"documentValue"} maxLength={150}
                                               label={"Número do Documento"}
                                               value={this.state.documentValue} style={{marginTop: "10px"}}
                                               onChange={(e) => {
                                                   this.setState({documentValue: e.target.value})
                                               }} errors={this.state.errors}/>
                                    </div>}

                                    {this.state.country.code === 'BR' && <>
                                        <Input label={"CPF"} name={"documentValue"}
                                               errors={this.state.errors}
                                               mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                               style={{marginTop: "10px"}} value={this.state.documentValue}
                                               onChange={(e) => {
                                                   this.setState({documentValue: e.target.value})
                                               }}/>
                                    </>}

                                    <Input label={"Cargo"} name={"jobTitle"} maxLength={150}
                                           value={this.state.jobTitle} style={{marginTop: "10px"}} onChange={(e) => {
                                        this.setState({jobTitle: e.target.value})
                                    }} errors={this.state.errors}/>
                                </div>

                                <div style={{
                                    marginTop: "20px"
                                }}>

                                    <Upload
                                        errors={this.state.errors}
                                        name={"proofOfAddress"}
                                        label={"Anexar comprovante de residência"}
                                        btnLabel={"Anexar"}
                                        uploadFile={this.handleUploadProofOfAddress}>
                                    </Upload>
                                </div>


                                {this.state.country.code === 'BR' && <>
                                    <div style={{marginTop: "20px"}}>
                                        <GmcSelect
                                            errors={this.state.errors}
                                            name={"documentType"}
                                            label={"Tipo de Documento"}
                                            options={this.state.documentTypes}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: "1px solid red"
                                                })
                                            }}
                                            value={this.state.documentType}
                                            onChange={this.handleDocumentTypeChange.bind(this)}/>
                                    </div>
                                </>}

                                <div style={{
                                    marginTop: "40px"
                                }}>
                                    <Upload
                                        errors={this.state.errors}
                                        name={"documentFront"}
                                        label={"Foto do documento"}
                                        tip={"(frente)"}
                                        btnLabel={"Anexar"}
                                        uploadFile={this.handleUploadDocumentFront}>
                                    </Upload>
                                </div>

                                <div style={{
                                    marginTop: "40px"
                                }}>
                                    <Upload
                                        errors={this.state.errors}
                                        name={"documentBack"}
                                        label={"Foto do documento"}
                                        tip={"(verso)"}
                                        btnLabel={"Anexar"}
                                        uploadFile={this.handleUploadDocumentBack}>
                                    </Upload>
                                </div>

                                <div style={{
                                    marginTop: "40px"
                                }}>
                                    <Upload
                                        errors={this.state.errors}
                                        name={"selfie"}
                                        label={"Selfie com o documento"}
                                        tip={"(verso)"}
                                        btnLabel={"Anexar"}
                                        uploadFile={this.handleUploadSelfie}>
                                    </Upload>
                                </div>

                                <div style={{
                                    marginTop: "40px"
                                }}>
                                    <div style={{fontSize: "12px", fontWeight: "bold", color: "#000066"}}>
                                        <Trans>Você é uma pessoa politicamente exposta?</Trans>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}>
                                        <button
                                            className={`btnDefaultOutlined ${this.state.isPoliticallyExposedPerson != null && this.state.isPoliticallyExposedPerson ? "btnDefaultOutlinedActive" : ""}`}
                                            style={{
                                                marginLeft: "0",
                                                marginTop: "5px",
                                            }}
                                            onClick={this.handleIsPoliticallyExposedPerson}><Trans>Sim</Trans>
                                        </button>
                                        <button
                                            className={`btnDefaultOutlined ${this.state.isPoliticallyExposedPerson != null && !this.state.isPoliticallyExposedPerson ? "btnDefaultOutlinedActive" : ""}`}
                                            style={{
                                                marginLeft: "10px",
                                                marginTop: "5px",
                                            }}
                                            onClick={this.handleIsNotPoliticallyExposedPerson}><Trans>Não</Trans>
                                        </button>
                                    </div>
                                    <GmcErrorMessage name={"isPoliticallyExposedPerson"}
                                                     errors={this.state.errors}/>
                                </div>


                                <div style={{
                                    marginTop: "40px"
                                }}>
                                    <div style={{fontSize: "12px", fontWeight: "bold", color: "#000066"}}>
                                        <Trans>Você é um cidadão dos Estados Unidos da América?</Trans>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}>
                                        <button
                                            className={`btnDefaultOutlined ${this.state.isAmericanCitizen != null && this.state.isAmericanCitizen ? "btnDefaultOutlinedActive" : ""}`}
                                            style={{
                                                marginLeft: "0",
                                                marginTop: "5px",
                                            }}
                                            onClick={this.handleIsAmericanCitizen}><Trans>Sim</Trans>
                                        </button>
                                        <button
                                            className={`btnDefaultOutlined ${this.state.isAmericanCitizen != null && !this.state.isAmericanCitizen ? "btnDefaultOutlinedActive" : ""}`}
                                            style={{
                                                marginLeft: "10px",
                                                marginTop: "5px",
                                            }}
                                            onClick={this.handleIsNotAmericanCitizen}><Trans>Não</Trans>
                                        </button>
                                    </div>
                                    <GmcErrorMessage name={"isAmericanCitizen"}
                                                     errors={this.state.errors}/>
                                </div>
                            </>}

                            {(this.state.partners.length > 0 && !this.state.addNew) && <>
                                <div style={{
                                    marginTop: "40px",
                                }}>
                                    {this.state.partners.map(partner => {
                                        return <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: "10px 5px",
                                        }}>
                                            <div style={{color: "#000066"}}>
                                                {partner.name}
                                            </div>
                                            <div>
                                                <button onClick={() => this.handleDeletePartner(partner)}>
                                                    <i className="fa fa-trash" aria-hidden="true"
                                                       style={{color: "#cc0057"}}></i>
                                                </button>
                                            </div>
                                        </div>
                                    })
                                    }
                                </div>
                            </>
                            }

                            {this.state.partners.length > 0 && <>
                                <div style={{
                                    display: "flex",
                                    marginTop: "20px",
                                    justifyContent: "end"
                                }}>
                                    {!this.state.addNew && <>
                                        <button className="btnLink"
                                                style={{backgroundColor: "#000066", color: "#FFF", fontSize: "12px"}}
                                                onClick={() => this.handleAddMore(true)}><Trans>Adicionar outro</Trans>
                                            <span style={{marginLeft: "5px"}}>
                                        <i className="fa fa-plus"></i>
                                    </span>
                                        </button>
                                    </>}

                                    {(this.state.addNew) && <>
                                        <button className="btnLink"
                                                style={{fontSize: "12px"}}
                                                onClick={() => this.handleAddMore(false)}><Trans>Cancelar</Trans>
                                        </button>
                                        <button className="btnLink"
                                                disabled={this.state.isLoading}
                                                style={{
                                                    fontSize: "14px",
                                                    marginLeft: "5px",
                                                    backgroundColor: this.state.isLoading ? 'rgba(0,0,102,0.42)' : '#000066',
                                                    color: '#FFF'
                                                }}
                                                onClick={() => this.handleGoNextStep()}>
                                            <Trans>Salvar</Trans>
                                            {this.state.isLoading ? <span style={{marginLeft: '5px'}}>
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                    </span> : ''}
                                        </button>
                                    </>}

                                </div>
                            </>}


                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "40px",
                                paddingTop: "20px"
                            }}>

                                {this.state.partners.length > 0 && !this.state.addNew && <>

                                    <button className="btnLink"
                                            onClick={this.handleCompleteSignup}
                                            disabled={this.state.isLoading}
                                            style={{
                                                backgroundColor: this.state.isLoading ? 'rgba(0,0,102,0.42)' : '',
                                                color: this.state.isLoading ? '#FFF' : ''
                                            }}
                                    ><Trans>Concluir cadastro</Trans>
                                        {this.state.isLoading ? <span style={{marginLeft: '5px'}}>
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                    </span> : ''}
                                    </button>

                                </>}

                                {this.state.partners.length < 1 && !this.state.addNew && <>
                                    <button className="btnLink"
                                            onClick={this.handleGoNextStep}
                                            disabled={this.state.isLoading}
                                            style={{
                                                backgroundColor: this.state.isLoading ? 'rgba(0,0,102,0.42)' : '',
                                                color: this.state.isLoading ? '#FFF' : ''
                                            }}
                                    ><Trans>Prosseguir</Trans>
                                        {this.state.isLoading ? <span style={{marginLeft: '5px'}}>
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                    </span> : ''}
                                    </button>
                                </>}
                            </div>
                        </div>

                    </div>
                </SignupContainer>
            </>
        );

    }
}


export default withTranslation()(SignupCompanyPartners);

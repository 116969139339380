import React from 'react';
import WithdrawGMCIcon from '../../assets/icone_receber_resgate.png';
import './styles.css';

export default function WithdrawGMC ()  {
    return (
        <>
            <div className="WithdrawGMCContainer">
            <div className="WithdrawGMCLeft">
                <h2>
                    5. Transfira para <br/>
                    quem você quiser, <br/>
                    em qualquer lugar <br/>
                    do mundo
                </h2>
                <p>
                    Com seus GMCs você pode transferir para qualquer <br/>
                    pessoa em qualquer lugar do mundo, de forma simples, <br/>
                    rápida e segura. Basta ir na opção Transferir no Painel de <br/>
                    Usuário e digitar as informações do destinatário, ele <br/>
                    recebe crédito em até 1 dia útil.

                </p>
            </div>
            <div className="WithdrawGMCRight">
                <img src={WithdrawGMCIcon} alt="Resgate"/>
            </div>
        </div>
        </>
    )
    
}
import React from "react";
import Select from "react-select";
import i18next from "i18next";

export default function GmcSelect(props) {

    const error = props.errors?.find((e) => e.key === props.name)
    const errorMessage = <div style={{fontSize: '12px', marginTop: "5px", color: "red"}}>
        {(error?.message || '').replace(/error.key/, props.label)}
    </div>

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
        }}>
            <label htmlFor={props.name} className="gmc-label">{i18next.t(props.label)}</label>
            <Select options={props.options}
                    placeholder={i18next.t(props.placeholder)}
                    isDisabled={props.isDisabled}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            marginTop: "5px",
                            border: error != null ? "1px solid red" : null
                        })
                    }}
                    value={props.value}
                    onChange={props.onChange}/>
            {error ? errorMessage : null}
        </div>
    )
}
import React from "react";
import logo from "../../assets/logo-rosa.png";
import Footer from "../Footer";
import ReactFlagsSelect from "react-flags-select";
import i18n from "../../i18n";
import './SignupContainer.css'

export default class SignupContainer extends React.Component {

    state = {
        selected: 'US'
    };

    constructor(props) {
        super(props);

        const currentLng = this.getCurrentLanguage();

        switch (currentLng) {
            case "zh" || "zh-CN":
                this.state.selected = 'CN'
                break;
            case "pt" || "pt-BR":
                this.state.selected = 'BR'
                break;
            case "en" || "en-GB" || "en-US":
                this.state.selected = 'US'
                break;
            default:
                this.state.selected = "BR";
                break;
        }
    }

    getCurrentLanguage = () => window.localStorage.getItem('i18nextLng') || 'pt-BR'

    changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        window.location.reload(false);
    }

    setSelected = (selected) => {

        this.setState({
            selected: selected
        })

        switch (selected) {
            case "US":
                this.changeLanguage("en");
                break;
            case "BR":
                this.changeLanguage("pt-BR");
                break;
            case "CN":
                this.changeLanguage("zh-CN");
                break;
            default:
                this.changeLanguage("pt-BR");
                break;
        }
    }


    render() {
        return (
            <>
                <div style={{
                    display: "flex",
                    minHeight: "100vh",
                    flexDirection: "column",
                    padding: "50px 25px"
                }}>
                    <img src={logo} className="leftMenu" alt="gomoney.me" style={{alignSelf: 'center'}}/>

                    <ReactFlagsSelect
                        className={"btnLanguageSelectionContainer"}
                        fullWidth={false}
                        optionsSize={16}
                        showOptionLabel={false}
                        selected={this.state.selected}
                        selectedSize={16}
                        searchable={false}
                        showSelectedLabel={true}
                        countries={["US", "BR", "CN"]}
                        customLabels={{"US": "EN", "BR": "pt-BR", "CN": "CN"}}
                        onSelect={(code) => this.setSelected(code)}
                    />

                    {this.props.children}

                </div>
                <Footer/>
            </>
        )
    }
}
import React from 'react';
import './styles.css';

export default function UnitePeople () {
    return (
        <>
        <div className="UnitePeopleContainer">
            <div className="UnitePeopleContent">
                <h1>
                    Unir pessoas, <br/>
                    conectar negócios
                </h1>
                <p>
                    A GoMoney surgiu do desejo de dois amigos em conectar pessoas e <br/> negócios financeiramente, de maneira mais simples, direta e com <br/> custos menores do que era feito até então.
                </p>
            </div>
        </div>
        </>
    )
}

import React from 'react';
import insertFundsIcon from '../../assets/icone_enviar_insirafundos.png';
import './styles.css';

export default function SendYourData(){
    return(
        <>
            <div className="SendYourDataContainer">
                <div className="SendYourDataLeft">
                        
                    <img src={insertFundsIcon} alt="Insira fundos"/>
                </div>
                <div className="SendYourDataRight">
                    <h2>
                        2. Envie os dados<br/>
                        da sua conta para <br/>
                        o remetente
                    </h2>
                    <p>
                    É só compartilhar os dados de sua conta GoMoney com o <br/>
                    remetente e pedir para que ele realize a transferência, de <br/>
                    qualquer lugar do mundo, você recebe o seu crédito em  <br/>
                    GMCs em 1 dia útil. Caso o remetente não tenha uma  <br/>
                    conta GoMoney é só enviar um convite através do Painel <br/> 
                    de Usuário, é fácil e só leva alguns minutinhos.

                    </p>
                </div>
            
            </div>
        </>
    )
}
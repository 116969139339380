import React, {Component} from "react";
import {Trans} from "react-i18next";

export default class Upload extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.handleFileUpload = this.handleFileUpload.bind(this)
        this.handleEditFile = this.handleEditFile.bind(this)
        this.state = {
            file: {},
            isUploading: false,
            isDone: false
        }

    }

    handleFileUpload = async (event) => {
        if (event.target.files.length > 0) {

            const file = event.target.files[0];
            this.setState({
                file: file,
                isUploading: true
            })
            const uploadResponse = await this.props.uploadFile(file);
            this.setState({
                isDone: true,
                isUploading: false
            })
        }
    }

    handleEditFile = () => {
        this.setState({
            file: null,
            isDone: false,
            isUploading: false
        })
    }

    render() {

        const error = this.props.errors?.find((e) => e.key === this.props.name)
        const errorMessage = <div style={{fontSize: '12px', marginTop: "5px", color: "red"}}>
            {(error?.message || '').replace(/error.key/, this.props.label)}
        </div>

        return (
            <>
                <div>
                    <div style={{fontStyle: "14px", fontWeight: "bold", color: "#000066", ...this.props.style}}>
                        <Trans>
                            {this.props.label}
                        </Trans>
                        {this.props.tip != null ?
                            <span style={{fontSize: "12px", fontWeight: "300", marginLeft: "5px"}}>
                                <Trans>
                                    {this.props.tip}
                                </Trans>
                            </span> : null}
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                        {
                            !this.state.isDone && !this.state.isUploading &&
                            <div style={{
                                display: "flex",
                                alignItems: 'start',
                                flexDirection: "column"
                            }}>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <input
                                        ref={this.inputRef}
                                        type="file"
                                        style={{display: "none"}}
                                        onChange={this.handleFileUpload}
                                        accept=".pdf,.jpg,.png,.jpeg"
                                    />
                                    <button className="btnDefault"
                                            style={{
                                                marginLeft: "0",
                                                marginTop: "5px",
                                            }}
                                            onClick={() => this.inputRef.current.click()}>
                                        <Trans>
                                            {this.props.btnLabel}
                                        </Trans>
                                    </button>
                                    <div style={{
                                        fontSize: "10px",
                                        marginLeft: "10px"
                                    }}>
                                        <Trans>
                                            JPG, PNG ou PDF.
                                        </Trans>
                                    </div>
                                </div>
                                {error ? errorMessage : null}
                            </div>

                        }
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: "10px",
                            alignItems: 'center'
                        }}>
                            {
                                this.state.isUploading &&
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <i className="fas fa-2x fa-circle-notch fa-spin"></i>
                                    <span style={{marginLeft: "10px"}}>
                                <Trans>
                                    Enviando, aguarde um instante.
                                </Trans>
                            </span>
                                </div>
                            }

                            {
                                this.state.isDone &&
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <i className="fa fa-2x fa-check-circle" aria-hidden="true"></i>
                                    <span style={{marginLeft: "10px"}}>
                                        <Trans>Arquivo enviado!</Trans>
                            </span>
                                </div>
                            }
                            {
                                this.state.isDone &&
                                <div>
                                    <button onClick={this.handleEditFile.bind(this)}
                                            style={{fontSize: "12px", marginLeft: "10px", textDecoration: "underline"}}>
                            <span style={{paddingRight: "5px"}}>
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                            </span>
                                        <Trans>Alterar</Trans>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
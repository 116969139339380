import axios from 'axios';

export const httpClient = () => {
    return axios.create({
        // baseURL: 'http://api-gomoney.dowhile.com.br/v1/',
        baseURL: 'https://gomoney.me/v2',
        // baseURL: 'https://homolog.gomoney.me/v2',
        // baseURL: 'http://localhost:3002/v2/',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
}
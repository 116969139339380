import React from 'react';
import {Link} from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import './styles.css';
import {Trans, withTranslation} from "react-i18next";

function AccordeonFaq(){
    return (
        <>
            <Accordion allowMultipleExpanded='true' allowZeroExpanded='true'>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <Trans>O que é a GoMoney?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <Trans>resposta1</Trans>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <Trans>O que é o GoMoney Coin (GMC)?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <Trans>resposta2</Trans>
                        </p>       
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <Trans>O GoMoney Coin tem lastro?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <Trans>resposta3</Trans>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                         <Trans>Preciso ter conta em exchange para usar a GoMoney Coin?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <p>
                        <Trans>resposta4</Trans>
<br/>
</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <Trans>Como faço para realizar uma transferência com a GoMoney?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <Trans>resposta5_1</Trans> <Link to={`/signup`}><Trans>ABRA SUA CONTA!</Trans></Link><br/><br/>
                            <Trans>resposta5_2</Trans>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <Trans>Posso resgatar os GMCs em uma conta bancária de minha titularidade em outro país?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p> 
                            <Trans>resposta6</Trans>
                        </p>

                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <Trans>Quais países posso enviar dinheiro com a GoMoney?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <Trans>resposta7</Trans>
                        </p>

                        
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <Trans>Quais as moedas em que posso solicitar resgate?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <Trans>A GoMoney realiza pagamentos em: <br/><br/></Trans>
                        <ul class="fa-ul">
                            <li><span class="fa-li"><i class="fas fa-check"></i></span><Trans>Reais (BRL)</Trans></li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span><Trans>Dólar Americano (USD)</Trans></li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span><Trans>Euro (EUR)</Trans></li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span><Trans>Libra Esterlina (GBP)</Trans></li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>Franco Suíço (CHF)</li>
                        </ul>
                        <br/><br/>
                         <Trans>resposta8</Trans>
                        </p>

                        
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <Trans>Quais são as taxas cobradas pela GoMoney?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        <Trans>Taxa de Emissão de GMC: R$ 0,20 a cada GMC emitido</Trans><br/><br/>
                        <Trans>Emissão de Boletos: R$ 5,00 por boleto pago.</Trans><br/><br/>
                        <Trans>Resgates no Brasil: R$ 5,00 por resgate.</Trans><br/><br/>
                        <Trans>Resgates no Exterior: USD 10,00 por resgate.</Trans><br/><br/>
                        </p>

                        
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <Trans>Qual o prazo para uma remessa internacional?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <Trans>resposta9</Trans>
                        </p>

                        
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                         <Trans>Qual o prazo para o resgate no Brasil?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <Trans>Em até 1 dia útil o recurso será enviado para sua conta bancária no Brasil.</Trans>
                        </p>

                        
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <Trans>Como se proteger da volatilidade do Dólar?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <Trans>resposta10</Trans>
                        </p>                        
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <Trans>A GoMoney oferece rentabilidade?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <Trans>resposta11</Trans>
                        </p>                        
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <Trans>Como receber pagamentos de clientes ou empresas do Brasil?</Trans>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <Trans>resposta12_1</Trans><br/><br/> 
                            <Trans>resposta12_2</Trans><br/><br/>
                            <Trans>resposta12_3</Trans>
                        </p>                        
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        </>
    )
}

export default withTranslation() (AccordeonFaq)
import React from 'react';
import Topbar from '../Topbar';
import './styles.css';

export default function WeHelpYou(){
    return (
        <>  
            <div className="containerWeHelpYou">  
                <Topbar topbar="contentHome"></Topbar>
                <div className="contentWeHelpYou">
                    <h1>Dúvidas? Nós te ajudamos!</h1>
                    {/* <input placeholder="Digite sua dúvida ou uma palavra-chave"/> */}
                </div>
            </div>
            
        </>
    )
}
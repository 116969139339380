import React, {Component} from 'react';
import TopBar from '../components/Topbar';
import Footer from '../components/Footer';
import UnitePeople from '../components/UnitePeople';
import FinancialArea from '../components/FinancialArea';
import YouCan from '../components/YouCan';
import Together from '../components/Together';
import StartNow from '../components/StartNow';
import SeparatorHr from '../components/SeparatorHr';



class About extends Component{

    render () {

        return (
            <>
            <TopBar topbar='contentSendRecieve' />
            <UnitePeople/>
            <FinancialArea/>
            <YouCan/>
            <Together/>
            <StartNow />
            <SeparatorHr />
            <Footer/>


            </>
        );
          
    }
}


export default About;
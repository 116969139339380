import React from 'react';
import './styles.css';
import AskedQuestionsAccordeon from '../AccordeonFaq';
import {Trans, withTranslation} from "react-i18next";

function AskedQuestions(){

    return (
        <section id='perguntas'>
        <div className="AskedQuestionsContainer">
            <div className="AskedQuestionsLeft">
                <h1>
                    <Trans>Perguntas frequentes</Trans>
                </h1>
                <p>
                    <Trans>subFaq</Trans>
                </p>
            </div>
            <div className="AskedQuestionsRight">
                <AskedQuestionsAccordeon />
                {/* <Link to='/faq'><button className="buttonAskedQuestions">Visite nosso FAQ</button></Link> */}
            </div>
        </div>
        </section>
    )
}
export default withTranslation()(AskedQuestions)
import React from 'react';
import startIcon from '../../assets/icone_enviar_cadastre.png';
import './styles.css';

export default function ReceiveIsEasy(){
    return(
        <>
        <div className="ReceiveIsEasyContainer">
            <div className="ReceiveIsEasyLeft">
                <h1>
                    Para começar <br/>
                    a Receber é fácil
                </h1>
                <h2>
                    1. Cadastre-se
                </h2>
                <p>
                    Faça seu cadastro gratuitamente em nosso site ou no <br/>
                    app. É só clicar em Cadastro e seguir os passos, é fácil e <br/>
                    leva poucos minutos!
                </p>
            </div>
            <div className="ReceiveIsEasyRight">
                <img src={startIcon} alt="Começar é fácil"/>
            </div>
        </div>
        </>
    )
}
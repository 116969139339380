import React from 'react';
import reasonsIcon from '../../assets/icone_motivos.png';
import './styles.css';
import {Trans, withTranslation} from "react-i18next";

function Section5(){

    return(
        <div className="internationTransferContainer">
                        
            <div className="internationTransferLeft">
                <img src={reasonsIcon} className="section5Img" alt="Motivos"/>
            </div>
            <div className="internationTransferRight">
               
                <h1>
                    <Trans>Receber de seus clientes e pagar seus fornecedores em qualquer lugar do planeta</Trans> <br/>                    
                </h1>
                <p>
                <Trans>Limites inteligentes que atenderão as necessidades da sua empresa, sem<br/>burocracia</Trans>
                </p>
               
            </div>
        </div>
    )
}

export default withTranslation()(Section5)
import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

export default function PrivacityPolicy() {
    return (
        <div className='policyContainer' id='politica1'>
            <h1>Política de Privacidade</h1>
            <h4>Última atualização: 14 de dezembro de 2023</h4>
            <p>
                Esta Política de Privacidade, juntamente com os Termos e Condições de Uso (“Termos
                de Uso”), descrevem os termos e as condições aplicáveis ao acesso e uso do Aplicativo
                e da Plataforma [<a href="https://gmc.gomoney.me" target="_blank" rel="noopener noreferrer"> https://gmc.gomoney.me</a>] (“Plataforma GoMoney”) disponibilizada
                pela GOMONEY PAGAMENTOS, EMISSÃO, INTERMEDIAÇÃO E CUSTÓDIA LTDA., sociedade
                empresária limitada, constituída de acordo com as leis da República Federativa do Brasil, com sede
                na Cidade de São Paulo, Estado de São Paulo, Avenida Queiroz Filho, 1560, Sala 15, Torre Sabiá, CEP
                05319-000, inscrita no Cadastro Nacional de Pessoas Jurídicas (“CNPJ/MF”) sob o no
                30.359.852/0001-30 (“GoMoney ou “Parte”), a seus Usuários (denominados
                juntamente com a GoMoney como (“Partes”).
            </p>
            <p>
                AO CLICAR EM “REGISTRAR” VOCÊ CONCORDA COM ESTES TERMOS DE USO. AS
                DISPOSIÇÕES AQUI PRESENTES REGULAMENTARÃO A RELAÇÃO ENTRE A GOMONEY E O
                USUÁRIO. CASO VOCÊ NÃO CONCORDE COM TODOS OS TERMOS QUE SEGUEM (OU
                NÃO POSSA CUMPRIR COM ALGUM DE TAIS TERMOS POR QUALQUER MOTIVO),
                INFELIZMENTE NÃO PODERÁ UTILIZAR OU ACESSAR A PLATAFORMA. LEIA-OS COM
                ATENÇÃO!
            </p>
            <p>
                A menção aos termos “GoMoney”, “nós” ou “nossos” referem-se à GoMoney; bem
                como os termos “você”, “usuário”, “seu”, “sua”, referem-se a você Usuário, que está
                consentindo com esta Política de Privacidade e com os Termos de Uso para fazer uso e
                ter acesso à Plataforma GoMoney.
            </p>
            <p>
                A GOMONEY PODERÁ MODIFICAR ESTA POLÍTICA DE PRIVACIDADE, A QUALQUER
                MOMENTO, MEDIANTE A PUBLICAÇÃO DA POLÍTICA DE PRIVACIDADE ATUALIZADA.
                Para a sua comodidade, a data da última versão é incluída no topo desta página. Toda
                vez que você acessar a Plataforma GoMoney e novos Termos de Uso e Política de
                Privacidade tiverem sido divulgados, você será solicitado a ler os novos Termos de Uso
                e Política de Privacidade e confirmar que concorda com as novas condições aplicáveis.
            </p>
            <p>
                Eventuais alterações nestes Termos de Uso e Política de Privacidade entrarão em vigor
                assim que publicados na Plataforma GoMoney. Se tal versão incluir alguma alteração
                substancial nós o informaremos com no mínimo 30 (trinta) dias de antecedência por email
                ou postando um aviso na página <Link to='/'> http://gomoney.me</Link>.
            </p>
            <p>
                Caso tenha alguma objeção a quaisquer itens, diretrizes ou alterações posteriores desta Política de Privacidade, ou, ainda, caso fique insatisfeito com os serviços oferecidos pela GoMoney, por qualquer motivo que seja, o Usuário poderá cessar o uso de tais serviços
                sem qualquer ônus, observado o disposto nos Termos de Uso.
            </p>
            <p>
                A Administradora do Aplicativo, Site e Serviços reserva-se no direito de verificar,
                notificar e encaminhar às autoridades públicas qualquer notícia de violação da lei, pela
                constatação da prática de publicações de USUÁRIO(A)(S) que tipifiquem delitos e/ou
                ilícitos de qualquer espécie, sejam legais, sejam contratuais, sem que isto implique em
                violação de privacidade ou constitua violação de direitos do(a)(s) USUÁRIO(A)(S) ou
                lhe(s) assegura indenizações de qualquer espécie, mesmo moral ou à imagem.
            </p>
            <p>
                O(A)(S) USUÁRIO(A)(S) é(são) inteiramente responsável(eis) pela guarda e manutenção
                de seu(ua)(s) nome(s) de USUÁRIO(A)(S) e senhas de acesso, escolhidos quando de seu
                credenciamento de acesso, bem como por certificar(em)-se de que efetuaram o log out
                de suas contas no Aplicativo "GOMONEY" após uso. O(A)(S) USUÁRIO(A)(S) concordam
                em IMEDIATAMENTE notificar à Administradora do Aplicativo, Site e Serviços por
                qualquer violação ou revelação indesejada destes.
            </p>
            <p>
                O(A)(S) USUÁRIO(A)(S) garante(m) que os seus dados pessoais fornecidos ao Aplicativo
                "GOMONEY" são verdadeiros e corretos e não incluem dados de terceiros, sendo o(a)(s)
                USUÁRIO(A)(S) o(a)(s) único(a)(s) responsável(eis) por comunicar qualquer alteração
                que houver em seus dados pessoais.
            </p>
            <p>
                O(A)(S) USUÁRIO(A)(S) concordam que as informações poderão ser acessadas por um
                número limitado de colaboradores do Aplicativo "GOMONEY", por certas empresas
                colaboradoras para o funcionamento do Aplicativo "GOMONEY" com as quais a
                Administradora do Aplicativo, Site e Serviços possa vir a conduzir parcerias comerciais.
                Os funcionários da Administradora do Aplicativo, Site e Serviços recebem treinamento
                sobre a importância da privacidade e sobre como lidar com os dados d o(a)(s)
                USUÁRIO(A)(S) e gerenciá-los de forma apropriada e segura.
            </p>
            <p>
                O(A)(S) USUÁRIO(A)(S) reconhece(m) e concorda(m) que, ao enviar o Conteúdo do
                Usuário e/ou encaminhar informações pessoais que o identificam ou identificam
                terceiros ao Aplicativo "GOMONEY", embora este último tenha recursos instalados para
                evitar o acesso não autorizado ou a interceptação das suas informações e/ou do
                Conteúdo do Usuário, não existe garantia absoluta de segurança.
            </p>
            <p>
                O(A)(S) USUÁRIO(A)(S) reconhece(m) e concorda(m) que o Aplicativo "GOMONEY", em
                cumprimento à Lei 12.965/2014 (Marco Civil da Internet no Brasil), mantém registros de
                acesso ao Aplicativo "GOMONEY" pelo prazo de 6 (seis) meses.
            </p>
            <p>
                O(A)(S) USUÁRIO(A)(S) devem ser responsáveis com a guarda de seus nomes de usuário
                e suas senhas de utilização do Aplicativo "GOMONEY", evitando sua revelação
                autorizada ou não autorizada a terceiros, comprometendo-se a notificar
                IMEDIATAMENTE à Administradora do Aplicativo, Site e Serviços, na hipótese de suas
                violações, voluntárias ou involuntárias.
            </p>
            <p>
                A Administradora do Aplicativo, Site e Serviços entende a importância da privacidade
                para os Usuários. O uso que o Aplicativo "GOMONEY" faz das informações pessoais que identificam o(a)(s) USUÁRIO(A)(S) e das comunicações privadas ocorridas no Aplicativo
                "GOMONEY" está de acordo com a legislação aplicável. Para fins de esclarecimento, as
                medidas de segurança e sigilo do Aplicativo "GOMONEY" envolvem, dentre outros,
                serviços de firewalls e autenticação e validação dos usuários envolvidos no tratamento
                dos dados e comunicações dos Usuários
            </p>
            <p>
                Para se cadastrar no Aplicativo "GOMONEY" e usar os seus serviços, poderá ser
                requerido que o(a)(s) USUÁRIO(A)(S) forneça(m) informações pessoais, incluindo, mas
                não se limitando a, seu nome, endereço, e-mail, gênero, data de nascimento, número
                de telefone, informações de contato, idade e outros dados que permitam a sua
                identificação. Adicionalmente, o Aplicativo "GOMONEY" poderá recolher informações
                demográficas de caráter não identificável. O Aplicativo "GOMONEY" poderá ainda
                recolher informações simultaneamente ou associar os dois tipos de informação
                mencionados. O Aplicativo "GOMONEY" poderá obter informações sobre as seções
                acessadas dentro do aplicativo e sua forma de uso. Além disso, informações sobre o
                hardware de acesso e software instalados no dispositivo de acesso à internet poderão
                ser recolhidas pelo Aplicativo "GOMONEY". Tais informações poderão incluir o endereço
                de Protocolo de Internet (“IP”), tipo de navegador, sistema operacional, informações
                inseridas em formulários e tempos de acesso. Exceto se de outra forma previsto nestes
                Termos, o Aplicativo "GOMONEY" não terá acesso, nem tentará acessar, os dados
                pessoais, exceto aqueles que o o(a)(s) USUÁRIO(A)(S), livre e voluntariamente,
                fornecer(em) através de uso do Aplicativo.
            </p>
            <p>
                O Aplicativo "GOMONEY" exige dos seus parceiros que mantenham confidenciais as suas
                informações pessoais e não as utilizem para finalidades não autorizadas. Além disso,
                o(a)(s) USUÁRIO(A)(S) reconhece(m) e concorda(m) que as suas informações poderão
                ser compartilhadas com terceiros na medida necessária para permitir à Administradora
                do Aplicativo, Site e Serviços realizar uma fusão, aquisição ou venda de todos ou de parte dos ativos da Administradora do Aplicativo, Site e Serviços.
            </p>
            <p>
                A Administradora do Aplicativo, Site e Serviços usa os dados obtidos pelo Aplicativo
                "GOMONEY", sejam de caráter pessoal, demográfico, coletivo ou técnico, para
                administrar e gerir o Aplicativo e outros aplicativos e serviços oferecidos pela
                Administradora do Aplicativo, Site e Serviços, personalizar o Aplicativo "GOMONEY" e
                as suas informações, direcionar campanhas publicitárias, responder perguntas que
                o(a)(s) USUÁRIO(A)(S) fizer(em) sobre nossos serviços, evitar atividades potencialmente
                proibidas ou ilegais, para disponibilizar, ofertar, operar e melhorar o Aplicativo
                "GOMONEY" e demais aplicativos e serviços oferecidos pela Administradora do
                Aplicativo, Site e Serviços, assim como para entrar em contato com o(a)(s)
                USUÁRIO(A)(S).
            </p>
            <p>
                O Aplicativo "GOMONEY" poderá conter imagens conhecidas como web beacons
                (conhecidos como “single-pixel gifs“) que permitem ao Aplicativo "GOMONEY" contar o
                número de seus usuários. Os web beacons não são usados para acessar suas
                informações pessoais, mas são uma técnica utilizada para contabilizar estatísticas coletivas sobre a utilização do Aplicativo. Web beacons coletam somente um conjunto
                limitado de dados incluindo um número de cookies, hora e data da visita a um site e uma
                descrição do local onde o sinalizador da rede (web) reside. Para fins de
                referência, cookies são pacotes de dados ligados a informações de caráter não pessoal
                sobre o usuário, armazenados temporariamente em seu dispositivo. Web beacons, por
                suas características técnicas não podem ser desativados ou rejeitados. Quanto
                aos cookies, estes poderão ser desativados ou poderão ter a configuração alterada em
                seu dispositivo. O(A)(S) USUÁRIO(A)(S) deve entrar em contato com o fornecedor de seu
                dispositivo caso queira alterar as configurações relacionadas aos cookies. O Aplicativo
                "GOMONEY" utiliza cookies quando o(a)(s) USUÁRIO(A)(S) acessa(m) o Aplicativo.
                Nossos cookies se associam unicamente com um usuário e seu respectivo dispositivo.
                Graças aos cookies, o Aplicativo "GOMONEY" pode reconhecer o(a)(s) USUÁRIO(A)(S)
                depois de ter acessado o Aplicativo, permitindo que o(a)(s) USUÁRIO(A)(S) tenha(m)
                uma experiência personalizada.
            </p>
            <p>
                O Aplicativo "GOMONEY" poderá fornecer os dados pessoais e/ou o Conteúdo do(a)(s)
                USUÁRIO(A)(S) sempre que permitido pela legislação aplicável e/ou solicitado por
                qualquer autoridade governamental ou determinado por decisão judicial.
            </p>
            <p>
                Cada Usuário do Aplicativo "GOMONEY" é responsável pela guarda e manutenção da
                confidencialidade de sua senha de acesso ao Aplicativo. Portanto, se, por qualquer
                motivo, a confidencialidade de sua senha estiver comprometida, imediatamente
                contate o Aplicativo "GOMONEY".
            </p>
            <p>
                Caso o(a)(s) USUÁRIO(A)(S) queira(m) ter acesso a e/ou queira(m) alterar e/ou queira(m) excluir seus dados pessoais armazenados pelo Aplicativo "GOMONEY", poderá(ão) fazêlo
                através de contato com a Administradora do Aplicativo, Site e Serviços.
            </p>
            <p>
                O(a)(s) USUÁRIO(A)(S) concorda(m) ser terminantemente proibido usar, exibir, espelhar
                ou reproduzir o Aplicativo "GOMONEY" ou qualquer elemento individual, dentro do mesmo, logotipo ou outras informações proprietárias, ou o layout e design de qualquer página ou formulário contido em uma página do Aplicativo "GOMONEY", sem o consentimento expresso e por escrito da Administradora do Aplicativo, Site e Serviços;
            </p>
            <p>
                A Administradora do Aplicativo, Site e Serviços tem o direito de investigar e quaisquer das violações supra mencionadas em toda a extensão da lei.
            </p>
            <p>
                A Administradora do Aplicativo, Site e Serviços pode acessar, guardar e divulgar
                qualquer informação sua, caso sejamos compelidos por lei a fazê-lo, ou caso acredite,
                de boa-fé, que isso seja razoavelmente necessário para (i) responder a reivindicações
                realizadas contra o Aplicativo "GOMONEY" ou cumprir com procedimentos judiciais (por
                exemplo, citações ou mandados judiciais); (ii) tornar exequíveis ou administrar os
                contratos com usuários, tais como esses 'TERMOS E CONDIÇÕES DE USO E SERVIÇO DO
                APLICATIVO "GOMONEY"'; (iii) prevenção de fraudes, avaliação de riscos, investigação,
                suporte aos clientes, desenvolvimento de produto e para fins de depuração, ou (iv) proteger os direitos, bens ou segurança do Aplicativo "GOMONEY", seus usuários, ou
                membros do público.
            </p>
            <p>
                O(A)(S) USUÁRIO(A)(S) reconhece(m) que a Administradora do Aplicativo, Site e Serviços
                não tem nenhuma obrigação de monitorar o acesso ou uso do Aplicativo "GOMONEY"
                ou revisar ou editar qualquer conteúdo de USUÁRIO(A)(S), mas tem o direito de fazê-lo
                para fins de operação e aprimoramento do Aplicativo e dos Serviços (incluindo, sem
                limitação, para fins de prevenção de fraude, avaliação de risco, investigação e
                atendimento ao cliente), para garantir a sua conformidade com estes 'TERMOS E
                CONDIÇÕES DE USO E SERVIÇO DO APLICATIVO "GOMONEY"', para cumprir com a lei
                aplicável ou à ordem ou exigência de um tribunal, órgão administrativo ou outro órgão
                governamental ou mesmo para questionar, defender-se ou recorrer contra tais
                exigências.
            </p>
            <p>
                A Política de Privacidade da Administradora do Aplicativo, Site e Serviços (conforme
                possa ser atualizada de tempos em tempos) rege a coleta e utilização de suas informações pessoais.
            </p>
            <p>
                Apreciamos e o encorajamos a fornecer feedback, comentários e sugestões de
                melhorias para o Aplicativo "GOMONEY" ("Comentários"). O(A)(S) USUÁRIO(A)(S)
                reconhece(m) e concorda(m) que todos os comentários que fornecer serão de
                propriedade única e exclusiva do Aplicativo "GOMONEY". O(A)(S) USUÁRIO(A)(S)
                irrevogavelmente concorda(m) em ceder à Administradora do Aplicativo, Site e Serviços
                todo o seu direito, título e interesse sobre todos os Comentários e dispensa quaisquer
                direitos morais que o(a)(s) USUÁRIO(A)(S) possa(m) ter em tais comentários, na medida
                permitida por lei.
            </p>
        </div>
    )
}
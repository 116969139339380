import React from 'react';
import fundsIcon from '../../assets/icone_receber_saldo.png';
import './styles.css';

export default function YourFundsInGMC(){
    return(
        <>
            <div className="YourFundsInGMCContainer">
                <div className="YourFundsInGMCLeft">
                        
                    <img src={fundsIcon} alt="Seu saldo"/>
                </div>
                <div className="YourFundsInGMCRight">
                    <h2>
                        4. Seu saldo em GMC
                    </h2>
                    <p>
                        Com seu saldo em GMCs em conta você pode escolher o <br/>
                        melhor momento para converter em moeda ou utilizá-los <br/>
                        para realizar novas transferências, lembrando que 1 GMC <br/>
                        sempre vale 1 Dólar Americano.

                    </p>
                </div>
            
            </div>
        </>
    )
}
import React from 'react';
import './styles.css';

export default function TermsOfUse() {
  return (
    <div className='termsContainer' id='terms'>
      <h1>Termos de uso</h1>
      <h4>Última atualização: 14 de dezembro de 2023</h4>
      <p>
        Estes Termos e Condições de Uso (“Termos de Uso”), juntamente com a Política de
        Privacidade [<a href="https://gomoney.me/politica-de-privacidade" target="_blank" rel="noopener noreferrer"> https://gomoney.me/politica-de-privacidade</a>], descrevem os termos e as condições
        aplicáveis ao acesso e uso do APP e da plataforma [<a href="https://gmc.gomoney.me" target="_blank" rel="noopener noreferrer"> https://gmc.gomoney.me</a>] (“Plataforma
        GoMoney”) disponibilizada pela GOMONEY PAGAMENTOS, EMISSÃO, INTERMEDIAÇÃO E
        CUSTÓDIA LTDA., sociedade empresária limitada, constituída de acordo com as leis da República
        Federativa do Brasil, com sede na Cidade de São Paulo, Estado de São Paulo, Avenida Queiroz Filho,
        1560, Sala 15, Torre Sabiá, CEP 05319-000, inscrita no Cadastro Nacional de Pessoas Jurídicas
        (“CNPJ/MF”) sob o no 30.359.852/0001-30 (“GoMoney ou “Parte”), a seus Usuários (denominados
        juntamente com a GoMoney como (“Partes”).
      </p>
      <p>
        AO CLICAR EM “aceitar termos e condições de uso”, VOCÊ CONCORDA COM ESTES TERMOS
        DE USO. AS DISPOSIÇÕES AQUI PRESENTES REGULAMENTARÃO A RELAÇÃO ENTRE A GOMONEY E O
        USUÁRIO. CASO VOCÊ NÃO CONCORDE COM TODOS OS TERMOS QUE SEGUEM (OU NÃO POSSA
        CUMPRIR COM ALGUM DE TAIS TERMOS POR QUALQUER MOTIVO), INFELIZMENTE NÃO PODERÁ
        UTILIZAR OU ACESSAR A PLATAFORMA. POR ISSO, LEIA-OS COM ATENÇÃO!
      </p>
      <p>
        A menção aos termos “GoMoney”, “nós” ou “nossos” referem-se à GoMoney; bem como os
        termos “você”, “usuário”, “seu”, “sua”, referem-se a você Usuário, que está consentindo com estes
        Termos de Uso, com a Política de Privacidade e com as tarifas de serviços para fazer uso e ter acesso
        à Plataforma GoMoney.
      </p>
      <p>
        A GOMONEY PODERÁ MODIFICAR ESTES TERMOS DE USO, A QUALQUER MOMENTO,
        MEDIANTE A PUBLICAÇÃO DE TERMOS DE USO ATUALIZADOS. Para a sua comodidade, a data da
        última versão é incluída no topo desta página. Toda vez que você acessar a Plataforma GoMoney e
        novos Termos de Uso tiverem sido divulgados, você será solicitado a ler os novos Termos de Uso e
        dar o aceite, confirmando que concorda com as novas condições aplicáveis.
      </p>
      <p>
        Os Termos de Uso e suas possíveis atualizações entrarão em vigor assim que publicados na
        Plataforma GoMoney.
      </p>
      <p>
        Caso tenha alguma objeção a quaisquer itens, diretrizes ou alterações posteriores destes
        Termos de Uso, ou, ainda, caso fique insatisfeito com os serviços oferecidos pela GoMoney, por
        qualquer motivo que seja, o Usuário deverá cessar o uso de tais serviços sem qualquer ônus,
        observado o disposto nestes Termos de Uso.
      </p>
      <h1>1. DEFINIÇÕES</h1>
      <p>
        1.1. Para facilitar seu entendimento e evitar repetições, os seguintes termos usados nestes Termos
        de Uso com a primeira letra em maiúsculo terão o significado estabelecido abaixo, seja no plural ou
        no singular, sem prejuízo de outros termos definidos ao longo destes Termos de Uso:
      </p>
      <div className="grid">
        <p className="grid-main-text">“Alteração Substancial”</p>
        <p>
          Significa uma mudança nesses Termos de Uso que reduz seus
          direitos ou aumenta suas obrigações.
        </p>
        <p className="grid-main-text">“Conta Bancária GoMoney”</p>
        <p>
          Conta bancária detida e indicada pela GoMoney para o envio de
          recursos pelo Usuário via transferência bancária (DOC ou TED),
          depósito ou ordem de pagamento internacional para a realização de
          Ordem de Compra. Os dados da Conta Bancária GoMoney podem
          ser acessados na página [<a href="https://gmc.gomoney.me" target="_blank" rel="noopener noreferrer"> https://gmc.gomoney.me</a>].
        </p>
        <p className="grid-main-text">“Conta GoMoney”</p>
        <p>
          Conta de armazenamento de GMCs, de titularidade do Usuário,
          mantida em seu nome, que poderá ser acessada, consultada e
          transacionada através do uso de seu login e senha após a
          confirmação do Cadastro.
        </p>
        <p className="grid-main-text">“GMC”</p>
        <p>
          Criptomoeda digital emitida pela GoMoney passível de negociação
          no âmbito da Plataforma GoMoney.
        </p>
        <p className="grid-main-text">“Ordem de Compra”</p>
        <p>
          Aquisição de GMC por Usuáriosiniciada pela mera demonstração de
          interesse de compra de GMC no âmbito da Plataforma GoMoney e
          efetuada fora deste, mediante pagamento via transferência
          bancária (DOC ou TED), depósito na Conta Bancária GoMoney ou
          ordem de pagamento internacional. O pagamento se dá (i) em reais
          para Usuários localizados no Brasil; ou (ii) mediante a emissão de
          2. PLATAFORMA GOMONEY
          2.1. Para acessar a Plataforma GoMoney, a GoMoney poderá conceder ao Usuário, em caráter
          acessório, uma licença limitada, pessoal, não exclusiva, não transferível e plenamente revogável,
          para usar a Plataforma GoMoney em plataforma online, desde que em conformidade com os termos
          aqui contidos.
          2.2. O Usuário declara-se integralmente responsável pelas informações inseridas na Plataforma
          GoMoney, pelo cadastramento, permissões, senhas e modo de utilização. A GoMoney, em hipótese
          alguma, será responsável pelo conteúdo (informações, senhas, cópias de informações etc.) oferecido
          pelo Usuário, não sendo, portanto, tais informações revisadas nem divulgadas em momento algum.
          A responsabilidade pelas informações é sempre do Usuário.
          uma ordem de pagamento internacional em dólar americano
          utilizando os dados disponíveis no link [<a href="https://gmc.gomoney.me" target="_blank" rel="noopener noreferrer"> https://gmc.gomoney.me</a>].
        </p>
        <p className="grid-main-text">“Ordem de Resgate”</p>
        <p>
          Ordem para venda de GMC emitida pelo Usuário contra a GoMoney
          no âmbito da Plataforma GoMoney efetuada mediante a entrega,
          pela GoMoney, de recursos equivalentes ao montante em GMC
          constante da ordem de venda emitida pelo Usuário. A entrega de
          recursos se dá em moeda corrente nacional e/ou dólar dos Estados
          Unidos da América, conforme a localização do Usuário, sendo
          realizada via transferência para conta bancária detida pelo Usuário
          e informada no Cadastro no Brasil ou no exterior.
        </p>
        <p className="grid-main-text">“Serviços”</p>
        <p>
          Todos os serviços oferecidos pela GoMoney por meio da Plataforma GoMoney
        </p>
        <p className="grid-main-text">“Usuário” ou “Parte”</p>
        <p>
          A pessoa física ou jurídica, por intermédio de representante legal,
          que acessa a Plataforma GoMoney e realiza o seu cadastro pessoal,
          de acordo com o previsto nestes Termos de Uso, de modo a usufruir
          dos Serviços oferecidos pela GoMoney, aceitando, nessa
          oportunidade, incondicionalmente, os presentes Termos de Uso e a
          Política de Privacidade.
        </p>
      </div>
      <h1>2. PLATAFORMA GOMONEY</h1>
      <p>
        2.1. Para acessar a Plataforma GoMoney, a GoMoney poderá conceder ao Usuário, em caráter
        acessório, uma licença limitada, pessoal, não exclusiva, não transferível e plenamente revogável,
        para usar a Plataforma GoMoney em plataforma online, desde que em conformidade com os termos
        aqui contidos.
      </p>
      <p>
        2.2. O Usuário declara-se integralmente responsável pelas informações inseridas na Plataforma
        GoMoney, pelo cadastramento, permissões, senhas e modo de utilização. A GoMoney, em hipótese
        alguma, será responsável pelo conteúdo (informações, senhas, cópias de informações etc.) oferecido
        pelo Usuário, não sendo, portanto, tais informações revisadas nem divulgadas em momento algum.
        A responsabilidade pelas informações é sempre do Usuário.
      </p>
      <p>
        2.3. A Plataforma GoMoney disponibilizada pela GoMoney ao Usuário será mantida em um
        servidor da internet homologado pela GoMoney.
      </p>
      <p>
        2.4. A GoMoney reserva-se o direito de atualizar a Plataforma GoMoney sempre que desejar,
        bem como deixar de comercializá-la, atualizá-la, ou mesmo descontinuá-la a qualquer tempo.
      </p>
      <h1>3. CADASTRO E VERIFICAÇÃO DO CADASTRO</h1>
      <p>
        3.1. Para utilizarem os Serviços, os Usuários deverão realizar um cadastro prévio na Plataforma
        GoMoney (“Cadastro”) em que o Usuário deverá informar um e-mail válido, criação de login e senha,
        bem como os dados previstos nos itens 3.2 e 3.3 abaixo, conforme aplicável
      </p>
      <p className="ml-10">
        3.1.1. O Usuário responderá pela veracidade e atualização dos dados informados no
        Cadastro e será exclusivamente responsável pelo correto preenchimento de seu Cadastro,
        inclusive dos dados de sua conta bancária nos termos do item abaixo.
      </p>
      <p>
        3.2. O Cadastro a ser preenchido pelo Usuário pessoa física incluirá, entre outras informações,
        (i) nome completo; (ii) filiação; (iii) data e local de nascimento; (iv) sexo; (v) estado civil; (vi) nome do
        cônjuge, se casado; (vii) profissão; (viii) documento de identificação (tipo, número, data de emissão
        e órgão expedidor); (ix) o número do Cadastro Nacional de Pessoa Física (CPF); (x) uma imagem de
        documento com foto; (xi) comprovante de residência com data máxima de 3 (três) meses anteriores
        ao Cadastro; (xii) número de telefone e código de Discagem Direta a Distância (DDD); (xiii) e-mail;
        (xiv) foto na modalidade “selfie” contendo o Usuário e o documento legível; e (xv) dados bancários
        (banco, agência e conta) de conta bancária de titularidade do Usuário. Caso a conta bancária do
        Usuário esteja localizada no exterior, deverão ser também fornecidas outras informações a serem
        solicitadas pela GoMoney para que essa possa realizar uma ordem de pagamento internacional.
      </p>
      <p className="ml-10">
        3.2.1. Para registrar-se como Usuário na Plataforma GoMoney, a pessoa física deve ter no
        mínimo 18 anos de idade completos.
      </p>
      <p>
        3.3. O Cadastro de Usuário pessoa jurídica deverá ser realizado necessariamente por um
        representante legal devidamente autorizado para tanto e deverá incluir, entre outras informações:
        (i) razão social; (ii) estatuto social com a indicação da atividade principal, endereço da sede e a forma
        e data de constituição; (iii) número de inscrição no Cadastro Nacional da Pessoa Jurídica (CNPJ); e
        (iv) documentos contendo as informações elencadas no item 3.2 acima relativas a representantes,
        administradores, mandatários ou prepostos autorizados a executar as ordens no âmbito da
        Plataforma GoMoney munidos de instrumento particular com firma reconhecida; (v) atos
        constitutivos, devidamente registrados, na forma da lei, na autoridade competente; e (v) dados
        bancários (banco, agência e conta) de conta de titularidade do Usuário.
      </p>
      <p>
        3.4. Previamente ao resgate em reais das GMCs mantidas em sua Conta GoMoney, o Usuário
        também deverá completar o seu Cadastro com os dados de uma conta bancária válida e de sua
        titularidade, mantida no Brasil e/ou no exterior, para a qual recursos provenientes do resgate de
        GMCs da Conta GoMoney deverão ser transferidos.
      </p>
      <p>
        3.5. Ao preencher o formulário de Cadastro, o Usuário ingressa no banco de dados da GoMoney,
        autorizando à GoMoney o acesso a referidos dados disponibilizados.
      </p>
      <p>
        3.6. Sendo constatada irregularidade no preenchimento do Cadastro, omissão de informações,
        dados equivocados ou preenchimentos errôneos, a GoMoney poderá suspender o acesso do Usuário
        à área do cadastro até que a situação se regularize, não se responsabilizando pelo salvamento
        automático de cadastro anterior.
      </p>
      <p>
        3.7. O Cadastro na Plataforma GoMoney é gratuito, porém serão cobradas tarifas pelos Serviços,
        de acordo com o [demonstrativo de tarifas]
      </p>
      <p>
        3.8. Os dados pessoais, de acesso e de transações dos Usuários são protegidos pela Política de
        Privacidade.
      </p>
      <p>
        3.9. Para a emissão de Ordens de Compra ou Ordens de Resgate, é necessário que o Cadastro
        esteja validado pela GoMoney, sendo que a validação do Cadastro se dará mediante a análise de
        toda a documentação e informações fornecidas pelo Usuário para fins do Cadastro (vide itens 3.2 e
        3.3) e comunicado ao Usuário mediante comunicação eletrônica (e-mail) informando acerca da
        validação do Cadastro. Para esta validação e consequente cumprimento dos procedimentos mínimos
        de segurança internos, a GoMoney realizará um procedimento de verificação dos dados informados
        e poderá solicitar ao Usuário o envio de cópia de documentos e/ou documentos adicionais.
      </p>
      <p>
        3.10. O Usuário garante que todas as informações prestadas são válidas e precisas e que a senha
        determinada é pessoal e intransferível. A atualização dos dados informados também é de
        responsabilidade do Usuário e este está ciente de que a precisão e veracidade destes dados são
        imprescindíveis à efetivação dos Serviços.
      </p>
      <p>
        3.11. A GoMoney se reserva o direito de recusar qualquer solicitação de cadastro e de suspender
        ou cancelar um cadastro previamente aceito nos casos (i) de violação de qualquer das disposições
        destes Termos de Uso; (ii) impossibilidade de verificação da identidade do Usuário ou constatação
        de falsidade em qualquer das informações por ele fornecidas; (iii) prática pelo Usuário de atos
        fraudulentos ou dolosos ou a adoção de qualquer comportamento que, a critério da GoMoney, seja
        incompatível com os objetivos da Plataforma GoMoney, ou que possam, de qualquer modo, causar
        danos a terceiros ou à Plataforma GoMoney. Caso o cadastro de qualquer Usuário seja suspenso ou
        cancelado por quaisquer das razões previstas nestes Termos de Uso, todas as suas Ordens de Compra
        ou Ordens de Resgate de GMCs ainda não executadas serão automaticamente canceladas.
      </p>
      <p>
        3.12. O Usuário aceita e concorda que toda e qualquer transação deve ser efetuada tão somente pelo titular do Cadastro.
      </p>
      <p>
        3.13. O “nome de usuário” (log-in) não poderá guardar semelhança com o nome “GoMoney”.
        Também poderão ser cancelados Cadastros efetuados com nomes de Usuário (log-in) considerados
        ofensivos.
      </p>
      <p>
        3.14. O Usuário está ciente de que é proibido manter mais de um Cadastro na Plataforma
        GoMoney e que, em caso de descumprimento, a GoMoney poderá cancelar ou suspender todos os
        cadastros e Ordens de Compra e Ordens de Resgate vinculados.
      </p>
      <p>
        3.15. O Usuário é o único e exclusivo responsável por manter em segurança e sigilo seu login e
        senha, evitando assim o uso não autorizado de seu Cadastro. Para proteger sua senha, o Usuário
        deverá (i) memorizá-la, (ii) não usar uma senha que possa ser facilmente associada a você, tal como
        seu nome, data de nascimento ou número de telefone; (iii) tomar cuidado para impedir que outra
        pessoa veja a sua senha quando você a estiver utilizando; e (iv) não manter um registro de sua senha
        juntamente com o registro de seu login.
      </p>
      <p>
        3.16. O Usuário deverá avisar imediatamente a GoMoney, através do email
        contact@gomoney.me, em caso de suspeita de acesso indevido à sua Conta GoMoney, ou qualquer
        atividade que possa dar ensejo a tal, para que a GoMoney possa tomar as devidas providências.
      </p>
      <p>
        3.17. Para a segurança dos Usuários, A GOMONEY NÃO SOLICITA SENHA, DADOS DE CARTÃO DE
        CRÉDITO OU OUTROS DADOS BANCÁRIOS DE SEUS CLIENTES POR E-MAIL, TELEFONE OU QUALQUER
        OUTRO CANAL DE ATENDIMENTO PERSONALIZADO. Portanto, se você receber qualquer
        comunicação com esse tipo de abordagem e conteúdo, não responda, desconsidere-o e, se possível,
        encaminhe o seu relato através do e-mail contact@gomoney.me. A GoMoney está ativa no combate
        à fraude eletrônica e conta com a conscientização de seus Usuários para a prática da navegação
        segura na internet.
      </p>
      <h1>4. FUNCIONAMENTO, REGRAS DE UTILIZAÇÃO DOS SERVIÇOS, LIMITES, RESTRIÇÕES E RESPONSABILIDADES DO USUÁRIO</h1>
      <h4>4.1. Funcionamento Geral</h4>
      <div className="ml-10">
        <p>
          4.1.1. Através dos Serviços providos pela Plataforma GoMoney, a GoMoney oferece uma
          plataforma para armazenamento e resgate de GMCs pelo Usuário, transações estas efetuadas
          pelos próprios Usuários, possibilitando que tais usuários negociem diretamente com a GoMoney.
          Uma unidade de valor da GMC é equivalente a um dólar dos Estados Unidos da América (ou o seu
          valor equivalente em Reais) com base na taxa de câmbio de fechamento de cotações para
          contabilidade (venda) divulgada pelo Banco Central do Brasil, através do sistema PTAX (ou
          qualquer sistema similar que venha a substituí-lo).
        </p>
        <p>
          4.1.2. A aquisição da GMC poderá ser efetuada mediante: (i) pagamento em em Reais,
          para contrapartes localizadas no Brasil, mediante depósito ou transferência para a Conta Bancária
          GoMoney; ou (ii) emissão de ordem de pagamento internacional em dólar americano utilizando
          os dados disponíveis no link: [<a href="https://gmc.gomoney.me" target="_blank" rel="noopener noreferrer"> https://gmc.gomoney.me</a>].
        </p>
        <p>
          4.1.3. A GMC não confere direito a participação societária na GoMoney ou aos seus
          resultados, tampouco envolve a assunção de quaisquer riscos relativos aos seus negócios (exceto
          o risco de crédito inerente à emissão da própria GMC). O Usuário somente poderá utilizar a GMC
          como forma de pagamento em transações no país ou no exterior ou então resgatá-la contra a
          própria GoMoney, a qual garante a recompra em reais pelo seu valor correspondente em dólar.
        </p>
        <p>
          4.1.4. As Ordens de Resgate serão sempre liquidadas via transferência bancária ou ordem
          de pagamento internacional para a conta bancária do Usuário indicada no Cadastro. Não haverá
          liquidações em espécie ou mediante entrega de qualquer outro tipo de ativo pela GoMoney.
        </p>
        <p>
          4.1.5. O Usuário poderá utilizar os Serviços por meio da Plataforma GoMoney para (i)
          armazenar GMCs em sua Conta GoMoney; (ii) iniciar/emitir Ordem de Compra e/ou Ordem de
          Resgate; (iii) transferir GMC’s para outros usuários; (iv) receber em sua Conta GoMoney GMCs
          provenientes de Ordens de Compra ou de outros Usuários; e (iv) solicitar o resgate em moeda
          corrente nacional ou dólar dos Estados Unidos da América, conforme aplicável, do montante de
          GMCs constantes da Ordem de Resgate emitida pelo Usuário.
        </p>
        <p className="ml-10">
          4.1.5.1. O Usuário reconhece e concorda expressamente que a inserção de seu login e senha
          nos campos indicados na Plataforma GoMoney constituem uma legítima e suficiente forma
          para a comprovação da identidade do Usuário e da validade de sua declaração de vontade em
          contratar os Serviços e transmitir as Ordens de Compra, Ordens de Resgate e quaisquer outras
          instruções no âmbito da Plataforma.
        </p>
      </div>
      <h4>4.2. Conta GoMoney</h4>
      <div className="ml-10">
        <p>
          4.2.1. Após conclusão do Cadastro pelo Usuário é criada automaticamente para o Usuário
          uma Conta GoMoney, que poderá ser acessada, consultada e transacionada através do uso de
          seu login e senha
        </p>
        <p>
          4.2.2. A Conta GoMoney apresentará o saldo em GMCs detidas pelo Usuário, bem como o saldo em Reais.
        </p>
      </div>
      <h4>4.3. Limites</h4>
      <div className="ml-10">
        <p>
          4.3.1. A GoMoney poderá utilizar determinados parâmetros próprios para estabelecer
          determinados limites, tais como, mas não se limitando a quantidade de transações de GMC e de
          valores transferidos à Conta Bancária GoMoney para aquisição de GMCs.
        </p>
        <p>
          4.3.2. A GoMoney poderá, a seu exclusivo critério, aumentar ou diminuir os limites
          inicialmente estipulados.
        </p>
      </div>
      <h4>4.4. Da Utilização dos Serviços</h4>
      <div className="ml-10">
        <p>
          4.4.1. O Usuário se compromete a não utilizar os Serviços para fins diretos ou indiretos de
          (i) infringir qualquer lei, regulamento, estes Termos de Uso, nem praticar atos contrários à moral
          e aos bons costumes; (ii) realizar, de qualquer forma, a prática ilegal de lavagem de dinheiro; e/ou
          (iii) financiar atividades e/ou organizações que envolvam terrorismo, crime organizado, tráfico de
          drogas, pessoas e/ou órgãos humanos.
        </p>
        <p>
          4.4.2. Como condição para a utilização dos Serviços, o Usuário entende e reconhece que
          as transações de compra e resgate de GMC são realizadas por sua conta e risco e que a GoMoney
          atua como contraparte nestas referidas operações. Em nenhum momento a GoMoney poderá
          ser considerada responsável por quaisquer perdas, danos, prejuízos e/ou lucros cessantes que
          possam eventualmente ser sofridos pelos Usuários devido às transações de compra e resgate
          realizadas ou não através da Plataforma GoMoney, bem como devido à variação do valor da GMC
          frente ao dólar.
        </p>
      </div>
      <h4>4.5 Riscos</h4>
      <div className="ml-10">
        <p>
          4.5.1. A GoMoney não é uma consultoria de investimentos, tampouco seus Serviços implicam
          qualquer tipo de consultoria sobre investimentos e/ou aplicação em GMCs, sendo que o
          Usuário o faz por sua conta e risco.
        </p>
        <p>
          4.5.2. <strong>EVENTOS DE BIFURCAÇÃO (FORK)</strong> - EM CASO DE QUALQUER EVENTO, PREVISTO
          OU NÃO, NA REDE, PROTOCOLO OU CONSENSO EM QUE AS GMCs SÃO EMITIDAS, INCLUINDO,
          MAS NÃO SE LIMITANDO, AO EVENTO DE BIFURCAÇÃO DA REDE (FORK), A GOMONEY DECLARA
          EXPRESSAMENTE E O USUÁRIO CONCORDA MEDIANTE O ACEITE DESTES TERMOS DE USO, QUE
          A GOMONEY NÃO TERÁ QUALQUER OBRIGAÇÃO DE CRIAR OS MEIOS PARA QUE SEUS USUÁRIOS
          RECEBAM QUAISQUER ATIVOS DECORRENTES DE TAIS EVENTOS, SALVO SE HOUVER
          COMUNICAÇÃO PRÉVIA POR ESCRITO EM SENTIDO CONTRÁRIO DIVULGADA PELA GOMONEY NA
          PLATAFORMA GOMONEY. Os Usuários que tenham ciência de evento próximo (observado que
          não cabe à GoMoney de forma alguma comunicar os Usuários da iminência de qualquer um de
          tais eventos) e desejem receber referidos ativos deverão, se assim desejarem, tomar as medidas
          necessárias para resgatar suas GMCs das Contas GoMoney.
        </p>
        <p>
          4.5.3. <strong>Falta de Garantia pelos Bancos Centrais</strong> - Criptomoedas e Tokens que operam como
          meio de troca não são emitidos ou garantidos por qualquer banco central ou uma organização
          nacional ou internacional, e não há garantia de que tais Critptomoedas e Tokens possam
          funcionar como um meio de troca legal em qualquer jurisdição. Determinadas jurisdições
          proibiram completamente a utilização de determinadas Criptomoedas e Tokens;
        </p>
        <p>
          4.5.4. <strong>Utilização de Terceiros</strong> - Como produto e tecnologia relativamente novos,
          Criptomoedas e Tokens ainda não são amplamente adotados como forma de pagamento de bens
          e serviços. Os bancos e outras instituições financeiras podem se recusar a transferir fundos para
          operações de criptomoedas, processar transferências eletrônicas ou de trocas de criptomoedas,
          para empresas relacionadas ao blockchain ou fornecedores de serviços, ou manter contas para
          pessoas ou instituições que fazem operações em Criptomoedas e Tokens;
        </p>
        <p>
          4.5.5. <strong>Ambientes de Negociação Não Regulamentados</strong> - Os ambientes de negociação de
          Criptomoedas e Tokens são relativamente novos e em grande parte não regulamentados e,
          portanto, podem estar mais expostas a roubo, fraude e falha em relação aos mercados
          regulamentados de outros ativos. Os ambientes de negociação geralmente exigem que dinheiro
          seja depositado antecipadamente para comprar Criptomoedas e Tokens, e nenhuma garantia
          pode ser dada que esses recursos depositados serão recuperados. Além disso, após a venda das
          Criptomoedas e dos Tokens, pode demorar diversos dias úteis até que o produto da alienação
          possa ser recebido em espécie. A participação nos ambientes de negociação exige que os usuários
          assumam o risco de crédito, transferindo Criptomoedas e Tokens de uma conta pessoal para a
          conta de terceiros. O Fundo Investido assumirá o risco de crédito de uma sociedade que opera
          um ambiente de negociação toda vez que realizar uma operação;
        </p>
        <p>
          4.5.6. <strong>Limites de Operação</strong> - Os ambientes de negociação de Criptomoedas e de Tokens
          podem impor limites de operação ou de distribuição diários, semanais, mensais ou específicos do
          cliente ou suspender totalmente os saques, tornando a troca de moeda virtual ou o Token digital
          para moeda de curso legal difícil ou impossível. Além disso, a liquidação das criptomoedas estão
          sujeitas à influência de muitos fatores, incluindo os níveis de liquidez nas trocas e interferências
          e interrupções operacionais, como autorizações de instituições bancárias e órgãos
          governamentais.
        </p>
      </div>
      <h1>5.1 Ordem de Resgate de GMC</h1>
      <p>5.1.1 Para que seja possível emitir uma Ordem de Resgate, o Usuário deve ter GMCs armazenadas em sua Conta GoMoney</p>
      <p>
        5.1.2 As GMCs estarão disponíveis após 6 (seis) confirmações do sistema Blockchain. O Usuário
        reconhece e concorda que as confirmações não dependem da GoMoney e que o tempo para
        obtê-las depende da taxa paga diretamente pelo Usuário à rede Blockchain.
      </p>
      <p>
        5.1.3 O resgate do valor dos recursos equivalentes ao montante em GMC constante da Conta
        GoMoney do Usuário será iniciado pelo Usuário mediante emissão contra a GoMoney no
        âmbito da Plataforma GoMoney da Ordem de Resgate. Após o recebimento e
        processamento da Ordem de Resgate, a GoMoney realizará a transferência dos recursos em
        moeda corrente nacional e/ou dólar dos Estados Unidos da América, conforme a localização
        do Usuário, sendo realizada via transferência para conta bancária detida pelo Usuário e
        informada no Cadastro no Brasil ou no exterior. A liquidação da transferência dos recursos
        equivalentes ao montante em GMC constante da Conta GoMoney do Usuário para conta
        bancária detida pelo Usuário e informada no Cadastro se dará em até 3 (três) dias contados
        do dia útil imediatamente posterior ao dia em que confirmado o processamento da Ordem
        de Resgate pela GoMoney.
      </p>
      <p>
        5.1.4 Caso conta bancária informada pelo Usuário no Cadastro seja detida por este no
        exterior, a GoMoney cursará, junto a uma instituição financeira autorizada pelo Banco Central do
        Brasil a operar no mercado de câmbio, uma operação de câmbio para a remessa de recursos ao
        exterior.
      </p>
      <p>
        5.1.5 Caso a transação solicitada pelo Usuário seja reprovada pela instituição financeira
        de Câmbio por restrições de qualquer natureza, o montante será devolvido para a conta corrente
        da qual a transferência bancária foi originada, e o envio ou recebimento não será realizado. Nesse
        cenário, os custos operacionais, tais como taxa de estorno e custos provenientes da reversão
        poderão ser debitados do Usuário.
      </p>
      <p>
        5.1.6 O valor da venda da GMC é equivalente a um dólar dos Estados Unidos da América,
        ou à sua cotação em reais baseada na taxa de câmbio de fechamento de cotações para
        contabilidade (venda) divulgada pelo Banco Central do Brasil, através do sistema PTAX (ou
        qualquer sistema similar que venha a substituí-lo), no dia útil imediatamente anterior à
        realização, pelo Usuário, da emissão da Ordem de Resgate.
      </p>
      <h1>6.1 Ordem de Compra de GMC</h1>
      <p>
        6.1.1 Para comprar GMC, o Usuário deverá realizar transferência bancária (DOC ou TED) ou depósito para a Conta Bancária GoMoney.
      </p>
      <p>
        6.1.2 O valor aportado na Conta Bancária GoMoney será considerado disponível no momento em
        que a compensação do DOC, TED ou depósito for efetivamente identificada na Conta
        Bancária GoMoney, o que pode ocorrer em até 3 (três) dias úteis ou a depender da
        instituição bancária.
      </p>
      <p>
        6.1.3 Caso a conta bancária informada pelo Usuário no Cadastro seja detida por este no exterior,
        a GoMoney cursará, junta a uma instituição financeira autorizada pelo Banco Central do
        Brasil a operar no mercado de câmbio uma operação de câmbio para ingresso de recursos
        oriundos do exterior.
      </p>
      <p>
        6.1.4 O valor da compra da GMC é equivalente a um dólar dos Estados Unidos da América, ou à
        sua cotação em reais baseada na taxa de câmbio de fechamento de cotações para
        contabilidade (venda) divulgada pelo Banco Central do Brasil, através do sistema PTAX (ou
        qualquer sistema similar que venha a substituí-lo), no dia útil imediatamente anterior à
        realização, pelo Usuário, da transferência bancária (DOC ou TED) ou depósito na Conta
        Bancária GoMoney.
      </p>
      <p>
        6.1.5 O Usuário está ciente de que a emissão da Ordem de Compra representa uma proposta
        válida para todos os fins. Após a compensação do DOC, TED ou depósito for efetivamente
        confirmada, a transação de compra das GMCsserá automaticamente efetivada e não poderá
        ser desfeita ou modificada, devendo o Usuário arcar perante a GoMoney com eventuais
        despesas e prejuízos ocasionados pela sua eventual desistência.
      </p>
      <h1>7.1 Armazenamento de GMC na Conta GoMoney</h1>
      <p>
        7.1.1 O Usuário poderá armazenar suas GMCs em sua Conta GoMoney (i) para fins de emitir uma
        Ordem de Resgate; (ii) somente para o armazenamento das mesmas; ou (iii) transferência a
        outros Usuários por meio da Plataforma GoMoney.
      </p>
      <h1>8.1 TARIFAS GOMONEY SOBRE OS SERVIÇOS</h1>
      <p>
        8.1.1 As cotações de conversão presentes na plataforma GoMoney são ofertadas em tempo real e refletem as condições de mercado aplicáveis no momento da consulta e, portanto, estão sujeitas a oscilações. Uma vez efetuado o aporte de
        valores na Conta Gomoney para ordem de compra de GMC’s e/ou solicitado o resgaste com indicação de conta no exterior, não é possível renegociar o valor da conversão, que inclui a taxa de câmbio e demais custos para sua realização.
      </p>
      <p>
        8.1.2 A GoMoney descontará uma taxa para cada Transação realizada pelo Usuário, cujo valor é informado em momento prévio à conclusão da Transação e deduzido do montante total da Transação contratada pelo Usuário. Em caso de devolução de
        recursos oriundos de uma Transação em razão de preenchimento incorreto dos dados bancários pelo Usuário, este poderá reenviar a solicitação de resgate de recursos pela GoMOney. Porém, poderá ser cobrado custo adicional após a correção
        dos dados incorretos e reenvio dos recursos.
      </p>
      <p>
        8.1.3 As transações realizadas através da plataforma GoMoney podem estar sujeitas a custos adicionais cobrados pelas instituições bancárias utilizadas pelo Usuário, pelo pagador ou pelo recebedor dos recursos no exterior. A GoMoney não
        tem qualquer ingerência sobre a cobrança desses custos adicionais e não se responsabiliza pelos eventuais descontos oriundos de alguma cobrança realizada por tal instituição bancária. O Usuário deverá se certificar dos custos que possam
        ser aplicados aos envios e recebimentos objeto de uma Transação por parte das instituições bancárias utilizadas pelo Usuário, pelo pagador ou pelo recebedor dos recursos no exterior.
      </p>
      <p>
        8.1.4 A instituição bancária internacional recebedora dos recursos é a responsável por creditar os valores na conta indicada pelo Usuário. A GoMoney não possui controle operacional sobre essas instituições e não cobre as despesas que
        poderão ser cobradas por elas, bem como não se responsabiliza pela eventual demora em creditar os valores transferidos na conta indicada. Nesse sentido, recomenda-se que o Usuário se informe acerca das práticas da instituição bancária
        que receberá os valores transferidos e dos custos por ela cobrados, anteriormente à solicitação da transação.
      </p>
      <p>8.1.5 A GoMoney debitará as tarifas em moeda corrente nacional diretamente do montante em GMC creditado na Conta GoMoney do Usuário.</p>
      <p>8.1.6 As tarifas a serem pagas pelo Usuário à GoMoney incidirão nas seguintes operações:</p>
      <p>
        8.1.7 Ao aceitar os presentes Termos de Uso, o Usuário concorda em pagar à GoMoney os valores correspondentes às comissões pelos Serviços prestados. A GoMoney se reserva o direito de modificar, aumentar ou eliminar comissões vigentes a
        qualquer momento, seja em caráter permanente, seja em caráter transitório durante períodos de promoção. Quaisquer alterações em nossas comissões serão divulgadas na Plataforma GoMoney
      </p>
      <p>
        8.1.8 Tarifas, taxas e quaisquer cobranças advindas de instituições financeiras incidentes nas transações, serão informadas ao Usuário no momento da solicitação da transação e descontadas da sua Conta GoMoney.
      </p>
      <p>
        8.1.9 A informação e declaração das transações realizadas no ambiente da plataforma GoMoney e os tributos possível e futuramente incidentes em tais operações, serão de inteira responsabilidade do Usuário.
      </p>
      <h1>9.1 SUPORTE TÉCNICO</h1>
      <p>
        9.1.1 A prestação do serviço de suporte técnico limitar-se-á a esclarecimentos acerca do
        funcionamento da Plataforma GoMoney e sua operação, sendo certo que o Usuário, ao
        aceitar os termos previstos nestes Termos de Uso, declara possuir o conhecimento mínimo
        do uso do computador e/ou dispositivo móvel e suas funções, bem como do sistema
        operacional sob o qual o sistema será utilizado. O Usuário declara, ainda, possuir a
        configuração adequada em seu computador e/ou dispositivo móvel no que se refere à
        utilização da Plataforma GoMoney e o bom estado de funcionamento do referido
        computador/dispositivo móvel
      </p>
      <p>
        9.1.2 O suporte técnico ao Usuário se dará através de e-mail por meio de troca de informações,
        cabendo à GoMoney fornecer uma resposta em até 2 (dois) dias úteis, contadas do chamado
        realizado pelo Usuário, não estando incluído neste serviço, o atendimento in loco.
      </p>
      <p>
        9.1.3 O suporte técnico estará disponível nos dias úteis, no horário de 9h às 18h, de acordo com
        o calendário e horário oficiais da capital do Brasil, a cidade de Brasília.\
      </p>
      <p>
        9.1.4 O suporte técnico fornecido pela GoMoney não abrange a conexão com a internet, rede
        interna e computadores/dispositivos móveis do Usuário.
      </p>
      <h1>10.1 Disponibilidade da Plataforma</h1>
      <p>
        10.1.1 A GoMoney procura assegurar que as informações fornecidas sejam precisas,
        completas, atuais e que o seu uso não tenha interrupções nem erros. No entanto, não podemos
        garantir a operação da Plataforma GoMoney em tempo integral, na medida em que esta depende
        de serviços prestados por terceiros, como empresas de telecomunicações e provedores de acesso
        à internet. Além disso, suspenderemos o uso e acesso à Plataforma GoMoney em caso fortuito
        ou força maior, por exemplo, em caso de ataques cibernéticos e outros que possam prejudicar as
        funcionalidades da Plataforma GoMoney e colocar em risco as informações dos Usuários.
      </p>
      <h1>11 MELHORIAS/CORREÇÕES/NOVAS VERSÕES</h1>
      <p>
        11.1 A GoMoney envidará seus melhores esforços no sentido de manter as informações na
        Plataforma GoMoney tão atualizadas, completas e precisas quanto possível. Para esse fim, procurará
        corrigir o mais rápido possível eventuais imprecisões ou omissões das informações contidas na
        Plataforma GoMoney.
      </p>
      <p>
        11.2 A Plataforma GoMoney será constantemente aperfeiçoada pela GoMoney, conforme sua
        conveniência e necessidade, podendo inclusive ocorrer suspensão temporária dos serviços em
        virtude de aperfeiçoamento realizado pela GoMoney. Todas as modificações, melhorias e correções
        efetuadas na Plataforma GoMoney, mesmo que informadas, solicitadas e eventualmente pagas pelo
        Usuário, ficam incorporadas à Plataforma GoMoney e sujeitas aos presentes Termos de Uso.
      </p>
      <p>
        11.3 Faculta-se à GoMoney acolher as sugestões de melhorias realizadas pelo Usuário, não
        assistindo a ele, em nenhuma hipótese, qualquer direito de propriedade sobre tais inserções ou
        melhorias
      </p>
      <p>
        11.4 O Usuário terá acesso às melhorias de funcionamento, correções e/ou novos recursos da
        Plataforma GoMoney eventualmente desenvolvidos/implementados pela GoMoney.
      </p>
      <p>
        11.5 O Usuário desde já concorda com modificações, implementação de novos recursos,
        ferramentas, melhorias ou correções no modelo de Plataforma GoMoney que utiliza, ficando a
        critério da GoMoney o gerenciamento e aprovação destas modificações na Plataforma GoMoney. A
        implementação das atualizações será realizada pela própria GoMoney de forma automática na
        Plataforma GoMoney, ficando a cargo do Usuário baixar as novas versões disponibilizadas.
      </p>
      <h1>12 RESTRIÇÕES AO USO DA PLATAFORMA</h1>
      <p>
        12.1 Aplicam-se aos presentes Termos de Uso as disposições da Lei nº 9.609, de 19 de Fevereiro
        de 1998 (“Lei 9.609/98”) e Lei 9.610 de 10 de Fevereiro de 1988 (“Lei 9.610/98”), ficando os
        infratores sujeitos às penas dos crimes previstos no art. 12 da Lei 9.609/98, sem prejuízo da
        responsabilidade civil pelos danos eventualmente causados pelo uso e distribuição de cópias não
        autorizadas da Plataforma GoMoney ou por qualquer outra violação aos direitos decorrentes da
        propriedade do mesmo, incluindo, mas não se limitando, o acesso de terceiros não autorizados.
      </p>
      <p>
        12.2 É vedado ao Usuário, entre outros:
      </p>
      <div className="ml-10">
        <p>
          (i) violar qualquer lei, regulamento, decreto, portaria, ordem, decisão ou regulamentação
          vigente, emitida por qualquer autoridade governamental no Brasil ou no exterior;
        </p>
        <p>
          (ii) infringir os direitos autorais, de patente, de marca comercial, de segredo comercial nem
          outros direitos de propriedade intelectual, industrial ou de publicidade ou
          confidencialidade, seja da GoMoney ou de terceiros;
        </p>
        <p>
          (iii) fornecer informações falsas, incompletas ou incorretas a GoMoney, a outros Usuários
          ou terceiros;
        </p>
        <p>
          (iv) envolver-se em atividades e/ou transações potencialmente fraudulentas ou suspeitas;
        </p>
        <p>
          (v) recusar-se a cooperar em uma investigação ou a apresentar confirmação de sua
          identidade ou alguma informação ou documento solicitado pela GoMoney ou por
          qualquer autoridade governamental brasileira ou internacional;
        </p>
        <p>
          (vi) usar os Serviços de maneira que acarrete ou possa acarretar queixas, disputas,
          reclamações, cancelamentos, tarifas, multas, penalidades e outras obrigações para a
          GoMoney, outros Usuários, terceiros ou a você mesmo;
        </p>
        <p>
          (vii) ter uma classificação de crédito atribuída por uma agência de análise de risco de crédito
          que indique um alto nível de risco em seu uso dos Serviços, a exclusivo critério da
          GoMoney;
        </p>
        <p>
          (viii) divulgar ou distribuir as informações de outro Usuário para terceiros, ou usar tais
          informações para fins de marketing, a menos que você tenha o consentimento expresso
          do respectivo Usuário para tanto;
        </p>
        <p>
          (ix) atuar de forma a impor uma carga injustificável ou desproporcional nos Serviços ou em
          nossa Plataforma GoMoney/website, software, sistemas (incluindo quaisquer redes e
          servidores utilizados para fornecer quaisquer Serviços) operados por nós ou em nosso
          nome;
        </p>
        <p>
          (x) usar um proxy anonimizador, robot, bot ou outro dispositivo automático ou processo
          manual para monitorar ou copiar nosso website sem nossa prévia permissão por escrito;
        </p>
        <p>
          (xi) usar um dispositivo, programa ou rotina para ignorar nossos cabeçalhos de exclusão de
          robots ou para interferir ou interromper ou tentar interferir ou interromper o
          funcionamento de nossa Plataforma GoMoney/website, software, sistemas (incluindo
          quaisquer redes e servidores utilizados para fornecer quaisquer Serviços) operados por
          nós ou em nome da GoMoney, quaisquer dos Serviços, ou o uso por outro Usuário de
          quaisquer Serviços;
        </p>
        <p>
          (xii) atuar de forma a nos fazer perder ou impactar qualquer serviço de nossos provedores
          de serviços da Internet, processadores de pagamento ou quaisquer outros fornecedores
          ou prestadores de serviços;
        </p>
        <p>
          (xiii) violar qualquer legislação municipal, nacional ou internacional que seja integrada ao
          ordenamento jurídico brasileiro, ou ainda, que, por qualquer razão legal, deva ser no
          Brasil aplicada;
        </p>
        <p>
          (xiv) praticar atos contrários à moral e aos bons costumes;
        </p>
        <p>
          (xv) assumir a personalidade ou identidade de outra pessoa, física ou jurídica;
        </p>
        <p>
          (xvi) carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível
          qualquer conteúdo que viole quaisquer direitos de terceiro, incluindo Direitos de
          Propriedade Intelectual (conforme definido abaixo);
        </p>
        <p>
          (xvii) carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível
          qualquer tipo de anúncio, propaganda ou material promocional não solicitado ou não
          autorizado pela Plataforma GoMoney, tais como mensagens não solicitadas ou
          mensagens enviadas em massa (conhecidos como “junk mail” ou “spam”);
        </p>
        <p>
          (xviii) carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível
          qualquer conteúdo que contenha vírus ou qualquer outro código, arquivo ou programa
          de computador com o propósito de interromper, destruir ou limitar a funcionalidade de
          qualquer software, hardware ou equipamento;
        </p>
        <p>(xix) ameaçar, coagir, constranger demais Usuários;</p>
        <p>
          (xx) violar direitos de sigilo e privacidade de outros Usuários ou de terceiros, sempre
          observada a Política de Privacidade; e
        </p>
        <p>
          (xxi) praticar quaisquer atos que direta ou indiretamente, no todo ou em parte, possam
          causar prejuízo à GoMoney, a outros Usuários ou a qualquer terceiro.
        </p>
      </div>
      <h1>13 PROPRIEDADE INTELECTUAL</h1>
      <h4>13.1 Dos direitos da GoMon</h4>
      <p className="ml-10">
        13.1.1 A Plataforma GoMoney, o conteúdo e quaisquer outros textos, gráficos, imagens,
        fotos, ilustrações, marcas comerciais, nomes comerciais, marcas de serviço, logotipos,
        informações, código-fonte, layouts, nomes de domínio, software, know-how, e outros materiais
        são todos protegidos por direito de propriedade intelectual (“Direito de Propriedade
        Intelectual”). Todos os Direitos de Propriedade Intelectual presentes na Plataforma GoMoney são
        de titularidade da GoMoney, licenciados à GoMoney ou de nossos parceiros (conforme aplicável).
        A exploração comercial, transmissão, divulgação, modificação, reprodução, cópia ou quaisquer
        outras formas de utilização comercial de tal conteúdo pelo Usuário são rigorosamente proibidas.
      </p>
      <h4>13.2 Reprodução de conteúdo para uso próprio</h4>
      <div className='ml-10'>
        <p>
          13.2.1 A reprodução de qualquer conteúdo da Plataforma GoMoney é proibida, salvo
          prévia autorização por escrito da GoMoney, ou nos casos em que se destina ao uso
          exclusivamente pessoal do Usuário. Em nenhum caso, o Usuário adquirirá qualquer Direito de
          Propriedade Intelectual sobre qualquer parte de referido conteúdo
        </p>
        <p>
          13.2.2 Em caso de violação pelo Usuário de qualquer Direito de Propriedade Intelectual da
          GoMoney, o Usuário assume toda e qualquer responsabilidade de caráter civil e/ou criminal
          advindos de referida violação.
        </p>
      </div>
      <h1>14 RESPONSABILIDADE DA GOMONEY</h1>
      <p>
        14.1 A GoMoney não será responsabilizada por casos fortuitos ou de força maior ou fatos
        decorrentes de culpa exclusiva de terceiros ou do Usuário, como (i) resultados produzidos pela
        Plataforma GoMoney, caso esta seja afetada por algum tipo de programa externo, como aqueles
        conhecidos popularmente como vírus, ou por falha de operação ou corrupção de dados; (ii) qualquer
        defeito decorrente de culpa exclusiva do Usuário; (iii) integração da Plataforma GoMoney contratada
        com qualquer outro software de terceiros ou do Usuário; (iv) danos ou prejuízos decorrentes de
        decisões administrativas, gerenciais ou comerciais tomadas pelo Usuário com base nas informações
        fornecidas pela Plataforma GoMoney; (v) problemas na rede do Usuário; (vi) toda e qualquer
        responsabilidade por eventuais perdas e danos, lucros cessantes ou emergentes, cujas causas
        possam ser atribuídas, de forma direta ou indireta, ao fornecimento, utilização ou desempenho da
        Plataforma GoMoney; (vii) eventos definidos na legislação civil como caso fortuito ou força maior;
        (viii) uso perigoso e/ou inadequado do Plataforma GoMoney; e (ix) fatores de ordem técnica que
        impossibilitem a transferência de dados e/ou o download de informações da Plataforma GoMoney
        contratada pelo Usuário e/ou de suas respectivas versões, se houver.
      </p>
      <p>
        14.2 A GoMoney não será responsável perante o Usuário por acatar e cumprir qualquer
        mandado, ordem judicial inclusive, ou de autoridade administrativa competente, sentença ou
        decisão, independentemente de tal mandado, ordem judicial, sentença ou decisão vir a ser
        subsequentemente objeto de reforma, modificação, anulação, revogação ou cancelamento
      </p>
      <h4>VIGÊNCIA</h4>
      <p>
        14.4 O prazo para utilização da Plataforma GoMoney e de vigência dos presentes Termos de Uso
        será por tempo indeterminado
      </p>
      <h1>15 RESCISÃO, INTERRUPÇÃO, SUSPENSÃO OU CANCELAMENTO DO CADASTRO E/OU SERVIÇOS</h1>
      <p>
        15.1 O Usuário poderá rescindir este instrumento a qualquer momento, por meio da solicitação
        de encerramento do Cadastro de sua titularidade através do e-mail <a href="mailto:contact@gomoney.me">contact@gomoney.me</a>
      </p>
      <p>
        15.2 Para fins de encerramento do Cadastro do Usuário e consequentemente da Conta GoMoney
        de sua titularidade, o Usuário deverá se certificar de que não há registro de GMCs em sua Conta
        GoMoney, cabendo ao Usuário realizar os devidos resgates, se aplicável
      </p>
      <p>
        15.3 Após a suspensão e/ou cancelamento do Cadastro do Usuário, realizado por ele mesmo ou
        pela GoMoney, o Usuário reconhece que poderá não mais ter acesso e/ou resgatar informações
        sobre transações realizadas através da Plataforma GoMoney, não tendo a GoMoney qualquer dever
        de armazenamento de informações, tampouco de repassar essas informações ao Usuário.
      </p>
      <p>
        15.4 Em caso de suspeita de fraude ou de qualquer outra atividade ilícita, a GoMoney poderá,
        além de recorrer às medidas legais cabíveis, reter eventuais fundos ou ativos armazenados na Conta
        GoMoney do Usuário ou de qualquer outra forma entregues à GoMoney, até a conclusão das
        respectivas investigações.
      </p>
      <h4>15.5 Causas para Rescisão, Suspensão e/ou Cancelamento Imediat</h4>
      <div className='ml-10'>
        <p>15.5.1 A GoMoney reserva para si o direito de suspender e/ou cancelar o cadastro dos
          Usuários imediatamente e indefinidamente, a qualquer tempo e sem aviso prévio, em caso de
          indícios de, mas sem se limitar a:</p>
        <p>
          (i) fornecimento de informações falsas, imprecisas, incompletas ou enganosas;
        </p>
        <p>
          (ii) não fornecimento de informações e/ou documentos adicionais, seja ou não para a
          validação do Cadastro;
        </p>
        <p>
          (iii) ações de má-fé;
        </p>
        <p>
          (iv) violação de algum dos termos dos presentes Termos de Uso;
        </p>
        <p>
          (v) uso dos Serviços em desacordo com a lei ou qualquer regulamento relacionado;
        </p>
        <p>
          (vi) envolvimento em condutas fraudulentas ou ilegais;
        </p>
        <p>
          (vii) tentativa de double-spending, isto é, de se utilizar a mesma GMC em duas transações; e
        </p>
        <p>
          (viii) operações incompatíveis com a capacidade econômica do Usuário.
        </p>
        <p>
          15.5.2 Mesmo em caso de rescisão, todas as taxas pagas e cobranças feitas antes do
          encerramento não são reembolsáveis
        </p>
      </div>
      <h4>15.6 Contas Inativas</h4>
      <p className="ml-10">
        15.6.1 O presente Contrato poderá ser rescindido pela GoMoney uma vez evidenciada a
        inatividade da Conta GoMoney por mais de 180 (cento e oitenta) dias, observadas as demais
        disposições deste item 12.
      </p>
      <h4>15.7 Direitos e Deveres após a Suspensão e/ou Cancelamento</h4>
      <div className="ml-10">
        <p>
          15.7.1 A rescisão dos presentes Termos de Uso não afetará nenhum direito e obrigação
          que poderão ser acumulados antes da rescisão ou término destes Termos de Uso e/ou da
          prestação dos Serviços. As obrigações de quaisquer das Partes contraídas pelos presentes Termos
          de Uso permanecerão após sua rescisão e continuarão em pleno vigor e efeito até que sejam
          sanadas, incluindo, mas sem se limitar a, aquelas decorrentes das transações de GMCs e emissões
          de Ordem de Compra e Ordem de Resgate.
        </p>
        <p>
          15.7.2 Em caso de suspensão e/ou cancelamento de Cadastro, a GoMoney poderá,
          observado o previsto no item 15.7.1 acima, realizar o resgate, de toda e qualquer GMC mantida
          na Conta GoMoney e, transferir os recursos em reais provenientes de tal venda para a conta
          bancária de titularidade do Usuário informada em seu Cadastro.
        </p>
        <p>
          15.7.3 As GMCs não poderão ser transferidas para uma conta bancária. Para fins de tal
          transferência será necessária a conversão das GMCs em moeda corrente nacional, Euro, Franco
          Suíço ou Dólar dos Estados Unidos da América, iniciada mediante realização de Ordem de Resgate
          na Plataforma GoMoney. Em caso de suspensão e/ou cancelamento de Cadastro pelo Usuário ou
          pela GoMoney, o Usuário terá o prazo de 10 (dez) dias para realizar a referida Ordem de Resgate.
          Caso o Usuário deixe de realizar tal Ordem de Resgate, a GoMoney poderá efetuar o resgate de
          tais GMCs, em nome e favor do Usuário, baseando-se na cotação do dólar dos Estados Unidos da
          América com base na taxa de câmbio de fechamento de cotações para contabilidade (venda)
          divulgada pelo Banco Central do Brasil, através do sistema PTAX (ou qualquer sistema similar que
          venha a substituí-lo) válida no dia útil imediatamente anterior ao último dia do prazo para a
          realização da Ordem de Resgate referido nesta cláusula, e transferir ao Usuário os recursos em
          reais provenientes de tal venda para a conta bancária informada em seu Cadastro, observado o
          previsto no item 15.7.1 acima
        </p>
        <p>
          15.7.4 O Usuário, desde já, autoriza a GoMoney a realizar, em nome e em favor do Usuário,
          o resgate das GMCs mantidas em sua Conta GoMoney, seguida da transferência dos recursos em
          reais provenientes de tal venda, nos termos da Cláusula 15.7.3 acima.
        </p>
        <p>
          15.7.5 As formas de interrupção, suspensão e desativação dos Serviços não isenta os
          Usuários, a qualquer tempo, do cumprimento destes Termos de Uso, incluindo, mas sem se
          limitar a, as cláusulas de responsabilidade e rescisão, que sobrevivem ao término da relação entre
          o Usuário e a GoMoney
        </p>
      </div>
      <h4>16 INDENIZAÇÃO</h4>
      <p>
        16.1 Você concorda em isentar e indenizar, defender e manter a GoMoney e seus respectivos
        diretores, agentes, sócios e funcionários indenes de qualquer prejuízo, responsabilidade, ação
        judicial ou demanda, incluindo honorários advocatícios, devidos ou decorrentes (i) do uso da
        Plataforma GoMoney; (ii) de violação destes Termos de Uso ou da Política de Privacidade; (iii) de
        falhas ocorridas nos equipamentos do Usuário e/ou (iv) da utilização indevida das informações ou
        materiais da Plataforma GoMoney.
      </p>
      <h4>17 CONSIDERAÇÕES FINAIS</h4>
      <p>
        17.1 A incapacidade ou a mera liberalidade em não fazer cumprir quaisquer disposições destes
        Termos de Uso não constitui renúncia de direitos, podendo ser exigido o seu cumprimento em
        qualquer momento.
      </p>
      <p>
        17.2 Caso qualquer disposição destes Termos de Uso for considerada inexequível ou inválida,
        essa disposição será isoladamente invalidada e não atingirá o restante das disposições destes Termos
        de Uso.
      </p>
      <p>
        17.3 Estes Termos de Uso e a relação entre as Partes serão regidos pelas leis da República
        Federativa do Brasil. As Partes elegem o Foro da Comarca de São Paulo/SP como sendo o único
        competente para dirimir quaisquer litígios e/ou demandas que venham a envolver as Partes em
        relação aos Serviços.
      </p>
      <p>
        17.4 A tolerância de uma Parte com a outra quanto ao descumprimento de qualquer uma das
        obrigações assumidas nestes Termos de Uso não implicará em novação ou renúncia de direito. A
        Parte tolerante poderá, a qualquer tempo, exigir da outra Parte o fiel e cabal cumprimento destes
        Termos de Uso.
      </p>
      <p>
        17.5 Os presentes Termos de Uso vinculam as Partes e seus sucessores a qualquer título
      </p>
      <p>
        17.6 A inviabilidade da execução ou invalidade, ainda que em virtude de decisão judicial, de
        alguma cláusula, não afetará a validade das demais cláusulas destes Termos de Uso, que
        permanecerão válidos e exequíveis conforme seus termos.
      </p>
      <p>
        17.7 A GoMoney se reserva o direito de modificar os presentes Termos de Uso a qualquer tempo,
        sendo o Usuário comunicado de tais alterações, mediante o aceite das novas disposições
      </p>
      <p>
        17.8 São parte integrante e inseparável destes Termos de Uso, e nele se consideram
        incorporados:
        <br></br>
        <p className='ml-10'>Política de Privacidade</p>
      </p>
      <p>
        17.9 O USUÁRIO RECONHECE QUE AS PECULIARIDADES DE USO DA PLATAFORMA GOMONEY E
        DOS SERVIÇOS NELA DISPONIBILIZADOS FORAM SUFICIENTEMENTE DESCRITAS NESTE TERMO DE
        USO E QUE A GOMONEY CUMPRIU DEVIDAMENTE O DEVER DE INFORMAÇÃO. APÓS LER TODAS AS
        CONDIÇÕES QUE REGULAM O USO DO SITE E DE SEUS SERVIÇOS, O USUÁRIO CONSENTE COM ESTES
        TERMOS DE USO E ACEITA TODAS AS SUAS DISPOSIÇÕES
      </p>
    </div>
  )
}
import React from 'react';
import './styles.css';

export default function SeparatorHr() {
    return (
        <div className="separatorContainer">
            <div className="separatorHr">
                <hr/>
            </div>
        </div>
    )
}
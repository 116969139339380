import React from 'react';
import togetherIcon from '../../assets/icone_sobre_juntossemfronteira.png';
import './styles.css';

export default function Together(){
    return(
        <>
        <div className="TogetherContainer">
            <div className="TogetherLeft">
                <h1>
                    Juntos <br/>
                    sem fronteiras
                </h1>
                    <p>
                        Assim nasceu a <strong>GoMoney</strong>, uma empresa Emissora e <br/> Negociadora de criptoativo próprio, que desenvolveu <br/> o GMC e, com ele, acredita que pode melhorar a <br/> forma das pessoas e empresas interagirem.
                    </p>
            </div>
            <div className="TogetherRight">
                <img src={togetherIcon} alt="Juntos sem fronteiras"/>
            </div>
        </div>
        </>
    )
}
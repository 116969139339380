import React, { useState } from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Home from './pages/Home';
import Legal from './pages/Legal';
import GMC from './pages/GMC';
import Caso from './pages/caso';
import Send from './pages/Send';
import Receive from './pages/Receive';
import ScrollToTop from './components/ScrollToTop';
import Faq from './pages/Faq';
import About from './pages/About';
import SignupIndex from "./pages/Signup/SignupIndex";
import SignupCompany from "./pages/Signup/Company/SignupCompany";
import SignupCompanyAddress from "./pages/Signup/Company/SignupCompanyAddress";
import SignupCompanyDocument from "./pages/Signup/Company/SignupCompanyDocument";
import SignupCompanyPartners from "./pages/Signup/Company/SignupCompanyPartners";
import SignupSuccess from "./pages/Signup/SignupSuccess";
import SignupConfirmation from "./pages/Signup/SignupConfirmation";
import SignupPeople from "./pages/Signup/People/SignupPeople";
import SignupPeopleAddress from "./pages/Signup/People/SignupPeopleAddress";
import SignupPeopleDocuments from "./pages/Signup/People/SignupPeopleDocuments";

export default function Routes() {
    const [stepsTotalCompany, setStepCompany] = useState(5)
    const [stepsTotalPeople, setStepPeople] = useState(4)
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/legal" component={Legal}/>
                    <Route path="/gmc" component={GMC}/>
                    <Route path="/caso" component={Caso}/>
                    <Route path="/send" component={Send}/>
                    <Route path="/receive" component={Receive}/>
                    <Route path="/faq" component={Faq}/>
                    <Route path="/about" component={About}/>
                    <Route path="/signup" exact={true} component={SignupIndex}/>


                    {/* Signup People BR */}
                    <Route
                        exact={true}
                        path="/signup/people"
                        render={(props) => <SignupPeople setStep={setStepPeople} step={1} stepsTotal={stepsTotalPeople} {...props}/>}
                        />
                    <Route
                        exact={true}
                        path="/signup/people/address"
                        render={(props) => <SignupPeopleAddress setStep={setStepPeople} step={2} stepsTotal={stepsTotalPeople} {...props} />}
                    />

                    <Route
                        exact={true}
                        path="/signup/people/documents"
                        render={(props) => <SignupPeopleDocuments setStep={setStepPeople} step={3} stepsTotal={stepsTotalPeople} {...props} />}
                    />
                    <Route
                        exact={true}
                        path="/signup/email-confirmation-people"
                        render={(props) => <SignupConfirmation setStep={setStepPeople} step={4} stepsTotal={stepsTotalPeople} {...props} />}
                    />


                    {/* Signup Company BR */}
                    <Route
                        exact={true}
                        path="/signup/company"
                        render={(props) => <SignupCompany setStep={setStepCompany} step={1} stepsTotal={stepsTotalCompany} {...props} />}
                    />
                    <Route
                        exact={true}
                        path="/signup/company/address"
                        render={(props) => <SignupCompanyAddress setStep={setStepCompany} step={2} stepsTotal={stepsTotalCompany} {...props} />}
                    />
                    <Route
                        exact={true}
                        path="/signup/company/documents"
                        render={(props) => <SignupCompanyDocument setStep={setStepCompany} step={3} stepsTotal={stepsTotalCompany} {...props} />}
                    />
                    <Route
                        exact={true}
                        path="/signup/company/partners"
                        render={(props) => <SignupCompanyPartners setStep={setStepCompany} step={4} stepsTotal={stepsTotalCompany} {...props} />}
                    />



                    {/* Signup Company Foreign */}
                    <Route
                        exact={true}
                        path="/signup/company"
                        render={(props) => <SignupCompany setStep={setStepCompany} step={1} stepsTotal={stepsTotalCompany} {...props} />}
                    />
                    <Route
                        exact={true}
                        path="/signup/company/address"
                        render={(props) => <SignupCompanyAddress setStep={setStepCompany} step={2} stepsTotal={stepsTotalCompany} {...props} />}
                    />
                    <Route
                        exact={true}
                        path="/signup/company/documents"
                        render={(props) => <SignupCompanyDocument setStep={setStepCompany} step={3} stepsTotal={stepsTotalCompany} {...props} />}
                    />
                    <Route
                        exact={true}
                        path="/signup/company/partners"
                        render={(props) => <SignupCompanyPartners setStep={setStepCompany} step={4} stepsTotal={stepsTotalCompany} {...props} />}
                    />
                    <Route
                        exact={true}
                        path="/signup/email-confirmation-company"
                        render={(props) => <SignupConfirmation setStep={setStepCompany} step={5} stepsTotal={stepsTotalCompany} {...props} />}
                    />

                    {/* Company and People */}
                    <Route path="/signup/waiting-validations" exact={true} component={SignupConfirmation}/>
                    <Route path="/signup/success" exact={true} component={SignupSuccess}/>
                    <Route path="/signup/email-confirmation" exact={true} component={SignupConfirmation}/>
                    <Route path="/signup/email-validation" exact={true} component={SignupSuccess}/>
                </Switch>
            </ScrollToTop>
        </ BrowserRouter>
    )
}
import React from 'react';
import './styles.css';
import stableCoin from '../../assets/icone_gmc_moedadigital_estavel.svg';
import transparentCoin from '../../assets/icone_gmc_moedadigital_transparente.svg';
import safeCoin from '../../assets/icone_gmc_moedadigital_segura.svg';
import {Trans, withTranslation} from "react-i18next";


function WhatIsGmc(){
    return(
    <section id='gmc'>
        <div className="container-flex">
            <div className="topCoin">
             <h2><Trans>Uma moeda eficiente, estável e global</Trans></h2>
                <p>
                <Trans>O GoMoney Coin (GMC) é a primeira stablecoin brasileira. Seu valor <br/>não flutua e acompanha a cotação do Dólar americano.<br/><br/></Trans>
<div className="gmcTaxes">
                    <div>
                        <Trans><strong>1 GMC = </strong> 1 DÓLAR</Trans>
                    </div>
                    <hr/>
                    <div>
                        <strong><Trans>TAXA ÚNICA</Trans></strong>
                    </div>
                    {/* <hr/>
                    <div id="gmcBottom">
                        <p>Tempo estimado para envio:</p>
                        <b>ATÉ 2 DIAS ÚTEIS</b>
                    </div> */}
                </div><br/><br/>
                <Trans>O GMC foi desenvolvido para aliar a facilidade e rapidez das moedas digitais,<br/>com a estabilidade e segurança da moeda mais utilizada no mundo.</Trans>

                </p>
            </div>
            <div className="bottomCoin">
            <div className="stableC">
                <img src={stableCoin} id="Balanca" alt="moeda estavel"/>    
                 <h4><Trans>ESTÁVEL</Trans></h4>
                 <p><Trans>1 GMC vale 1 Dólar</Trans></p>
                 <p><Trans>SEMPRE</Trans></p>
            </div>
            <div className="transC" >
                <img src={transparentCoin}id="Lupa" alt="lupa"/>
                    <h4><Trans>TRANSPARENTE</Trans></h4>
                    <p><Trans>Todas as operações</Trans></p>                     
                    <p><Trans>são registradas e auditáveis</Trans></p>
            </div>
            <div className="safC">
                <img src={safeCoin} id="handShake" alt="handshake"/>
                <h4><Trans>SEGURA</Trans></h4>
                    <p><Trans>Plataforma 100% digital</Trans></p>
                    <p><Trans>baseada no Blockchain</Trans></p>
            </div>
        </div>           

        </div>
    </section>


    )
}

export default withTranslation()(WhatIsGmc)
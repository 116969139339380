import React, {Component} from 'react';
import TopBar from '../components/Topbar';
import Footer from '../components/Footer';
import TitleVocePode from '../components/TitleVocePode';



class caso extends Component{
 
 
     render () {
          
             return (
                <>
                <TopBar topbar= 'contentSendRecieve'/>
                <TitleVocePode/>
                <Footer/>
                </>
             );
           
     }
 }
 
 
 export default caso;
import React, {Component} from 'react';
import TopBar from '../components/Topbar';
import DownloadApp from '../components/DownloadApp';
import AskedQuestions from '../components/AskedQuestions';
import StartNow from '../components/StartNow';
import HrSeparator from '../components/SeparatorHr';
import Footer from '../components/Footer';
import HowToReceive from '../components/HowToReceive';
import ReceiveIsEasy from '../components/ReceiveIsEasy';
import SendYourData from '../components/SendYourData';
import ReceiveInGMC from '../components/ReceiveInGMC';
import YourFundsInGMC from '../components/YourFundsInGMC';
import WithdrawGMC from '../components/WithdrawGMC';

class Receive extends Component{

    
     render () {
         
             return (
                <>
                <TopBar topbar= 'contentSendRecieve'/>
                <HowToReceive />
                <ReceiveIsEasy />
                <SendYourData />
                <ReceiveInGMC />
                <YourFundsInGMC />
                <WithdrawGMC />
                <DownloadApp />
                <AskedQuestions />
                <StartNow />
                <HrSeparator />
                <Footer />
                </>
             );
           
     }
 }
 
 
 export default Receive;
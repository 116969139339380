import React from 'react';
import gmcIcon from '../../assets/icone_conheca.png';
import './styles.css';
import {Trans, withTranslation} from "react-i18next";

function StableCoin(){
    return(
        <div className="stableCoinContainer">
            <div className="stableCoinLeft">
                <h1>
                    <Trans>Receber pagamento de clientes brasileiros de forma ágil e segura</Trans>
                </h1>
                <p>
                    <Trans>modulogmb</Trans>
                </p>
                {/* <Link to ='/gmc'><button className="stableCoinButton">Conheça mais</button></Link> */}
            </div>
            <div className="stableCoinRight">
                <img src={gmcIcon} className="stableCoinImg" alt="Motivos"/>
            </div>
            
        </div>
    )
}
export default withTranslation()(StableCoin)
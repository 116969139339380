import React from 'react';
import './styles.css';
import InternationalTransfer from '../InternationalTransfer';
import StableCoin from '../StableCoin';
import KnowOurHistory from '../KnowOurHistory';
import {Trans, withTranslation} from "react-i18next";

function TitleVocePode(){
    
    return (
        <>
         <section id="caso">
         <div className="HowTextWorksTop">
                <div className='titulo'><Trans>Com a GoMoney você pode:</Trans></div>                
            </div>
            <InternationalTransfer /> 
            <StableCoin />
            <KnowOurHistory />   
         </section>
                
        </>
    )
}
export default withTranslation()(TitleVocePode)

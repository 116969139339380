import React from 'react';
import './App.css';
import Routes from './routes';
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

function App() {
  return (
    <div className="mainContainer" >
        <ToastContainer />
        <Routes />
    </div>
  );
}

export default App;

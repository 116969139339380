import React, { useState } from 'react';
import './styles.css';

import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";


export default function WhatIsGmc(){
    const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const openModal = () => {
    setModal(!modal);
  };

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };
    const handleRegister =() =>{
        window.location.href='/signup'
    }
    return(
    <>
        <div className="flex-container">
            <div className="item1"> 
                <h1>GoMoney coin. <br/> Uma criptomoeda eficiente, <br/> estável e global.</h1>
            </div> 
                    <div className="item2">
                        <p>A GoMoney Coin (GMC) é a primeira stablecoin brasileira, seu valor não flutua pois é atrelado ao dólar, ou seja, 1 GMC sempre vale 1 dólar. <br/> Desenvolvida para aliar a facilidade e a rapidez das moedas digitais com a estabilidade e segurança de uma moeda atrelada a um ativo estável.</p>
                        <p><input type="button" id="btn" value="Desburocratize" onClick={handleRegister}></input></p> 
                
                    </div>   

                    
        </div>          
        <div className="item3">
<button onClick={openModal} className="HowSend">
                Clique e assista
        {modal ? (
          <section className="modal__bg">
            <div className="modal__align">
              <div className="modal__content" modal={modal}>
                <IoCloseOutline
                  className="modal__close"
                  arial-label="Close modal"
                  onClick={setModal}
                />
                <div className="modal__video-align">
                  {videoLoading ? (
                    <div className="modal__spinner">
                      <BiLoaderAlt
                        className="modal__spinner-style"
                        fadeIn="none"
                      />
                    </div>
                  ) : null}
                  <iframe
                    className="modal__video-style"
                    onLoad={spinner}
                    loading="lazy"
                    width="800"
                    height="500"
                    src="https://www.youtube.com/embed/2omUYUvs0OE"
                    title="GoMoney, sua conta digital 2.0 l Transferências para qualquer pessoa em qualquer lugar do mundo."
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        ) : null}
      </button>
</div>      

    </>

    )
}
import React from 'react';
import './styles.css';

export default function HowTransfer() {
    const handleRegister =() =>{
        window.location.href='https://gmc.gomoney.me/login/signup'
    }
    return(
        <>
        <div className="howTransferContainer">
            <div className="howTransferContent">
                <h1>
                    Como transferir para <br/>
                    qualquer lugar do <br/>
                    mundo com a GoMoney
                </h1>
                <p>
                    Com a GoMoney você pode realizar pagamentos para <br/>
                    pessoas ou empresas em qualquer lugar do mundo <br/>
                    usando a GMC
                </p>
                <button className="starttoSendButton" onClick={handleRegister}>Comece a enviar</button>
            </div>
        </div>
            
        </>
    )
}
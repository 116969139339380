// import React, { useState } from 'react';
import React from 'react';
import './styles.css';
import Topbar from '../Topbar'
import appleStore from '../../assets/icone_appstore.svg'
import appleStore2 from '../../assets/icone_appstore_1.svg'
import googlePlay from '../../assets/icone_googleplay.svg'
import googlePlay2 from '../../assets/icone_googleplay_1.svg'
import {Trans, withTranslation} from "react-i18next";

function HomeElement() {
    
    const idioma = window.localStorage.getItem('i18nextLng');
   
    if (idioma === 'pt-BR') {
     var img = googlePlay   
     var img2 = appleStore   
    } else {
     img = googlePlay2   
     img2 = appleStore2   
    }
    return (
        <>
            <div className="home">
                <Topbar topbar="contentHome"></Topbar>
                <div className="content">
                    <div className="contentLeft">
                        <h1>
                        <Trans>
                        Pague seu fornecedor <br/> estrangeiro em qualquer<br/>lugar do mundo<br/>
                        </Trans>

                        </h1>
                        {/* <p>Transferências internacionais sem <br/>
                        limite e sem burocracia.</p> */}
                        <div className="stores">
                            <a href="https://play.google.com/store/apps/details?id=com.gomoney.app"><img className="storeIconGoogle" src={img} alt="Google Play"/></a>
                            <a href="https://apps.apple.com/br/app/gomoney/id1461331130"><img className="storeIconApple" src={img2} alt="Apple Store"/></a> 
                        </div>
                    </div>
                    <div className="contentRight">
                        {/* <span><Trans>Abra já a sua conta!</Trans></span>
                        <form>
                            <button onClick={(e) => {
                                e.preventDefault();
                                window.location.href='/signup';
                                }}><Trans>Cadastrar</Trans></button>
                        </form>
                        <div className="haveAccount">Já tem uma conta? <a href="https://gmc.gomoney.me/login">Entrar</a></div>  */}
                    </div>
                </div>
            </div>
        </>
    )
 }

export default withTranslation()(HomeElement);
import React, {Component} from 'react';
import SignupContainer from "../../components/Signup/SignupContainer";
import GmcSelect from "../../components/Forms/GmcSelect";
import * as yup from 'yup'
import {toast} from "react-toastify";
import CmsService from "../../services/cms";
import {Trans, useTranslation, withTranslation} from "react-i18next";
import i18next from '../../i18n'




class SignupIndex extends Component {


    constructor(props) {
        super(props);
        this.handleToPeople = this.handleToPeople.bind(this)
        this.handleToCompany = this.handleToCompany.bind(this)
        this.handleGoNextStep = this.handleGoNextStep.bind(this)

        this.state = {
            selectedSignupType: null,
            selectedCountry: '',
            countries: [],
            errors: []
        }
        const validations = {
            selectedCountry: yup.object().required('Campo Obrigatório!').nullable()
        };

        this.enterpriseForm = yup.object().shape(validations);
    }

    
    async componentWillMount() {
        const response = await CmsService.getCountries()
        var list = response.data.sort((a, b) => (a.name > b.name) ? 1 : -1)
        this.setState({            
            countries: list.map(e => {
                //console.log(e);
                return {
                    value: e,
                    label: i18next.t(e.code)
                }
            })
        })
    }

    handleCountryChange = (
        newValue,
        actionMeta
    ) => {
        this.setState({
            selectedCountry: newValue
        })
    };

    handleToCompany() {
        this.setState({
            selectedSignupType: 'company'
        })

    }

    handleToPeople() {
        this.setState({
            selectedSignupType: 'people'
        })
    }


    async handleGoNextStep() {

        try {

            this.setState({
                isLoading: true,
                errors: [],
            })
            const errors = await this.enterpriseForm.validate(this.state, {abortEarly: false})

            if (errors.length > 0) {
                alert('wrong');
            } else {
                this.props.history.push({
                    pathname: `/signup/${this.state.selectedSignupType}`,
                    state: {
                        country: this.state.selectedCountry.value,
                        selectedSignupType: this.state.selectedSignupType
                    }
                })
            }

        } catch (e) {

            if (e.response?.status === 400) {
                toast.error(e.response.data.message);
            } else if (e.inner) {

                toast.error('Verifique os campos do formulário.');

                const errs = [];

                for (const error of e.inner) {
                    errs.push({
                        key: error.path,
                        message: error.message
                    })
                }

                this.setState({
                    errors: errs
                })
            }

        } finally {
            this.setState({
                isLoading: false
            })
        }


    }

    handleSelectCountry(event) {
        this.setState({
            selectedCountry: event.target.value
        })
    }

    render() {
        const showCountryList = this.state.selectedSignupType != null;

        let msg;

        if (this.state.selectedSignupType === 'company') {
            msg = i18next.t("Por favor, informe o país onde sua empresa está localizada");
        } else {
            msg = i18next.t("Por favor, informe o país que você reside?");
        }

        return (
            <>

                <SignupContainer>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <div
                            style={{
                                maxWidth: '500px',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: "20px 20px"
                            }}>

                            <div
                                style={{fontSize: '24px', fontWeight: "bold", marginTop: "20px", color: "#000066"}}>
                                <Trans>
                                    Você quer abrir uma conta na GoMoney para você ou para sua empresa?
                                </Trans>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', marginTop: "20px"}}>
                                <button className="btnDefault"
                                        style={{backgroundColor: this.state.selectedSignupType == 'people' ? "#2f2f2f" : ""}}
                                        onClick={this.handleToPeople}>
                                    <Trans>
                                        Para mim
                                    </Trans>
                                </button>
                                <button className="btnDefault"
                                        style={{backgroundColor: this.state.selectedSignupType == 'company' ? "#2f2f2f" : ""}}
                                        onClick={this.handleToCompany}>
                                    <Trans>
                                        Para empresa
                                    </Trans>
                                </button>
                            </div>
                            <div style={{fontSize: "14px", fontWeight: "400", marginTop: "20px", color: "#000066"}}>
                                *
                                <Trans>
                                    Se você não se enquadra nas opções acima, por favor, envie um e-mail
                                    para <strong>cadastro@gomoney.me</strong>
                                </Trans>

                            </div>

                            <div style={{display: showCountryList ? 'flex' : 'none'}}>
                                <div>

                                    <div
                                        style={{
                                            fontSize: '24px',
                                            fontWeight: "bold",
                                            marginTop: "20px",
                                            color: "#000066"
                                        }}>
                                        {msg}
                                    </div>

                                    <GmcSelect name="selectedCountry"
                                               errors={this.state.errors}
                                               options={this.state.countries}
                                               value={this.state.selectedCountry}
                                               placeholder={i18next.t("Selecione")}
                                               onChange={this.handleCountryChange.bind(this)}/>
                                </div>
                            </div>
                            <div style={{
                                display: this.state.selectedCountry != null ? "flex" : 'none',
                                marginTop: '20px',
                                justifyContent: 'end'
                            }}>
                                {this.state.selectedSignupType != null && <>
                                    <button className="btnLink"
                                            onClick={this.handleGoNextStep}>
                                        <Trans>
                                            Prosseguir
                                        </Trans>
                                    </button>
                                </>}
                            </div>
                        </div>
                    </div>
                </SignupContainer>
            </>
        )
    }
}


export default withTranslation()(SignupIndex);

import React from 'react';
import receiveGMC from '../../assets/icone_receber_gmc.png';
import './styles.css';

export default function ReceiveInGMC ()  {
    return (
        <>
            <div className="ReceiveInGMCContainer">
            <div className="ReceiveInGMCLeft">
                <h2>
                    3. Receba em GMC <br/>
                </h2>
                <p>
                    Você será informado assim que o crédito em GMC chegar <br/>
                    em sua conta GoMoney, agora que você tem seus GMCs <br/>
                    você pode utilizar para fazer novas transferências ou <br/>
                    resgatar o valor para a sua moeda desejada.

                </p>
            </div>
            <div className="ReceiveInGMCRight">
                <img src={receiveGMC} alt="Receba em GMC"/>
            </div>
        </div>
        </>
    )
    
}
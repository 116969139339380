import React from 'react';
import './styles.css';
import appStore from '../../assets/icone_appstore.svg';
import appleStore2 from '../../assets/icone_appstore_1.svg'
import googlePlay from '../../assets/icone_googleplay.svg';
import googlePlay2 from '../../assets/icone_googleplay_1.svg'
import {Trans, withTranslation} from "react-i18next";

function DownloadApp(){

    const idioma = window.localStorage.getItem('i18nextLng');
   
    if (idioma === 'pt-BR') {
     var img = googlePlay   
     var img2 = appStore   
    } else {
     img = googlePlay2   
     img2 = appleStore2   
    }
    return (
        <div className="DownloadAppContainer">
            <div className="DownloadAppContent">
                <h1><Trans>Baixe o App</Trans></h1>
                <p>
                    <Trans>Comece agora a fazer transferências<br/>de forma rápida e econômica, em qualquer lugar</Trans>
                </p>
                <div className="DownloadAppStores">
                    
                <a href="https://apps.apple.com/br/app/gomoney/id1461331130"><img src={img2} className="DownloadAppAppStore" alt="App Store"/></a>
                    <div className="DownloadAppSpace"></div>
                    <a href="https://play.google.com/store/apps/details?id=com.gomoney.app"><img src={img} className="DownloadAppGooglePlay" alt="Google Play"/></a>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(DownloadApp)
import React from 'react';
import './styles.css'
import AccordeonFaq from '../AccordeonFaq';

export default function FaqMainComponent(){
    return (
        <>
            <div className="faqMainContainer">
                <div className="faqMainContent">
                    <h1>
                        Perguntas Frequentes
                    </h1>
                    <AccordeonFaq/>
                </div>
            </div>
        </>
    )
}
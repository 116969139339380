import React from 'react';
import { Link } from 'react-router-dom';
import './SideDrawer.css';
import {Trans, withTranslation} from "react-i18next";

const sideDrawer = props => {
    let drawerClasses = 'side-drawer';
    if (props.show){
        drawerClasses= 'side-drawer open';
    }
   return(
        <nav className={drawerClasses}>
            <ul>
            <li> <Link to='/'>Home</Link> </li>
            <li><a href='#sobre' to='/about'><Trans>Sobre</Trans></a></li>
            <li> <a href='#gmc'><Trans>GMC</Trans></a></li>
            <li> <a href='#caso'><Trans>Caso de Uso</Trans></a></li>
            <li> <a href='#como'><Trans>Como Funciona</Trans></a></li>
            <li> <a href='#perguntas'><Trans>Ajuda</Trans></a></li>
            <li><a href="https://gmc.gomoney.me/login"><Trans>Entrar</Trans></a></li>
            <li><a href="/signup"><Trans>Abra sua conta</Trans></a></li>
            </ul>
        </nav>);
};

export default withTranslation()(sideDrawer);
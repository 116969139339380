import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import logo from '../../assets/logo.png';
import {Trans, withTranslation} from "react-i18next";


function Footer(){
    return (
       <div className="footerContainer">
     
           <div className="footerCenter">
        
            <div className="footerAdderss">
                <img src={logo} alt="GoMoney.me"/>
                <p>
                Av Queiroz Filho, 1560 <br/>
                Sala 15 - Torre Sabiá - São Paulo/SP <br/>
                +55 11 4450-1422<br/>
                <a href='mailto:contact@gomoney.me'>contact@gomoney.me</a>
                </p>
            </div>
            <div className="footerNavigation">
                <h3><Trans>Navegação</Trans></h3>
                <a href='#sobre' to='/about'><Trans>Sobre</Trans></a> <br/>
                <a href='#gmc'><Trans>GMC</Trans></a> <br/>
                <a href='#caso'><Trans>Caso de Uso</Trans></a> <br/>
                <a href='#como'><Trans>Como Funciona</Trans></a> <br/>
                <a href='#perguntas'><Trans>Ajuda</Trans></a> <br/>
                <a href="https://gmc.gomoney.me/login"><Trans>Entrar</Trans></a> <br/>
                <Link to='/signup'><Trans>Abra sua conta</Trans></Link> <br/>
            </div>
            <div className="footerLegal">
                <h3><Trans>Legal</Trans></h3>
                <a href="https://gomoney.me/legal/politicas-de-privacidade" target='_blank'><Trans>Política de privacidade</Trans></a> <br/>
                <a href="https://gomoney.me/legal/termos-de-uso" target='_blank'><Trans>Termos de Uso</Trans></a> <br/>
                
            </div>
            <div className="footerNavigation">
            <h3><Trans>Compliance</Trans></h3>
            <Link to="/compliance_abril/compliance-politica-pldft.pdf" target="_blank" download><Trans>Política PLDFT</Trans></Link> <br/>
            <Link to="/compliance_abril/compliance-controles-internos.pdf" target="_blank" download><Trans>Controles Internos</Trans></Link> <br/>
            <Link to="/compliance_abril/compliance-controles-seguranca.pdf" target="_blank" download><Trans>Controles Segurança</Trans></Link> <br/>
            <Link to="/compliance_abril/codigo-etica-conduta.pdf" target="_blank" download><Trans>Código de Ética</Trans></Link> <br/>
            </div>
                
           </div>
            <div className="footerBottom">
                    <p>
                        <Trans>Direitos Reservados</Trans> 2018 - {new Date().getFullYear()} GOMONEY PAGAMENTOS, EMISSÃO, INTERMEDIAÇÃO E CUSTÓDIA LTDA - CNPJ: 30.359.852/0001-30 <br/>
                        Av Queiroz Filho, 1560 - Sala 15 - Torre Sabiá - São Paulo/SP, Brasil CEP: 05319-000
                    </p>
            </div>
           
       </div>
    )
}

export default withTranslation()(Footer)
import React, {useState} from 'react';
import Style from '../../components/InputPhone/style'
import PhoneInput from 'react-phone-number-input'
import countries from 'react-phone-number-input/locale/ru.json'
import {Trans} from "react-i18next";

const InputPhone = ({phoneCountry, setPhone, errors}) => {
    const error = errors?.find((elem) => elem.key === 'phoneDdi')
    const errorMessage = <div style={{fontSize: '12px', marginTop: "5px", color: "red"}}>
        {(error?.message || '').replace(/error.key/, 'DDI')}
    </div>
    return (
        <>
            <Style color={error ? "red" : "#000066"}>
                <label className="gmc-label">
                    <Trans>
                        Phone
                    </Trans>
                </label>
                <PhoneInput
                    international
                    defaultCountry={countries}
                    value={phoneCountry}
                    onChange={(e) => {
                        setPhone.setState({phoneDdi: e})
                    }}/>
            </Style>

        </>
    )
}


export default InputPhone

import React, {Component} from 'react';
import HomeElement from '../components/Home';
import SendAndRecieve from '../components/SendAndRecieve';
import GMC from '../components/GMC';
import SendOrRecieve from '../components/SendOrRecieve';


import DigitalCoin from '../components/DigitalCoin';

import DownloadApp from '../components/DownloadApp';
import AskedQuestions from '../components/AskedQuestions';
import StartNow from '../components/StartNow';
import SeparatorHr from '../components/SeparatorHr'
import Footer from '../components/Footer';
import TitleVocePode from '../components/TitleVocePode';



class Home extends Component{
 
     render () {
          
             return (
                 <>
                    <HomeElement/>
                    <GMC />
                    <DigitalCoin/>                    
                    <SendOrRecieve />
                    <TitleVocePode/>           
                    <SendAndRecieve />
                    <DownloadApp />
                    <AskedQuestions />
                    <StartNow />
                    <SeparatorHr />
                    <Footer />

 
 
                 </>
             );
           
     }
 }
 
 
 export default Home;
import React from 'react';
import convertFundsIcon from '../../assets/icone_enviar_gmc.png';
import './styles.css';

export default function ConvertFunds ()  {
    return (
        <>
            <div className="ConvertFundsContainer">
            <div className="ConvertFundsLeft">
                <h2>
                    3. Converta seu saldo <br/>
                    em GMC
                </h2>
                <p>
                    Converta seus fundos em moeda para GoMoney Coin, <br/>
                    nossa criptomoeda que sempre vale 1 Dólar Americano, <br/>
                    é só ir na opção Comprar no menu Painel de Usuário <br/>
                    e comprar o valor desejado de GMCs, com ela as <br/>
                    transferências são seguras e muito mais econômicas.
                </p>
            </div>
            <div className="ConvertFundsRight">
                <img src={convertFundsIcon} alt="Começar é fácil"/>
            </div>
        </div>
        </>
    )
    
}
import React from 'react';
import './styles.css';
import footerImage from '../../assets/icone_comece_agora.png';
import {Trans, withTranslation} from "react-i18next";

function Footer(){
    const handleRegister =() =>{
        window.location.href='/signup'
    }
    return (
       <div className="startNowContainer">
           <div className="startNowTop">
                <img src={footerImage} alt="Comece agora" className="startNowImg"/>
                <div className="startNowRight">
                    <h1>
                        <Trans>Comece agora.</Trans>
                    </h1>
                   <br></br>
                    <button className="startNowButton" onClick={handleRegister}>
                        <Trans>Abra sua conta</Trans>
                    </button>

                </div>
           </div>

       </div>
    )
}

export default withTranslation()(Footer)
import React, {Component} from 'react';
import SignupContainer from "../../../components/Signup/SignupContainer";
import Upload from "../../../components/Forms/Upload";
import * as yup from "yup";
import GmcErrorMessage from "../../../components/Forms/GmcErrorMessage";
import {toast} from "react-toastify";
import GmcSelect from "../../../components/Forms/GmcSelect";
import * as DocumentTypes from "../../../constants/document-types";
import SignupService from "../../../services/signup";
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";

class SignupPeopleDocuments extends Component {

    constructor(props) {
        super(props);
        this.handleBack = this.handleBack.bind(this)
        this.handleGoNextStep = this.handleGoNextStep.bind(this)

        this.handleIsPoliticallyExposedPerson = this.handleIsPoliticallyExposedPerson.bind(this)
        this.handleIsNotPoliticallyExposedPerson = this.handleIsNotPoliticallyExposedPerson.bind(this)
        this.handleIsAmericanCitizen = this.handleIsAmericanCitizen.bind(this)
        this.handleIsNotAmericanCitizen = this.handleIsNotAmericanCitizen.bind(this)
        this.handleDocumentTypeChange = this.handleDocumentTypeChange.bind(this)

        this.handleUploadDocumentFront = this.handleUploadDocumentFront.bind(this)
        this.handleUploadDocumentBack = this.handleUploadDocumentBack.bind(this)
        this.handleUploadSelfie = this.handleUploadSelfie.bind(this)

        this.state = {
            isLoading: false,
            proofOfAddress: null,
            documentFront: null,
            documentBack: null,
            selfie: null,
            isPoliticallyExposedPerson: null,
            isAmericanCitizen: null,
            country: props.location.state.country,
            documentTypes: DocumentTypes,
            documentType: props.location.state.documentType
        }


        if (this.state.country.code === 'BR') {
            this.setState({
                documentType: {value: 'CPF', label: 'CPF'}
            })
        }
        this.documentsForm = yup.object().shape({
            documentFront: yup
                .object()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
            documentBack: yup
                .object()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
            selfie: yup
                .object()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
            isPoliticallyExposedPerson: yup
                .bool()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
            isAmericanCitizen: yup
                .bool()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
        });
    }

    componentDidMount() {
        if (this.state.country.code === 'BR') {
            this.setState({
                documentTypes: DocumentTypes.BRAZIL.map(item => {
                    item.label = i18next.t(item.label)
                    return item;
                })
            })
        } else {
            this.setState({
                documentTypes: DocumentTypes.OTHERS.map(item => {
                    item.label = i18next.t(item.label)
                    return item;
                })
            })
        }

    }

    async handleUploadDocumentFront(file) {
        try {

            const form = new FormData();
            form.append('file', file);
            form.append('documentType', this.state.documentType.value);
            form.append('documentDirection', 'FRONT');

            const response = await SignupService.uploadProfileDocuments(form)

            toast.success(i18next.t('Documento enviado com sucesso.'))

            this.setState({
                documentFront: response.data
            })

        } catch (e) {

        }
    }

    async handleUploadDocumentBack(file) {
        const form = new FormData();
        form.append('file', file);
        form.append('documentType',  this.state.documentType.value);
        form.append('documentDirection', 'BACK');

        const response = await SignupService.uploadProfileDocuments(form)

        toast.success(i18next.t('Documento enviado com sucesso.'))
        this.setState({
            documentBack: response.data
        })
    }

    async handleUploadSelfie(file) {
        const form = new FormData();
        form.append('file', file);
        form.append('documentType', 'SELFIE');
        form.append('documentDirection', 'SINGLE');

        const response = await SignupService.uploadProfileDocuments(form)

        toast.success(i18next.t('Documento enviado com sucesso.'))
        this.setState({
            selfie: response.data
        })
    }

    handleBack() {
        this.props.history.goBack();
    }

    handleDocumentTypeChange(value) {
        this.setState({
            documentType: value
        })
    }

    async handleGoNextStep() {

        try {

            this.setState({
                isLoading: true,
                errors: []
            })

            const errors = await this.documentsForm.validate(this.state, {abortEarly: false})

            if (errors.length > 0) {
                alert('wrong');
            } else {

                await SignupService.updateProfile({
                    isPoliticallyExposedPerson: this.state.isPoliticallyExposedPerson,
                    isAmericanCitizen: this.state.isAmericanCitizen
                });

                await SignupService.completeSignup();

                this.props.history.push({
                    pathname: '/signup/email-confirmation-people',
                    state: {
                        country: this.state.country,
                        documentType: this.state.documentType
                    }
                })

            }

        } catch (e) {

            const errs = [];

            for (const error of e.inner) {
                errs.push({
                    key: error.path,
                    message: error.message
                })
            }

            this.setState({
                errors: errs
            })

        } finally {
            this.setState({
                isLoading: false
            })
        }


    }

    handleIsPoliticallyExposedPerson() {
        this.setState({
            isPoliticallyExposedPerson: true
        })
    }

    handleIsNotPoliticallyExposedPerson() {
        this.setState({
            isPoliticallyExposedPerson: false
        })
    }

    handleIsAmericanCitizen() {
        this.setState({
            isAmericanCitizen: true
        })
    }

    handleIsNotAmericanCitizen() {
        this.setState({
            isAmericanCitizen: false
        })
    }

    render() {
        return (
            <>
                <SignupContainer>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{
                            display: "flex",
                            maxWidth: "300px",
                            alignSelf: "center",
                            justifyContent: "",
                            flexDirection: "column"
                        }}>

                            <div style={{
                                fontSize: '14px',
                                marginTop: "50px",
                                fontWeight: "500",
                                color: "#cc3366",
                                justifySelf: 'start'
                            }}>
                                <Trans>
                                    Etapa {{current: this.props.step}} de {{total: this.props.stepsTotal}}
                                </Trans>
                            </div>

                            <div style={{
                                fontSize: '24px',
                                fontWeight: "bold",
                                marginTop: "20px",
                                color: "#000066",
                                justifySelf: 'start'
                            }}>
                                <Trans>Upload de documentos de identificação.</Trans>
                            </div>
                            {this.state.country.code !== 'BR' && <>
                                <div style={{marginTop: "20px"}}>
                                    <GmcSelect
                                        errors={this.state.errors}
                                        name={"documentType"}
                                        label={"Tipo de Documento"}
                                        options={this.state.documentTypes}
                                        isDisabled={true}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                border: "1px solid red"
                                            })
                                        }}
                                        value={this.state.documentType}
                                        onChange={this.handleDocumentTypeChange.bind(this)}/>
                                </div>
                            </>}

                            {this.state.country.code === 'BR' && <>
                                <div style={{marginTop: "20px"}}>
                                    <GmcSelect
                                        errors={this.state.errors}
                                        name={"documentType"}
                                        label={"Tipo de Documento"}
                                        options={this.state.documentTypes}
                                        isDisabled={false}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                border: "1px solid red"
                                            })
                                        }}
                                        value={this.state.documentType}
                                        onChange={this.handleDocumentTypeChange.bind(this)}/>
                                </div>
                            </>}

                            <div style={{
                                marginTop: "40px"
                            }}>
                                <Upload
                                    errors={this.state.errors}
                                    name={"documentFront"}
                                    label={"Foto do documento"}
                                    tip={"(frente)"}
                                    btnLabel={"Anexar"}
                                    uploadFile={this.handleUploadDocumentFront.bind(this)}
                                    style={{fontSize: "14px"}}>
                                </Upload>
                            </div>

                            <div style={{
                                marginTop: "40px"
                            }}>
                                <Upload
                                    errors={this.state.errors}
                                    name={"documentBack"}
                                    label={"Foto do documento"}
                                    tip={"(verso)"}
                                    btnLabel={"Anexar"}
                                    uploadFile={this.handleUploadDocumentBack.bind(this)}
                                    style={{fontSize: "14px"}}>
                                </Upload>
                            </div>

                            <div style={{
                                marginTop: "40px"
                            }}>
                                <Upload
                                    errors={this.state.errors}
                                    name={"selfie"}
                                    label={"Selfie com o documento"}
                                    btnLabel={"Anexar"}
                                    uploadFile={this.handleUploadSelfie.bind(this)}
                                    style={{fontSize: "14px"}}>
                                </Upload>
                            </div>

                            <div style={{
                                marginTop: "40px"
                            }}>
                                <div style={{fontSize: "12px", fontWeight: "bold", color: "#000066"}}>
                                    <Trans>Você é uma pessoa politicamente exposta?</Trans>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                }}>
                                    <button
                                        className={`btnDefaultOutlined ${this.state.isPoliticallyExposedPerson != null && this.state.isPoliticallyExposedPerson ? "btnDefaultOutlinedActive" : ""}`}
                                        style={{
                                            marginLeft: "0",
                                            marginTop: "5px",
                                        }}
                                        onClick={this.handleIsPoliticallyExposedPerson}>
                                        <Trans>Sim</Trans>
                                    </button>
                                    <button
                                        className={`btnDefaultOutlined ${this.state.isPoliticallyExposedPerson != null && !this.state.isPoliticallyExposedPerson ? "btnDefaultOutlinedActive" : ""}`}
                                        style={{
                                            marginLeft: "10px",
                                            marginTop: "5px",
                                        }}
                                        onClick={this.handleIsNotPoliticallyExposedPerson}><Trans>Não</Trans>
                                    </button>
                                </div>
                                <GmcErrorMessage name={"isPoliticallyExposedPerson"}
                                                 errors={this.state.errors}></GmcErrorMessage>
                            </div>

                            <div style={{
                                marginTop: "40px"
                            }}>
                                <div style={{fontSize: "12px", fontWeight: "bold", color: "#000066"}}>
                                    <Trans>
                                        Você é um cidadão dos Estados Unidos da América?
                                    </Trans>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                }}>
                                    <button
                                        className={`btnDefaultOutlined ${this.state.isAmericanCitizen != null && this.state.isAmericanCitizen ? "btnDefaultOutlinedActive" : ""}`}
                                        style={{
                                            marginLeft: "0",
                                            marginTop: "5px",
                                        }}
                                        onClick={this.handleIsAmericanCitizen}><Trans>Sim</Trans>
                                    </button>
                                    <button
                                        className={`btnDefaultOutlined ${this.state.isAmericanCitizen != null && !this.state.isAmericanCitizen ? "btnDefaultOutlinedActive" : ""}`}
                                        style={{
                                            marginLeft: "10px",
                                            marginTop: "5px",
                                        }}
                                        onClick={this.handleIsNotAmericanCitizen}><Trans>Não</Trans>
                                    </button>
                                </div>
                                <GmcErrorMessage name={"isAmericanCitizen"}
                                                 errors={this.state.errors}></GmcErrorMessage>
                            </div>

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px"
                            }}>
                                <button className="btnLink"
                                        onClick={this.handleBack}><Trans>Voltar</Trans>
                                </button>

                                <button className="btnLink"
                                        onClick={this.handleGoNextStep}
                                        disabled={this.state.isLoading}
                                        style={{
                                            backgroundColor: this.state.isLoading ? 'rgba(0,0,102,0.42)' : '',
                                            color: this.state.isLoading ? '#FFF' : ''
                                        }}
                                ><Trans>Prosseguir</Trans>
                                    {this.state.isLoading ? <span style={{marginLeft: '5px'}}>
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                    </span> : ''}
                                </button>
                            </div>
                        </div>

                    </div>
                </SignupContainer>
            </>
        );

    }
}


export default withTranslation()(SignupPeopleDocuments);


export const BRAZIL = [
    {label: 'CNH', value: 'CNH'},
    {label: 'RG', value: 'RG'},
    {label: 'RNE', value: 'RNE'},
    {label: 'Passaporte', value: 'PASSPORT'},
    {label: 'Identidade Profissional', value: 'PROFESSIONAL_ID'},
    {label: 'Outros', value: 'OTHERS'},
]

export const OTHERS = [
    {label: 'Passaporte', value: 'PASSPORT'},
    {label: 'ID', value: 'ID'},
    {label: 'Drive License', value: 'DRIVE_LICENSE'},
]

import React from 'react';
import DestinationDataIcon from '../../assets/icone_enviar_informedados.png';
import './styles.css';

export default function DestinationData(){
    return(
        <>
            <div className="DestinationDataContainer">
                <div className="DestinationDataLeft">
                        
                    <img src={DestinationDataIcon} alt="Insira fundos"/>
                </div>
                <div className="DestinationDataRight">
                    <h2>
                        4. Informe os dados <br/>
                        da conta GoMoney <br/>
                        do destinatário
                    </h2>
                    <p>
                        Você só precisa dos dados da conta GoMoney do <br/>
                        beneficiário com seu código de usuário e e-mail, para <br/>
                        achar o código de usuário basta ir em seu Perfil <br/>
                        no Painel de Usuário, caso ele ainda não tenha uma conta <br/>
                        GoMoney é só enviar um convite através do Painel de <br/>
                        Usuário, leva só alguns minutos!
                    </p>
                </div>
            
            </div>
        </>
    )
}
import React from 'react';
import insertFundsIcon from '../../assets/icone_enviar_insirafundos.png';
import './styles.css';

export default function InsertFunds(){
    return(
        <>
            <div className="InsertFundsContainer">
                <div className="InsertFundsLeft">
                        
                    <img src={insertFundsIcon} alt="Insira fundos"/>
                </div>
                <div className="InsertFundsRight">
                    <h2>
                        2. Insira fundos em <br/>
                        sua conta GoMoney
                    </h2>
                    <p>
                        A plataforma da GoMoney utiliza GMC para realizar <br/>
                        transferências, para adquirir GMCs vá a opção "Depositar" <br/>
                        no menu do Painel de Usuário e deposite o valor desejado <br/>
                        através de uma Transferência TED ou gerando um Boleto <br/>
                        Bancário para a GoMoney. Em até 1 dia útil o valor <br/>
                        depositado entra automaticamente na sua conta GoMoney.
                    </p>
                </div>
            
            </div>
        </>
    )
}
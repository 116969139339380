import {httpClient} from "../utils/http-client";
import axios from "axios";

class CmsService {

    getCountries() {
        return httpClient().get('/countries')
    }

    getStates() {
        return httpClient().get('/cms/states')
    }

    getCities(code) {
        return httpClient().get(`/cms/states/${code}/cities`)
    }
}

export default new CmsService();
import React, {Component} from 'react';
import TopBar from '../components/Topbar';
import Footer from '../components/Footer';
import WhatIsGmc from '../components/WhatIsGmc';
import DownloadApp from '../components/DownloadApp';
import AskQuestions from '../components/AskedQuestions';
import StartNow from '../components/StartNow';
import HrComponet from '../components/SeparatorHr';


class GMC extends Component{
 
 
     render () {
          
             return (
                <>
                <TopBar topbar= 'contentSendRecieve'/>
                <WhatIsGmc/>
                <DownloadApp/>
                <AskQuestions/>            
                <StartNow/>
                <HrComponet/>
                <Footer/>
                </>
             );
           
     }
 }
 
 
 export default GMC;
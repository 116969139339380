import React from 'react';
import '../SendAndRecieve/styles.css';
import cadastro from '../../assets/icone_cadastro.svg';
import insiraFundos from '../../assets/insira_fundos.svg';
import converta from '../../assets/converta.svg';
import transfira from '../../assets/transfira.svg';
import {Trans, withTranslation} from "react-i18next";

function HowItWorksSend () {
    
    return (
        <div className="container-fluid">
            <div className="HowItWorksCenter">
                <div className="centerElement">
                    <img src={cadastro} alt="Cadastre-se"/>
                    <h3><Trans>1. Deposite fundos em sua conta GoMoney</Trans></h3>
                    <p><Trans>Você pode depositar via PIX</Trans></p>
                </div>
                <div className="centerElement">
                    <img src={insiraFundos} alt="Insira Fundos"/>
                    <h3><Trans>2. Converta seu saldo em GMC</Trans></h3>
                    <p><Trans>1 GoMoney Coin = 1 Dólar Americano.<br/>SEMPRE!</Trans></p>
                </div>
                <div className="centerElement">
                    <img src={converta} alt="Converta"/>
                    <h3><Trans>3. Transfira seus GMCs</Trans></h3>
                    <p><Trans>Transfira seus GMCs para outro usuário GoMoney em qualquer país.</Trans></p>
                </div>
                <div className="centerElement">
                    <img src={transfira} alt="Transfira"/>
                    <h3><Trans>4. Solicite o Resgate</Trans></h3>
                    <p><Trans>Converta seus GMCs em Reais</Trans></p>
                </div>
            </div>
            <div className="CSSgal">

                    <s id="s1"></s> 
                    <s id="s2"></s>
                    <s id="s3"></s>
                    <s id="s4"></s>

                    <div className="slider">
                    <div className="slider-content" >
                    <div className="centerElement">
                    <img src={cadastro} alt="Cadastre-se"/>
                    <h3><Trans>1. Deposite fundos em sua conta GoMoney</Trans></h3>
                    <p><Trans>Você pode depositar via PIX</Trans></p>
                </div>
                        </div>
                        <div className="slider-content"  >
                        <div className="centerElement">
                    <img src={insiraFundos} alt="Insira Fundos"/>
                    <h3><Trans>2. Converta seu saldo em GMC</Trans></h3>
                    <p><Trans>1 GoMoney Coin = 1 Dólar Americano.<br/>SEMPRE!</Trans></p>
                </div>
                        </div>
                    <div className="slider-content" >
                    <div className="centerElement">
                    <img src={converta} alt="Converta"/>
                    <h3><Trans>3. Transfira seus GMCs</Trans></h3>
                    <p><Trans>Transfira seus GMCs para outro usuário GoMoney em qualquer país.</Trans></p>
                </div>
                        </div>
                    <div className="slider-content" >
                    <div className="centerElement">
                    <img src={transfira} alt="Transfira"/>
                    <h3><Trans>4. Solicite o Resgate</Trans></h3>
                    <p><Trans>Converta seus GMCs em Reais</Trans></p>
                </div>
                        </div>
                    </div>

                    <div className="prevNext">
                        <div>
                            <a href="#s4" id="1"> </a>
                            <a href="#s2" id="2"> </a>
                        </div>
                        <div>
                            <a href="#s1" id="3"> </a>
                            <a href="#s3" id="4"> </a>
                        </div>
                        <div>
                            <a href="#s2" id="5"> </a>
                            <a href="#s4" id="6"> </a>
                        </div>
                        <div>
                            <a href="#s3" id="7"> </a>
                            <a href="#s1" id="8"> </a>
                        </div>
                    </div>

                    <div className="bullets">
                        <a href="#s1" id="1"> </a>
                        <a href="#s2" id="2"> </a>
                        <a href="#s3" id="3"> </a>
                        <a href="#s4" id="4"> </a>
                    </div>

                </div>
            {/* <div className="HowItWorksBottom">
                <Link to="/send"><button className="HowItWorksButton">Quero saber mais como enviar</button></Link>
            </div> */}
        </div>
    )
}
export default withTranslation()(HowItWorksSend)
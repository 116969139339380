import React, {Component} from 'react';
import Input from "../../../components/Forms/Input";
import SignupContainer from "../../../components/Signup/SignupContainer";
import * as yup from 'yup'
import {toast} from "react-toastify";
import SignupService from "../../../services/signup";
import InputPhone from '../../../components/InputPhone/InputPhone'
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";

class SignupCompany extends Component {

    constructor(props) {
        super(props);
        this.handleBack = this.handleBack.bind(this)
        this.handleGoNextStep = this.handleGoNextStep.bind(this)

        this.state = {
            isLoading: false,
            companyName: '',
            ein: '',
            fieldOfActivity: '',
            email: '',
            emailRepeat: '',
            phone: '',
            phoneDdi: null,
            website: '',
            password: '',
            passwordRepeat: '',
            userType: 'PJ',
            termsAccepted: false,
            privacyAccepted: false,
            countryId: props.location.state.country._id,
            country: props.location.state.country,
            errors: []
        }


        this.enterpriseForm = yup.object().shape({
            companyName: yup
                .string()
                .required(i18next.t('Campo Obrigatório!')),
            ein: yup
                .string()
                .required(i18next.t('Campo Obrigatório!')),
            fieldOfActivity: yup
                .string()
                .required(i18next.t('Campo Obrigatório!')),
            email: yup
                .string()
                .email()
                .required(i18next.t('Campo Obrigatório!')),
            emailRepeat: yup
                .string()
                .email()
                .required(i18next.t('Campo Obrigatório!')),
            phone: yup.string().required(i18next.t('Campo Obrigatório!')),
            password: yup.string().required(i18next.t('Campo Obrigatório!')).matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                i18next.t("Deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere especial")
            ),
            passwordRepeat: yup.string().required(i18next.t('Campo Obrigatório!'))
                .oneOf([yup.ref('password'), null], i18next.t('As senhas devem corresponder!')),
            termsAccepted: yup.boolean().oneOf([true], i18next.t('Campo Obrigatório!')),
            privacyAccepted: yup.boolean().oneOf([true], i18next.t('Campo Obrigatório!')),
        });

    }

    toggleTermsChange = () => {
        this.setState({
            termsAccepted: !this.state.termsAccepted,
        });
    }

    togglePrivacyChange = () => {
        this.setState({
            privacyAccepted: !this.state.privacyAccepted,
        });
    }

    handleBack() {
        this.props.history.goBack();
    }

    async handleGoNextStep() {

        try {

            this.setState({
                isLoading: true,
                errors: [],
                selectedCountry: this.props.location.state.selectedCountry
            })

            const errors = await this.enterpriseForm.validate(this.state, {abortEarly: false})

            if (errors.length > 0) {
                alert('wrong');
            } else {
                await SignupService.signup(this.state);
                this.props.history.push({
                    pathname: '/signup/company/address',
                    state: {
                        country: this.state.country
                    }
                })
            }

        } catch (e) {


            if (e.response?.status === 400) {
                toast.error(e.response.data.message);
            } else if (e.inner) {

                toast.error(i18next.t('Verifique os campos do formulário.'));

                const errs = [];

                console.log(e.inner);

                for (const error of e.inner) {
                    errs.push({
                        key: error.path,
                        message: error.message
                    })
                }

                this.setState({
                    errors: errs
                })
            }

        } finally {
            this.setState({
                isLoading: false
            })
        }


    }


    render() {
        return (
            <>
                <SignupContainer>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{display: "flex", maxWidth: "300px", alignSelf: "center", flexDirection: "column"}}>


                            <div style={{
                                fontSize: '14px',
                                marginTop: "50px",
                                fontWeight: "500",
                                color: "#cc3366",
                                justifySelf: 'start'
                            }}>
                                <Trans>
                                    Etapa {{current: this.props.step}} de {{total: this.props.stepsTotal}}
                                </Trans>

                            </div>

                            <div style={{
                                fontSize: '24px',
                                fontWeight: "bold",
                                marginTop: "20px",
                                color: "#000066",
                                justifySelf: 'start'
                            }}>
                                <Trans>Dados <br/> da empresa.</Trans>
                            </div>

                            <div style={{marginTop: "20px"}}>

                                <Input label={"Nome da Empresa"} name={"companyName"} maxLength={150}
                                       value={this.state.companyName} onChange={(e) => {
                                    this.setState({companyName: e.target.value})
                                }} errors={this.state.errors}/>

                                {this.state.country.code === 'BR' && <>
                                    <Input label={"CNPJ"} name={"ein"}
                                           style={{marginTop: "10px"}}
                                           mask={this.state.country.code === 'BR' ? [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/] : false}
                                           value={this.state.ein}
                                           onChange={(e) => {
                                               this.setState({
                                                   ein: e.target.value
                                               });
                                           }}
                                           errors={this.state.errors}/>
                                </>}

                                {this.state.country.code !== 'BR' && <>
                                    <Input label={"Número Fiscal"} name={"ein"}
                                           style={{marginTop: "10px"}}
                                           value={this.state.ein}
                                           onChange={(e) => {
                                               this.setState({
                                                   ein: e.target.value
                                               });
                                           }}
                                           errors={this.state.errors}/>
                                </>}

                                <Input label={"Ramo de Atuação"} name={"fieldOfActivity"}
                                       errors={this.state.errors}
                                       style={{marginTop: "10px"}} value={this.state.fieldOfActivity} onChange={(e) => {
                                    this.setState({fieldOfActivity: e.target.value})
                                }}></Input>
                                <Input label={"E-mail de acesso"} name={"email"}
                                       style={{marginTop: "10px"}} maxLength={150} type="email" value={this.state.email}
                                       onChange={(e) => {
                                           this.setState({email: e.target.value})
                                       }}
                                       errors={this.state.errors}></Input>
                                <Input label={"Confirmar e-mail de acesso"} name={"emailRepeat"}
                                       style={{marginTop: "10px"}} maxLength={150} type="email"
                                       value={this.state.emailRepeat}
                                       onChange={(e) => {
                                           this.setState({emailRepeat: e.target.value})
                                       }}
                                       errors={this.state.errors}></Input>
                                <div style={{display: "flex"}}>
                                    <InputPhone phoneCountry={this.phoneCountry} setPhone={this}
                                                errors={this.state.errors}/>
                                    <Input name={"phone"}
                                           style={{marginTop: "38px", width: "92%", paddingLeft: "10px"}}
                                           mask={this.state.country.code === 'BR' ? [/\d/, /\d/, " ", /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/] : false}
                                           value={this.state.phone}
                                           onChange={(e) => {
                                               this.setState({phone: e.target.value})
                                           }}
                                           errors={this.state.errors}/>

                                </div>
                                <Input label={"Site"} name={"website"}
                                       style={{marginTop: "10px"}} maxLength={50} value={this.state.website}
                                       onChange={(e) => {
                                           this.setState({website: e.target.value})
                                       }}
                                       errors={this.state.errors}></Input>
                            </div>

                            <div style={{
                                fontSize: '24px',
                                fontWeight: "bold",
                                marginTop: "40px",
                                color: "#000066",
                                justifySelf: 'start'
                            }}>
                                <Trans>
                                    Escolha <br/> sua senha.
                                </Trans>
                            </div>
                            <div style={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#000066",
                                marginTop: "20px"
                            }}>
                                <Trans>
                                    Por favor, crie uma senha da sua escolha, atendendo aos requisitos abaixo:
                                </Trans>
                            </div>

                            <div style={{
                                fontSize: "12px",
                                marginTop: "20px"
                            }}>
                                <ul>
                                    <li><Trans>
                                        Deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere
                                        especial
                                    </Trans></li>
                                </ul>
                            </div>

                            <Input type="password" label={"Senha"} name={"password"}
                                   style={{marginTop: "20px"}} value={this.state.password} onChange={(e) => {
                                this.setState({password: e.target.value})
                            }} errors={this.state.errors}></Input>
                            <Input type="password" label={"Repetir senha"} name={"passwordRepeat"}
                                   style={{marginTop: "10px"}} value={this.state.passwordRepeat} onChange={(e) => {
                                this.setState({passwordRepeat: e.target.value})
                            }} errors={this.state.errors}></Input>

                            <label className="inline-flex items-center mt-3">
                                <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600"
                                       checked={this.state.termsAccepted} onChange={this.toggleTermsChange}/><span
                                className="ml-2 text-gray-700">
                                <Trans i18nKey="termsOfUsage">Aceito os <a href="https://gomoney.me/legal/termos-de-uso"
                                                                           target="_blank">Termos de Uso</a></Trans>

                            </span>
                            </label>
                            <label className="inline-flex items-center mt-3">
                                <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600"
                                       checked={this.state.privacyAccepted} onChange={this.togglePrivacyChange}/><span
                                className="ml-2 text-gray-700">
                                <Trans i18nKey="privacyPolicy">
                                    Aceito as <a href="https://gomoney.me/legal/politicas-de-privacidade"
                                                 target="_blank">Políticas de Privacidade</a>
                                       </Trans>
                                </span>
                            </label>

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px"
                            }}>
                                <button className="btnLink"
                                        onClick={this.handleBack}><Trans>Voltar</Trans>
                                </button>
                                <button className="btnLink"
                                        onClick={this.handleGoNextStep}
                                        disabled={this.state.isLoading}
                                        style={{
                                            backgroundColor: this.state.isLoading ? 'rgba(0,0,102,0.42)' : '',
                                            color: this.state.isLoading ? '#FFF' : ''
                                        }}
                                ><Trans>Prosseguir</Trans>
                                    {this.state.isLoading ? <span style={{marginLeft: '5px'}}>
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                    </span> : ''}
                                </button>
                            </div>
                        </div>


                    </div>
                </SignupContainer>
            </>
        );

    }
}


export default withTranslation()(SignupCompany);

import React, {Component} from 'react';
import SignupContainer from "../../../components/Signup/SignupContainer";
import Upload from "../../../components/Forms/Upload";
import {toast} from "react-toastify";
import GmcErrorMessage from "../../../components/Forms/GmcErrorMessage";
import * as yup from "yup";
import SignupService from "../../../services/signup";
import i18next from "i18next";
import {Trans, withTranslation} from "react-i18next";

class SignupCompanyDocument extends Component {

    constructor(props) {
        super(props);
        this.handleBack = this.handleBack.bind(this)
        this.handleGoNextStep = this.handleGoNextStep.bind(this)
        this.handleUploadConstitution = this.handleUploadConstitution.bind(this)
        this.handleHaveChanges = this.handleHaveChanges.bind(this)
        this.handleDontHaveChanges = this.handleDontHaveChanges.bind(this)
        this.handleUploadConstitutionUpdate = this.handleUploadConstitutionUpdate.bind(this)

        this.state = {
            isLoading: false,
            constitutionDocument: null,
            constitutionDocumentUpdate: null,
            countryId: props.location.state.country._id,
            country: props.location.state.country,
            errors: []
        }

        this.form = yup.object().shape({
            constitutionDocument: yup
                .object()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
            hasChanges: yup
                .bool()
                .nullable()
                .required(i18next.t('Campo Obrigatório!')),
        });
    }

    async handleUploadConstitution(file) {
        try {

            const form = new FormData();
            form.append('file', file);
            form.append('documentType', 'CONSTITUTION_DOCUMENT');
            form.append('documentDirection', 'SINGLE');

            const response = await SignupService.uploadCompanyDocuments(form);

            this.setState({
                constitutionDocument: response.data
            })

            toast.success(i18next.t('Documento enviado com sucesso.'))
        } catch (e) {

        }
    }

    async handleUploadConstitutionUpdate(file) {
        try {

            const form = new FormData();
            form.append('file', file);
            form.append('documentType', 'CONSTITUTION_DOCUMENT_UPDATE');
            form.append('documentDirection', 'SINGLE');

            const response = await SignupService.uploadCompanyDocuments(form);

            this.setState({
                file: response.data
            })

            toast.success(i18next.t('Documento enviado com sucesso.'))

        } catch (e) {

        }
    }

    handleBack() {
        this.props.history.goBack();
    }

    async handleGoNextStep() {

        try {

            this.setState({
                isLoading: true,
                errors: [],
                selectedCountry: this.props.location.state.selectedCountry
            })

            const errors = await this.form.validate(this.state, {abortEarly: false})

            if (errors.length > 0) {
                alert('wrong');
            } else {
                this.props.history.push({
                    pathname: '/signup/company/partners',
                    state: {
                        country: this.state.country
                    }
                })
            }

        } catch (e) {
            if (e.response?.status === 400) {
                toast.error(e.response.data.message);
            } else if (e.inner) {

                console.log(e);

                toast.error(i18next.t('Verifique os campos do formulário.'));

                const errs = [];

                for (const error of e.inner) {
                    errs.push({
                        key: error.path,
                        message: error.message
                    })
                }

                this.setState({
                    errors: errs
                })
            }
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    handleHaveChanges() {
        this.setState({
            hasChanges: true
        })
    }

    handleDontHaveChanges() {
        this.setState({
            hasChanges: false
        })
    }

    render() {
        return (
            <>
                <SignupContainer>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{
                            display: "flex",
                            maxWidth: "300px",
                            alignSelf: "center",
                            justifyContent: "",
                            flexDirection: "column"
                        }}>

                            <div style={{
                                fontSize: '14px',
                                marginTop: "50px",
                                fontWeight: "500",
                                color: "#cc3366",
                                justifySelf: 'start'
                            }}>
                                <Trans>
                                    Etapa {{current: this.props.step}} de {{total: this.props.stepsTotal}}
                                </Trans>
                            </div>

                            <div style={{
                                fontSize: '24px',
                                fontWeight: "bold",
                                marginTop: "20px",
                                color: "#000066",
                                justifySelf: 'start'
                            }}>
                                <Trans>Documentos <br/> da empresa.</Trans>
                            </div>


                            <div style={{
                                marginTop: "20px"
                            }}>
                                <Upload
                                    errors={this.state.errors}
                                    name={"constitutionDocument"}
                                    label={"Anexar Documento da constituição da empresa"}
                                    btnLabel={"Anexar"}
                                    uploadFile={this.handleUploadConstitution.bind(this)}
                                    style={{fontSize: "14px"}}>
                                </Upload>
                            </div>


                            <div style={{
                                marginTop: "20px"
                            }}>
                                <div style={{fontSize: "12px", fontWeight: "bold", color: "#000066"}}>
                                    <Trans>A empresa possui alterações no Documento de Constituição?</Trans>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                }}>
                                    <button className="btnDefault" style={{
                                        marginLeft: "0",
                                        marginTop: "5px",
                                        backgroundColor: this.state.hasChanges != null && this.state.hasChanges ? "#000066" : ""
                                    }}
                                            onClick={this.handleHaveChanges}><Trans>Sim</Trans>
                                    </button>
                                    <button className="btnDefault" style={{
                                        marginLeft: "10px",
                                        marginTop: "5px",
                                        backgroundColor: this.state.hasChanges != null && !this.state.hasChanges ? "#000066" : ""
                                    }}
                                            onClick={this.handleDontHaveChanges}><Trans>Não</Trans>
                                    </button>
                                </div>
                                <GmcErrorMessage name={"hasChanges"}
                                                 errors={this.state.errors}></GmcErrorMessage>
                            </div>

                            <div style={{
                                display: this.state.hasChanges ? "block" : "none",
                                marginTop: "20px"
                            }}>
                                <Upload
                                    errors={this.state.errors}
                                    name={"constitutionDocumentUpdate"}
                                    label={this.state.country.code === 'BR' ? "Anexar a alteração do contrato social da empresa" : "Anexar a alteração do documento de constituição da empresa"}
                                    btnLabel={"Anexar"}
                                    uploadFile={this.handleUploadConstitution.bind(this)}
                                    style={{fontSize: "12px"}}>
                                </Upload>
                            </div>

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px"
                            }}>
                                <button className="btnLink"
                                        onClick={this.handleBack}><Trans>Voltar</Trans>
                                </button>
                                <button className="btnLink"
                                        onClick={this.handleGoNextStep}><Trans>Prosseguir</Trans>
                                </button>
                            </div>
                        </div>

                    </div>
                </SignupContainer>
            </>
        );

    }
}


export default withTranslation()(SignupCompanyDocument);

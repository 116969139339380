import React from 'react';
import './styles.css';
import {Trans, withTranslation} from "react-i18next";

function GMC() {
    // const handleRegister =() =>{
    //     window.location.href='/signup'
    // }
    return(
        <section id='sobre'>
        <div className="gmcContainer">
            <div className="gmcLeft">
            <div className='titulo'><Trans>Sobre a GoMoney</Trans></div>
            <h3><Trans>BLOCKCHAIN + SISTEMA FINANCEIRO</Trans></h3>
                <h1>
                <Trans>A GoMoney foi fundada em 2018 com o objetivo de simplificar a forma de conectar pessoas e negócios globalmente.</Trans>
                </h1>
                <p>
                <Trans>Nossa plataforma une a credibilidade e a força do Sistema Financeiro com a tecnologia Blockchain, dando muito mais segurança, agilidade e transparência nas operações.</Trans>
                </p>
           
                <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-check"></i></span><Trans>Segura</Trans></li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span><Trans>Rápida</Trans></li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span><Trans>Limites inteligentes</Trans></li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span><Trans>Sem burocracia</Trans></li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span><Trans>Melhor custo benefício</Trans></li>
                </ul>


               
            </div>
           
             {/* <button className="gmcMobileButton" onClick={handleRegister}>Comece a economizar</button> */}
        </div>
        </section>
    )
}

export default withTranslation()(GMC)